/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const getUnderlyingPrice = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "getUnderlyingPrice", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getUnderlyingTokens = { inputs: [{ internalType: "address", name: "lpToken", type: "address" }], name: "getUnderlyingTokens", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "address[]", name: "_lpTokens", type: "address[]" }, { internalType: "address[]", name: "_pools", type: "address[]" }, { internalType: "address[][]", name: "_poolUnderlyings", type: "address[][]" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const poolOf = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "poolOf", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const price = { inputs: [{ internalType: "address", name: "underlying", type: "address" }], name: "price", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const registerPool = { inputs: [{ internalType: "address", name: "_lpToken", type: "address" }, { internalType: "address", name: "_pool", type: "address" }, { internalType: "address[]", name: "_underlyings", type: "address[]" }], name: "registerPool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const underlyingTokens = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], name: "underlyingTokens", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, _acceptOwner, _setPendingOwner, getUnderlyingPrice, getUnderlyingTokens, initialize, owner, pendingOwner, poolOf, price, registerPool, renounceOwnership, transferOwnership, underlyingTokens];
