/**
 * Autogenerated file. Do not edit manually. */
export const _removeRedemptionStrategy = { inputs: [{ internalType: "contract IRedemptionStrategy", name: "strategyToRemove", type: "address" }], name: "_removeRedemptionStrategy", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _resetRedemptionStrategies = { inputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "inputTokens", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "outputTokens", type: "address[]" }], name: "_resetRedemptionStrategies", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setDefaultOutputToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "_setDefaultOutputToken", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setRedemptionStrategies = { inputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "inputTokens", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "outputTokens", type: "address[]" }], name: "_setRedemptionStrategies", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setRedemptionStrategy = { inputs: [{ internalType: "contract IRedemptionStrategy", name: "strategy", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "_setRedemptionStrategy", outputs: [], stateMutability: "nonpayable", type: "function" };
export const amountOutAndSlippageOfSwap = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "uint256", name: "inputAmount", type: "uint256" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "amountOutAndSlippageOfSwap", outputs: [{ internalType: "uint256", name: "outputAmount", type: "uint256" }, { internalType: "uint256", name: "slippage", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const defaultOutputToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }], name: "defaultOutputToken", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getAllPairsStrategies = { inputs: [], name: "getAllPairsStrategies", outputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "inputTokens", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "outputTokens", type: "address[]" }], stateMutability: "view", type: "function" };
export const getAllRedemptionStrategies = { inputs: [], name: "getAllRedemptionStrategies", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getInputTokensByOutputToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "getInputTokensByOutputToken", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getRedemptionStrategies = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "getRedemptionStrategies", outputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "bytes[]", name: "strategiesData", type: "bytes[]" }], stateMutability: "view", type: "function" };
export const getRedemptionStrategy = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "getRedemptionStrategy", outputs: [{ internalType: "contract IRedemptionStrategy", name: "strategy", type: "address" }, { internalType: "bytes", name: "strategyData", type: "bytes" }], stateMutability: "view", type: "function" };
export const pairsStrategiesMatch = { inputs: [{ internalType: "contract IRedemptionStrategy[]", name: "configStrategies", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "configInputTokens", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "configOutputTokens", type: "address[]" }], name: "pairsStrategiesMatch", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const redemptionStrategiesByName = { inputs: [{ internalType: "string", name: "name", type: "string" }], name: "redemptionStrategiesByName", outputs: [{ internalType: "contract IRedemptionStrategy", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const redemptionStrategiesByTokens = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "redemptionStrategiesByTokens", outputs: [{ internalType: "contract IRedemptionStrategy", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const swap = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "uint256", name: "inputAmount", type: "uint256" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "swap", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export default [_removeRedemptionStrategy, _resetRedemptionStrategies, _setDefaultOutputToken, _setRedemptionStrategies, _setRedemptionStrategy, amountOutAndSlippageOfSwap, defaultOutputToken, getAllPairsStrategies, getAllRedemptionStrategies, getInputTokensByOutputToken, getRedemptionStrategies, getRedemptionStrategy, pairsStrategiesMatch, redemptionStrategiesByName, redemptionStrategiesByTokens, swap];
