/**
 * Autogenerated file. Do not edit manually. */
export const AdminRightsToggled = { anonymous: false, inputs: [{ indexed: false, internalType: "bool", name: "hasRights", type: "bool" }], name: "AdminRightsToggled", type: "event" };
export const Failure = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "error", type: "uint256" }, { indexed: false, internalType: "uint256", name: "info", type: "uint256" }, { indexed: false, internalType: "uint256", name: "detail", type: "uint256" }], name: "Failure", type: "event" };
export const FuseAdminRightsToggled = { anonymous: false, inputs: [{ indexed: false, internalType: "bool", name: "hasRights", type: "bool" }], name: "FuseAdminRightsToggled", type: "event" };
export const NewAdmin = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldAdmin", type: "address" }, { indexed: false, internalType: "address", name: "newAdmin", type: "address" }], name: "NewAdmin", type: "event" };
export const NewImplementation = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldImplementation", type: "address" }, { indexed: false, internalType: "address", name: "newImplementation", type: "address" }], name: "NewImplementation", type: "event" };
export const NewPendingAdmin = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingAdmin", type: "address" }, { indexed: false, internalType: "address", name: "newPendingAdmin", type: "address" }], name: "NewPendingAdmin", type: "event" };
export const NewPendingImplementation = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingImplementation", type: "address" }, { indexed: false, internalType: "address", name: "newPendingImplementation", type: "address" }], name: "NewPendingImplementation", type: "event" };
export const _acceptAdmin = { inputs: [], name: "_acceptAdmin", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _acceptImplementation = { inputs: [], name: "_acceptImplementation", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setPendingAdmin = { inputs: [{ internalType: "address", name: "newPendingAdmin", type: "address" }], name: "_setPendingAdmin", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setPendingImplementation = { inputs: [{ internalType: "address", name: "newPendingImplementation", type: "address" }], name: "_setPendingImplementation", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _toggleAdminRights = { inputs: [{ internalType: "bool", name: "hasRights", type: "bool" }], name: "_toggleAdminRights", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const admin = { inputs: [], name: "admin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const adminHasRights = { inputs: [], name: "adminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const comptrollerImplementation = { inputs: [], name: "comptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseAdmin = { inputs: [], name: "fuseAdmin", outputs: [{ internalType: "address payable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseAdminHasRights = { inputs: [], name: "fuseAdminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pendingAdmin = { inputs: [], name: "pendingAdmin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingComptrollerImplementation = { inputs: [], name: "pendingComptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [AdminRightsToggled, Failure, FuseAdminRightsToggled, NewAdmin, NewImplementation, NewPendingAdmin, NewPendingImplementation, _acceptAdmin, _acceptImplementation, _setPendingAdmin, _setPendingImplementation, _toggleAdminRights, admin, adminHasRights, comptrollerImplementation, fuseAdmin, fuseAdminHasRights, pendingAdmin, pendingComptrollerImplementation];
