/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _callPool_addressArray_bytesArray = { inputs: [{ internalType: "address[]", name: "targets", type: "address[]" }, { internalType: "bytes[]", name: "data", type: "bytes[]" }], name: "_callPool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _callPool_addressArray_bytes = { inputs: [{ internalType: "address[]", name: "targets", type: "address[]" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "_callPool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _editCErc20DelegateWhitelist = { inputs: [{ internalType: "address[]", name: "oldImplementations", type: "address[]" }, { internalType: "address[]", name: "newImplementations", type: "address[]" }, { internalType: "bool[]", name: "allowResign", type: "bool[]" }, { internalType: "bool[]", name: "statuses", type: "bool[]" }], name: "_editCErc20DelegateWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _editComptrollerImplementationWhitelist = { inputs: [{ internalType: "address[]", name: "oldImplementations", type: "address[]" }, { internalType: "address[]", name: "newImplementations", type: "address[]" }, { internalType: "bool[]", name: "statuses", type: "bool[]" }], name: "_editComptrollerImplementationWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _editPluginImplementationWhitelist = { inputs: [{ internalType: "address[]", name: "oldImplementations", type: "address[]" }, { internalType: "address[]", name: "newImplementations", type: "address[]" }, { internalType: "bool[]", name: "statuses", type: "bool[]" }], name: "_editPluginImplementationWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _latestCErc20Delegate = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "_latestCErc20Delegate", outputs: [{ internalType: "address", name: "implementation", type: "address" }, { internalType: "bool", name: "allowResign", type: "bool" }, { internalType: "bytes", name: "becomeImplementationData", type: "bytes" }], stateMutability: "view", type: "function" };
export const _latestCEtherDelegate = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "_latestCEtherDelegate", outputs: [{ internalType: "address", name: "implementation", type: "address" }, { internalType: "bool", name: "allowResign", type: "bool" }, { internalType: "bytes", name: "becomeImplementationData", type: "bytes" }], stateMutability: "view", type: "function" };
export const _latestPluginImplementation = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "_latestPluginImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const _registerComptrollerExtension = { inputs: [{ internalType: "address payable", name: "pool", type: "address" }, { internalType: "contract DiamondExtension", name: "extensionToAdd", type: "address" }, { internalType: "contract DiamondExtension", name: "extensionToReplace", type: "address" }], name: "_registerComptrollerExtension", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _resetGap = { inputs: [], name: "_resetGap", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setCErc20DelegateExtensions = { inputs: [{ internalType: "address", name: "cErc20Delegate", type: "address" }, { internalType: "contract DiamondExtension[]", name: "extensions", type: "address[]" }], name: "_setCErc20DelegateExtensions", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setComptrollerExtensions = { inputs: [{ internalType: "address", name: "comptroller", type: "address" }, { internalType: "contract DiamondExtension[]", name: "extensions", type: "address[]" }], name: "_setComptrollerExtensions", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setCustomInterestFeeRate = { inputs: [{ internalType: "address", name: "comptroller", type: "address" }, { internalType: "int256", name: "rate", type: "int256" }], name: "_setCustomInterestFeeRate", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setDefaultInterestFeeRate = { inputs: [{ internalType: "uint256", name: "_defaultInterestFeeRate", type: "uint256" }], name: "_setDefaultInterestFeeRate", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setLatestCErc20Delegate = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }, { internalType: "address", name: "newImplementation", type: "address" }, { internalType: "bool", name: "allowResign", type: "bool" }, { internalType: "bytes", name: "becomeImplementationData", type: "bytes" }], name: "_setLatestCErc20Delegate", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setLatestComptrollerImplementation = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }, { internalType: "address", name: "newImplementation", type: "address" }], name: "_setLatestComptrollerImplementation", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setLatestPluginImplementation = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }, { internalType: "address", name: "newImplementation", type: "address" }], name: "_setLatestPluginImplementation", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPoolLimits = { inputs: [{ internalType: "uint256", name: "_minBorrowEth", type: "uint256" }, { internalType: "uint256", name: "_maxSupplyEth", type: "uint256" }, { internalType: "uint256", name: "_maxUtilizationRate", type: "uint256" }], name: "_setPoolLimits", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _upgradePluginToLatestImplementation = { inputs: [{ internalType: "address", name: "cDelegator", type: "address" }], name: "_upgradePluginToLatestImplementation", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _withdrawAssets = { inputs: [{ internalType: "address", name: "erc20Contract", type: "address" }], name: "_withdrawAssets", outputs: [], stateMutability: "nonpayable", type: "function" };
export const autoUpgradePool = { inputs: [{ internalType: "contract IComptroller", name: "pool", type: "address" }], name: "autoUpgradePool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const cErc20DelegateExtensions = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], name: "cErc20DelegateExtensions", outputs: [{ internalType: "contract DiamondExtension", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const cErc20DelegateWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }, { internalType: "bool", name: "", type: "bool" }], name: "cErc20DelegateWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const cEtherDelegateWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }, { internalType: "bool", name: "", type: "bool" }], name: "cEtherDelegateWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const comptrollerExtensions = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], name: "comptrollerExtensions", outputs: [{ internalType: "contract DiamondExtension", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const comptrollerImplementationWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "comptrollerImplementationWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const customInterestFeeRates = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "customInterestFeeRates", outputs: [{ internalType: "int256", name: "", type: "int256" }], stateMutability: "view", type: "function" };
export const defaultInterestFeeRate = { inputs: [], name: "defaultInterestFeeRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const deployCErc20 = { inputs: [{ internalType: "bytes", name: "constructorData", type: "bytes" }], name: "deployCErc20", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "nonpayable", type: "function" };
export const getCErc20DelegateExtensions = { inputs: [{ internalType: "address", name: "cErc20Delegate", type: "address" }], name: "getCErc20DelegateExtensions", outputs: [{ internalType: "contract DiamondExtension[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getComptrollerExtensions = { inputs: [{ internalType: "address", name: "comptroller", type: "address" }], name: "getComptrollerExtensions", outputs: [{ internalType: "contract DiamondExtension[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getMinBorrowEth = { inputs: [{ internalType: "contract ICErc20", name: "_ctoken", type: "address" }], name: "getMinBorrowEth", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "uint256", name: "_defaultInterestFeeRate", type: "uint256" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const interestFeeRate = { inputs: [], name: "interestFeeRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const latestCErc20Delegate = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }], name: "latestCErc20Delegate", outputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "bool", name: "", type: "bool" }, { internalType: "bytes", name: "", type: "bytes" }], stateMutability: "view", type: "function" };
export const latestComptrollerImplementation = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }], name: "latestComptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const latestPluginImplementation = { inputs: [{ internalType: "address", name: "oldImplementation", type: "address" }], name: "latestPluginImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const marketsCounter = { inputs: [], name: "marketsCounter", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxSupplyEth = { inputs: [], name: "maxSupplyEth", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxUtilizationRate = { inputs: [], name: "maxUtilizationRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const minBorrowEth = { inputs: [], name: "minBorrowEth", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pluginImplementationWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "pluginImplementationWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const toggleAutoimplementations = { inputs: [{ internalType: "contract IComptroller", name: "pool", type: "address" }, { internalType: "bool", name: "enabled", type: "bool" }], name: "toggleAutoimplementations", outputs: [], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, _acceptOwner, _callPool_addressArray_bytesArray, _callPool_addressArray_bytes, _editCErc20DelegateWhitelist, _editComptrollerImplementationWhitelist, _editPluginImplementationWhitelist, _latestCErc20Delegate, _latestCEtherDelegate, _latestPluginImplementation, _registerComptrollerExtension, _resetGap, _setCErc20DelegateExtensions, _setComptrollerExtensions, _setCustomInterestFeeRate, _setDefaultInterestFeeRate, _setLatestCErc20Delegate, _setLatestComptrollerImplementation, _setLatestPluginImplementation, _setPendingOwner, _setPoolLimits, _upgradePluginToLatestImplementation, _withdrawAssets, autoUpgradePool, cErc20DelegateExtensions, cErc20DelegateWhitelist, cEtherDelegateWhitelist, comptrollerExtensions, comptrollerImplementationWhitelist, customInterestFeeRates, defaultInterestFeeRate, deployCErc20, getCErc20DelegateExtensions, getComptrollerExtensions, getMinBorrowEth, initialize, interestFeeRate, latestCErc20Delegate, latestComptrollerImplementation, latestPluginImplementation, marketsCounter, maxSupplyEth, maxUtilizationRate, minBorrowEth, owner, pendingOwner, pluginImplementationWhitelist, renounceOwnership, toggleAutoimplementations, transferOwnership];
