/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const directory = { inputs: [], name: "directory", outputs: [{ internalType: "contract FusePoolDirectory", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getFlywheelsToClaim = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "getFlywheelsToClaim", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { internalType: "contract IComptroller[]", name: "", type: "address[]" }, { internalType: "address[][]", name: "", type: "address[][]" }], stateMutability: "view", type: "function" };
export const getMaxBorrow = { inputs: [{ internalType: "address", name: "account", type: "address" }, { internalType: "contract ICErc20", name: "cTokenModify", type: "address" }], name: "getMaxBorrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const getMaxRedeem = { inputs: [{ internalType: "address", name: "account", type: "address" }, { internalType: "contract ICErc20", name: "cTokenModify", type: "address" }], name: "getMaxRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const getPoolOwnership = { inputs: [{ internalType: "contract IComptroller", name: "comptroller", type: "address" }], name: "getPoolOwnership", outputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "bool", name: "", type: "bool" }, { internalType: "bool", name: "", type: "bool" }, { components: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "admin", type: "address" }, { internalType: "bool", name: "adminHasRights", type: "bool" }, { internalType: "bool", name: "fuseAdminHasRights", type: "bool" }], internalType: "struct FusePoolLensSecondary.CTokenOwnership[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getRewardSpeedsByPool = { inputs: [{ internalType: "contract IComptroller", name: "comptroller", type: "address" }], name: "getRewardSpeedsByPool", outputs: [{ internalType: "contract ICErc20[]", name: "", type: "address[]" }, { internalType: "address[]", name: "", type: "address[]" }, { internalType: "address[]", name: "", type: "address[]" }, { internalType: "uint256[][]", name: "", type: "uint256[][]" }, { internalType: "uint256[][]", name: "", type: "uint256[][]" }], stateMutability: "view", type: "function" };
export const getRewardSpeedsByPools = { inputs: [{ internalType: "contract IComptroller[]", name: "comptrollers", type: "address[]" }], name: "getRewardSpeedsByPools", outputs: [{ internalType: "contract ICErc20[][]", name: "", type: "address[][]" }, { internalType: "address[][]", name: "", type: "address[][]" }, { internalType: "address[][]", name: "", type: "address[][]" }, { internalType: "uint256[][][]", name: "", type: "uint256[][][]" }, { internalType: "uint256[][][]", name: "", type: "uint256[][][]" }], stateMutability: "view", type: "function" };
export const getRewardsDistributorsBySupplier = { inputs: [{ internalType: "address", name: "supplier", type: "address" }], name: "getRewardsDistributorsBySupplier", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { internalType: "contract IComptroller[]", name: "", type: "address[]" }, { internalType: "address[][]", name: "", type: "address[][]" }], stateMutability: "view", type: "function" };
export const getUnclaimedRewardsByDistributors = { inputs: [{ internalType: "address", name: "holder", type: "address" }, { internalType: "contract IRewardsDistributor[]", name: "distributors", type: "address[]" }], name: "getUnclaimedRewardsByDistributors", outputs: [{ internalType: "address[]", name: "", type: "address[]" }, { internalType: "uint256[]", name: "", type: "uint256[]" }, { internalType: "contract ICErc20[][]", name: "", type: "address[][]" }, { internalType: "uint256[2][][]", name: "", type: "uint256[2][][]" }, { internalType: "uint256[]", name: "", type: "uint256[]" }], stateMutability: "nonpayable", type: "function" };
export const initialize = { inputs: [{ internalType: "contract FusePoolDirectory", name: "_directory", type: "address" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [Initialized, directory, getFlywheelsToClaim, getMaxBorrow, getMaxRedeem, getPoolOwnership, getRewardSpeedsByPool, getRewardSpeedsByPools, getRewardsDistributorsBySupplier, getUnclaimedRewardsByDistributors, initialize];
