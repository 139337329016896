var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { arbitrum, bsc, chapel, ethereum, evmos, fantom, ganache, lineagoerli, moonbeam, neondevnet, polygon, } from "@midas-capital/chains";
import { SupportedChains, } from "@midas-capital/types";
import { constants, utils } from "ethers";
import { filterOnlyObjectProperties, filterPoolName } from "../MidasSdk/utils";
export const ChainSupportedAssets = {
    [SupportedChains.bsc]: bsc.assets,
    [SupportedChains.polygon]: polygon.assets,
    [SupportedChains.ganache]: ganache.assets,
    [SupportedChains.evmos]: evmos.assets,
    [SupportedChains.chapel]: chapel.assets,
    [SupportedChains.moonbeam]: moonbeam.assets,
    [SupportedChains.neon_devnet]: neondevnet.assets,
    [SupportedChains.arbitrum]: arbitrum.assets,
    [SupportedChains.fantom]: fantom.assets,
    [SupportedChains.lineagoerli]: lineagoerli.assets,
    [SupportedChains.ethereum]: ethereum.assets,
};
export function withFusePools(Base) {
    return class FusePools extends Base {
        fetchFusePoolData(poolId, overrides = {}) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const { comptroller, name: _unfiliteredName, creator, blockPosted, timestampPosted, } = yield this.contracts.FusePoolDirectory.callStatic.pools(Number(poolId), overrides);
                if (comptroller === constants.AddressZero) {
                    return null;
                }
                const name = filterPoolName(_unfiliteredName);
                const assets = (yield this.contracts.FusePoolLens.callStatic.getPoolAssetsWithData(comptroller, overrides)).map(filterOnlyObjectProperties);
                let totalLiquidityNative = 0;
                let totalAvailableLiquidityNative = 0;
                let totalSupplyBalanceNative = 0;
                let totalBorrowBalanceNative = 0;
                let totalSuppliedNative = 0;
                let totalBorrowedNative = 0;
                let suppliedForUtilization = 0;
                let borrowedForUtilization = 0;
                let utilization = 0;
                const promises = [];
                for (let i = 0; i < assets.length; i++) {
                    const asset = assets[i];
                    asset.isBorrowPaused = asset.borrowGuardianPaused;
                    asset.isSupplyPaused = asset.mintGuardianPaused;
                    asset.plugin = this.marketToPlugin[asset.cToken];
                    const _asset = ChainSupportedAssets[this.chainId].find((ass) => ass.underlying === asset.underlyingToken);
                    if (_asset) {
                        asset.underlyingSymbol = (_a = _asset.symbol) !== null && _a !== void 0 ? _a : "";
                        asset.logoUrl = _asset.symbol
                            ? "https://d1912tcoux65lj.cloudfront.net/token/96x96/" + _asset.symbol.toLowerCase() + ".png"
                            : "";
                        asset.originalSymbol = _asset.originalSymbol ? _asset.originalSymbol : undefined;
                    }
                    asset.netSupplyBalance = asset.supplyBalance.gt(asset.borrowBalance)
                        ? asset.supplyBalance.sub(asset.borrowBalance)
                        : constants.Zero;
                    asset.netSupplyBalanceNative =
                        Number(utils.formatUnits(asset.netSupplyBalance, asset.underlyingDecimals)) *
                            Number(utils.formatUnits(asset.underlyingPrice, 18));
                    asset.supplyBalanceNative =
                        Number(utils.formatUnits(asset.supplyBalance, asset.underlyingDecimals)) *
                            Number(utils.formatUnits(asset.underlyingPrice, 18));
                    asset.borrowBalanceNative =
                        Number(utils.formatUnits(asset.borrowBalance, asset.underlyingDecimals)) *
                            Number(utils.formatUnits(asset.underlyingPrice, 18));
                    totalSupplyBalanceNative += asset.supplyBalanceNative;
                    totalBorrowBalanceNative += asset.borrowBalanceNative;
                    asset.totalSupplyNative =
                        Number(utils.formatUnits(asset.totalSupply, asset.underlyingDecimals)) *
                            Number(utils.formatUnits(asset.underlyingPrice, 18));
                    asset.totalBorrowNative =
                        Number(utils.formatUnits(asset.totalBorrow, asset.underlyingDecimals)) *
                            Number(utils.formatUnits(asset.underlyingPrice, 18));
                    if (asset.totalSupplyNative === 0) {
                        asset.utilization = 0;
                    }
                    else {
                        asset.utilization = (asset.totalBorrowNative / asset.totalSupplyNative) * 100;
                    }
                    totalSuppliedNative += asset.totalSupplyNative;
                    totalBorrowedNative += asset.totalBorrowNative;
                    const assetLiquidityNative = Number(utils.formatUnits(asset.liquidity, asset.underlyingDecimals)) *
                        Number(utils.formatUnits(asset.underlyingPrice, 18));
                    asset.liquidityNative = assetLiquidityNative;
                    totalAvailableLiquidityNative += asset.isBorrowPaused ? 0 : assetLiquidityNative;
                    totalLiquidityNative += asset.liquidityNative;
                    if (!asset.isBorrowPaused) {
                        suppliedForUtilization += asset.totalSupplyNative;
                        borrowedForUtilization += asset.totalBorrowNative;
                    }
                    const supportedAsset = this.supportedAssets.find((_asset) => _asset.underlying === asset.underlyingToken);
                    asset.extraDocs = supportedAsset ? supportedAsset.extraDocs : "";
                }
                if (suppliedForUtilization !== 0) {
                    utilization = (borrowedForUtilization / suppliedForUtilization) * 100;
                }
                yield Promise.all(promises);
                // Sort array by liquidity, array is mutated in place with .sort()
                assets.sort((a, b) => b.liquidityNative - a.liquidityNative);
                return {
                    id: Number(poolId),
                    chainId: this.chainId,
                    assets,
                    creator,
                    comptroller,
                    name,
                    totalLiquidityNative,
                    totalAvailableLiquidityNative,
                    totalSuppliedNative,
                    totalBorrowedNative,
                    totalSupplyBalanceNative,
                    totalBorrowBalanceNative,
                    blockPosted,
                    timestampPosted,
                    underlyingTokens: assets.map((a) => a.underlyingToken),
                    underlyingSymbols: assets.map((a) => a.underlyingSymbol),
                    utilization,
                };
            });
        }
        fetchPoolsManual(overrides = {}) {
            return __awaiter(this, void 0, void 0, function* () {
                const [poolIndexes, pools] = yield this.contracts.FusePoolDirectory.callStatic.getActivePools(overrides);
                if (!pools.length || !poolIndexes.length) {
                    return undefined;
                }
                const poolData = yield Promise.all(poolIndexes.map((poolId) => {
                    if (this.chainId === SupportedChains.polygon && poolId.toString() === "1")
                        return null;
                    return this.fetchFusePoolData(poolId.toString(), overrides).catch((error) => {
                        this.logger.error(`Pool ID ${poolId} wasn't able to be fetched from FusePoolLens without error.`, error);
                        return null;
                    });
                }));
                return poolData.filter((p) => !!p);
            });
        }
        fetchPools({ filter, options, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const isCreatedPools = filter === "created-pools";
                const isVerifiedPools = filter === "verified-pools";
                const isUnverifiedPools = filter === "unverified-pools";
                const req = isCreatedPools
                    ? this.contracts.FusePoolLens.callStatic.getPoolsByAccountWithData(options.from)
                    : isVerifiedPools
                        ? this.contracts.FusePoolDirectory.callStatic.getPublicPoolsByVerification(true)
                        : isUnverifiedPools
                            ? this.contracts.FusePoolDirectory.callStatic.getPublicPoolsByVerification(false)
                            : this.contracts.FusePoolLens.callStatic.getPublicPoolsWithData();
                const whitelistedPoolsRequest = this.contracts.FusePoolLens.callStatic.getWhitelistedPoolsByAccountWithData(options.from);
                const responses = yield Promise.all([req, whitelistedPoolsRequest]);
                const [pools, whitelistedPools] = yield Promise.all(responses.map((poolData) => __awaiter(this, void 0, void 0, function* () {
                    return yield Promise.all(poolData[0].map((_id) => {
                        return this.fetchFusePoolData(_id.toString());
                    }));
                })));
                const whitelistedIds = whitelistedPools.map((pool) => pool === null || pool === void 0 ? void 0 : pool.id);
                const filteredPools = pools.filter((pool) => !whitelistedIds.includes(pool === null || pool === void 0 ? void 0 : pool.id));
                return [...filteredPools, ...whitelistedPools].filter((p) => !!p);
            });
        }
    };
}
