import Filter from "bad-words";
import { Contract, utils } from "ethers";
import ComptrollerABI from "../../abis/Comptroller";
import UnitrollerArtifact from "../../artifacts/Unitroller.json";
export function filterOnlyObjectProperties(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([k]) => isNaN(k)));
}
export const filter = new Filter({ placeHolder: " " });
filter.addWords(...["R1", "R2", "R3", "R4", "R5", "R6", "R7"]);
export const filterPoolName = (name) => {
    return filter.clean(name);
};
export const getSaltsHash = (from, poolName, blockNumber) => {
    return utils.solidityKeccak256(["address", "string", "uint"], [from, poolName, blockNumber]);
};
export const getBytecodeHash = (fuseFeeDistributorAddress) => {
    return utils.keccak256(UnitrollerArtifact.bytecode.object + new utils.AbiCoder().encode(["address"], [fuseFeeDistributorAddress]).slice(2));
};
export const getPoolAddress = (from, poolName, marketsCounter, fuseFeeDistributorAddress, fusePoolDirectoryAddress) => {
    return utils.getCreate2Address(fusePoolDirectoryAddress, getSaltsHash(from, poolName, marketsCounter), getBytecodeHash(fuseFeeDistributorAddress));
};
export const getPoolUnitroller = (poolAddress, signer) => {
    return new Contract(poolAddress, UnitrollerArtifact.abi, signer);
};
export const getPoolComptroller = (poolAddress, signer) => {
    return new Contract(poolAddress, ComptrollerABI, signer);
};
export const getContract = (address, abi, providerOrSigner) => {
    return new Contract(address, abi, providerOrSigner);
};
