/**
 * Autogenerated file. Do not edit manually. */
export const ActionPaused = { anonymous: false, inputs: [{ indexed: false, internalType: "string", name: "action", type: "string" }, { indexed: false, internalType: "bool", name: "pauseState", type: "bool" }], name: "ActionPaused", type: "event" };
export const Failure = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "error", type: "uint256" }, { indexed: false, internalType: "uint256", name: "info", type: "uint256" }, { indexed: false, internalType: "uint256", name: "detail", type: "uint256" }], name: "Failure", type: "event" };
export const MarketActionPaused = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "string", name: "action", type: "string" }, { indexed: false, internalType: "bool", name: "pauseState", type: "bool" }], name: "MarketActionPaused", type: "event" };
export const MarketUnlisted = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "MarketUnlisted", type: "event" };
export const NewBorrowCap = { anonymous: false, inputs: [{ indexed: true, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "uint256", name: "newBorrowCap", type: "uint256" }], name: "NewBorrowCap", type: "event" };
export const NewBorrowCapGuardian = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldBorrowCapGuardian", type: "address" }, { indexed: false, internalType: "address", name: "newBorrowCapGuardian", type: "address" }], name: "NewBorrowCapGuardian", type: "event" };
export const NewPauseGuardian = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPauseGuardian", type: "address" }, { indexed: false, internalType: "address", name: "newPauseGuardian", type: "address" }], name: "NewPauseGuardian", type: "event" };
export const NewSupplyCap = { anonymous: false, inputs: [{ indexed: true, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "uint256", name: "newSupplyCap", type: "uint256" }], name: "NewSupplyCap", type: "event" };
export const _blacklistBorrowingAgainstCollateral = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "bool", name: "blacklisted", type: "bool" }], name: "_blacklistBorrowingAgainstCollateral", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _blacklistBorrowingAgainstCollateralWhitelist = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "account", type: "address" }, { internalType: "bool", name: "whitelisted", type: "bool" }], name: "_blacklistBorrowingAgainstCollateralWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _borrowCapWhitelist = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "account", type: "address" }, { internalType: "bool", name: "whitelisted", type: "bool" }], name: "_borrowCapWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _borrowGuardianPaused = { inputs: [], name: "_borrowGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const _getExtensionFunctions = { inputs: [], name: "_getExtensionFunctions", outputs: [{ internalType: "bytes4[]", name: "", type: "bytes4[]" }], stateMutability: "pure", type: "function" };
export const _mintGuardianPaused = { inputs: [], name: "_mintGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const _removeFlywheel = { inputs: [{ internalType: "address", name: "flywheelAddress", type: "address" }], name: "_removeFlywheel", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _setBorrowCapForCollateral = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "uint256", name: "borrowCap", type: "uint256" }], name: "_setBorrowCapForCollateral", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setBorrowCapForCollateralWhitelist = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "account", type: "address" }, { internalType: "bool", name: "whitelisted", type: "bool" }], name: "_setBorrowCapForCollateralWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setBorrowCapGuardian = { inputs: [{ internalType: "address", name: "newBorrowCapGuardian", type: "address" }], name: "_setBorrowCapGuardian", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setBorrowPaused = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }, { internalType: "bool", name: "state", type: "bool" }], name: "_setBorrowPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _setMarketBorrowCaps = { inputs: [{ internalType: "contract ICErc20[]", name: "cTokens", type: "address[]" }, { internalType: "uint256[]", name: "newBorrowCaps", type: "uint256[]" }], name: "_setMarketBorrowCaps", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setMarketSupplyCaps = { inputs: [{ internalType: "contract ICErc20[]", name: "cTokens", type: "address[]" }, { internalType: "uint256[]", name: "newSupplyCaps", type: "uint256[]" }], name: "_setMarketSupplyCaps", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setMintPaused = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }, { internalType: "bool", name: "state", type: "bool" }], name: "_setMintPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _setPauseGuardian = { inputs: [{ internalType: "address", name: "newPauseGuardian", type: "address" }], name: "_setPauseGuardian", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setSeizePaused = { inputs: [{ internalType: "bool", name: "state", type: "bool" }], name: "_setSeizePaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _setTransferPaused = { inputs: [{ internalType: "bool", name: "state", type: "bool" }], name: "_setTransferPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const _supplyCapWhitelist = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "account", type: "address" }, { internalType: "bool", name: "whitelisted", type: "bool" }], name: "_supplyCapWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _unsupportMarket = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "_unsupportMarket", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const accountAssets = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], name: "accountAssets", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const addNonAccruingFlywheel = { inputs: [{ internalType: "address", name: "flywheelAddress", type: "address" }], name: "addNonAccruingFlywheel", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const admin = { inputs: [], name: "admin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const adminHasRights = { inputs: [], name: "adminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const allBorrowers = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "allBorrowers", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const allMarkets = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "allMarkets", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const autoImplementation = { inputs: [], name: "autoImplementation", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const borrowCapForCollateral = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "borrowCapForCollateral", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowCapGuardian = { inputs: [], name: "borrowCapGuardian", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const borrowCaps = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "borrowCaps", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowGuardianPaused = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "borrowGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const borrowingAgainstCollateralBlacklist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "borrowingAgainstCollateralBlacklist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const cTokensByUnderlying = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "cTokensByUnderlying", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const closeFactorMantissa = { inputs: [], name: "closeFactorMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const comptrollerImplementation = { inputs: [], name: "comptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const enforceWhitelist = { inputs: [], name: "enforceWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const fuseAdmin = { inputs: [], name: "fuseAdmin", outputs: [{ internalType: "address payable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseAdminHasRights = { inputs: [], name: "fuseAdminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const getAccruingFlywheels = { inputs: [], name: "getAccruingFlywheels", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getAllBorrowers = { inputs: [], name: "getAllBorrowers", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getAllMarkets = { inputs: [], name: "getAllMarkets", outputs: [{ internalType: "contract ICErc20[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getAssetAsCollateralValueCap = { inputs: [{ internalType: "contract ICErc20", name: "collateral", type: "address" }, { internalType: "contract ICErc20", name: "cTokenModify", type: "address" }, { internalType: "bool", name: "redeeming", type: "bool" }, { internalType: "address", name: "account", type: "address" }], name: "getAssetAsCollateralValueCap", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getRewardsDistributors = { inputs: [], name: "getRewardsDistributors", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getWhitelist = { inputs: [], name: "getWhitelist", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getWhitelistedBorrowersBorrows = { inputs: [{ internalType: "address", name: "cToken", type: "address" }], name: "getWhitelistedBorrowersBorrows", outputs: [{ internalType: "uint256", name: "borrowed", type: "uint256" }], stateMutability: "view", type: "function" };
export const getWhitelistedSuppliersSupply = { inputs: [{ internalType: "address", name: "cToken", type: "address" }], name: "getWhitelistedSuppliersSupply", outputs: [{ internalType: "uint256", name: "supplied", type: "uint256" }], stateMutability: "view", type: "function" };
export const isBlacklistBorrowingAgainstCollateralWhitelisted = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "account", type: "address" }], name: "isBlacklistBorrowingAgainstCollateralWhitelisted", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isBorrowCapForCollateralWhitelisted = { inputs: [{ internalType: "address", name: "cTokenBorrow", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "account", type: "address" }], name: "isBorrowCapForCollateralWhitelisted", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isBorrowCapWhitelisted = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "account", type: "address" }], name: "isBorrowCapWhitelisted", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isSupplyCapWhitelisted = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "account", type: "address" }], name: "isSupplyCapWhitelisted", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isUserOfPool = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "isUserOfPool", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const liquidationIncentiveMantissa = { inputs: [], name: "liquidationIncentiveMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const markets = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "markets", outputs: [{ internalType: "bool", name: "isListed", type: "bool" }, { internalType: "uint256", name: "collateralFactorMantissa", type: "uint256" }], stateMutability: "view", type: "function" };
export const mintGuardianPaused = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "mintGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const nonAccruingRewardsDistributors = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "nonAccruingRewardsDistributors", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const oracle = { inputs: [], name: "oracle", outputs: [{ internalType: "contract BasePriceOracle", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pauseGuardian = { inputs: [], name: "pauseGuardian", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingAdmin = { inputs: [], name: "pendingAdmin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingComptrollerImplementation = { inputs: [], name: "pendingComptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const rewardsDistributors = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "rewardsDistributors", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const seizeGuardianPaused = { inputs: [], name: "seizeGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const suppliers = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "suppliers", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const supplyCaps = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "supplyCaps", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transferGuardianPaused = { inputs: [], name: "transferGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const whitelist = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "whitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const whitelistArray = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "whitelistArray", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [ActionPaused, Failure, MarketActionPaused, MarketUnlisted, NewBorrowCap, NewBorrowCapGuardian, NewPauseGuardian, NewSupplyCap, _blacklistBorrowingAgainstCollateral, _blacklistBorrowingAgainstCollateralWhitelist, _borrowCapWhitelist, _borrowGuardianPaused, _getExtensionFunctions, _mintGuardianPaused, _removeFlywheel, _setBorrowCapForCollateral, _setBorrowCapForCollateralWhitelist, _setBorrowCapGuardian, _setBorrowPaused, _setMarketBorrowCaps, _setMarketSupplyCaps, _setMintPaused, _setPauseGuardian, _setSeizePaused, _setTransferPaused, _supplyCapWhitelist, _unsupportMarket, accountAssets, addNonAccruingFlywheel, admin, adminHasRights, allBorrowers, allMarkets, autoImplementation, borrowCapForCollateral, borrowCapGuardian, borrowCaps, borrowGuardianPaused, borrowingAgainstCollateralBlacklist, cTokensByUnderlying, closeFactorMantissa, comptrollerImplementation, enforceWhitelist, fuseAdmin, fuseAdminHasRights, getAccruingFlywheels, getAllBorrowers, getAllMarkets, getAssetAsCollateralValueCap, getRewardsDistributors, getWhitelist, getWhitelistedBorrowersBorrows, getWhitelistedSuppliersSupply, isBlacklistBorrowingAgainstCollateralWhitelisted, isBorrowCapForCollateralWhitelisted, isBorrowCapWhitelisted, isSupplyCapWhitelisted, isUserOfPool, liquidationIncentiveMantissa, markets, mintGuardianPaused, nonAccruingRewardsDistributors, oracle, pauseGuardian, pendingAdmin, pendingComptrollerImplementation, rewardsDistributors, seizeGuardianPaused, suppliers, supplyCaps, transferGuardianPaused, whitelist, whitelistArray];
