import { utils } from "ethers";
const daysPerYear = 365;
export function withConvertMantissa(Base) {
    return class ConvertMantissa extends Base {
        /**
         * Directly taken from the compound.finance docs:
         * https://compound.finance/docs#protocol-math
         */
        ratePerBlockToAPY(ratePerBlock, blocksPerMin) {
            const blocksPerDay = blocksPerMin * 60 * 24;
            const rateAsNumber = Number(utils.formatUnits(ratePerBlock, 18));
            return (Math.pow(rateAsNumber * blocksPerDay + 1, daysPerYear) - 1) * 100;
        }
    };
}
