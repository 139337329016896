var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendTransactionToSafeLiquidator } from "./index";
export default function liquidateUnhealthyBorrows(sdk, liquidatablePool) {
    return __awaiter(this, void 0, void 0, function* () {
        const erroredLiquidations = [];
        const succeededLiquidations = [];
        for (const liquidation of liquidatablePool.liquidations) {
            const { method, args, value } = liquidation;
            sdk.logger.info(`Sending liquidation for:\n comptroller: ${liquidatablePool.comptroller}\n method: ${method}\n params: ${JSON.stringify(args)}\n value: ${value}\n`);
            try {
                const transactionResponse = yield sendTransactionToSafeLiquidator(sdk, method, args, value);
                succeededLiquidations.push(transactionResponse);
            }
            catch (error) {
                const msg = "Error sending sendTransactionToSafeLiquidator transaction: " + error;
                erroredLiquidations.push({ tx: liquidation, error: msg });
            }
        }
        return [erroredLiquidations, succeededLiquidations];
    });
}
