/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const addVault = { inputs: [{ internalType: "address", name: "vault", type: "address" }], name: "addVault", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const getAllVaults = { inputs: [], name: "getAllVaults", outputs: [{ internalType: "contract OptimizedAPRVaultBase[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getClaimableRewards = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getClaimableRewards", outputs: [{ components: [{ internalType: "address", name: "flywheel", type: "address" }, { internalType: "address", name: "vault", type: "address" }, { internalType: "address", name: "rewardToken", type: "address" }, { internalType: "string", name: "rewardTokenName", type: "string" }, { internalType: "string", name: "rewardTokenSymbol", type: "string" }, { internalType: "uint8", name: "rewardTokenDecimals", type: "uint8" }, { internalType: "uint256", name: "rewards", type: "uint256" }], internalType: "struct OptimizedVaultsRegistry.ClaimableRewardsInfo[]", name: "rewardsData", type: "tuple[]" }], stateMutability: "nonpayable", type: "function" };
export const getLatestVaultExtensions = { inputs: [{ internalType: "address", name: "vault", type: "address" }], name: "getLatestVaultExtensions", outputs: [{ internalType: "contract OptimizedAPRVaultExtension[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getVaultsData = { inputs: [], name: "getVaultsData", outputs: [{ components: [{ internalType: "address", name: "vault", type: "address" }, { internalType: "address", name: "asset", type: "address" }, { internalType: "string", name: "assetSymbol", type: "string" }, { internalType: "uint8", name: "assetDecimals", type: "uint8" }, { internalType: "uint256", name: "estimatedTotalAssets", type: "uint256" }, { internalType: "uint256", name: "apr", type: "uint256" }, { internalType: "uint256", name: "adaptersCount", type: "uint256" }, { internalType: "bool", name: "isEmergencyStopped", type: "bool" }, { internalType: "uint64", name: "performanceFee", type: "uint64" }, { internalType: "uint64", name: "depositFee", type: "uint64" }, { internalType: "uint64", name: "withdrawalFee", type: "uint64" }, { internalType: "uint64", name: "managementFee", type: "uint64" }, { components: [{ internalType: "address", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }, { internalType: "address", name: "market", type: "address" }, { internalType: "address", name: "pool", type: "address" }], internalType: "struct OptimizedVaultsRegistry.AdapterInfo[]", name: "adaptersData", type: "tuple[]" }], internalType: "struct OptimizedVaultsRegistry.VaultInfo[]", name: "vaultsData", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const removeVault = { inputs: [{ internalType: "address", name: "vault", type: "address" }], name: "removeVault", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setEmergencyExit = { inputs: [], name: "setEmergencyExit", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setLatestVaultExtensions = { inputs: [{ internalType: "address", name: "vault", type: "address" }, { internalType: "contract OptimizedAPRVaultExtension[]", name: "extensions", type: "address[]" }], name: "setLatestVaultExtensions", outputs: [], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const vaults = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "vaults", outputs: [{ internalType: "contract OptimizedAPRVaultBase", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, _acceptOwner, _setPendingOwner, addVault, getAllVaults, getClaimableRewards, getLatestVaultExtensions, getVaultsData, initialize, owner, pendingOwner, removeVault, renounceOwnership, setEmergencyExit, setLatestVaultExtensions, transferOwnership, vaults];
