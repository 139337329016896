var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber, utils } from "ethers";
export const SCALE_FACTOR_ONE_18_WEI = BigNumber.from(10).pow(18);
export const SCALE_FACTOR_UNDERLYING_DECIMALS = (asset) => BigNumber.from(10).pow(18 - asset.underlyingDecimals.toNumber());
export function fetchGasLimitForTransaction(sdk, method, tx) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return (yield sdk.provider.estimateGas(tx)).mul(11).div(10);
        }
        catch (error) {
            throw `Failed to estimate gas before signing and sending ${method} transaction: ${error}`;
        }
    });
}
export const logLiquidation = (sdk, borrower, exchangeToTokenAddress, liquidationAmount, liquidationTokenSymbol, liquidationStrategy, debtFundingStrategies) => {
    sdk.logger.info(`Gathered transaction data for safeLiquidate a ${liquidationTokenSymbol} borrow of kind ${liquidationStrategy}:
         - Liquidation Amount: ${utils.formatEther(liquidationAmount)}
         - Underlying Collateral Token: ${borrower.collateral[0].underlyingSymbol} ${borrower.collateral[0].cToken}
         - Underlying Debt Token: ${borrower.debt[0].underlyingSymbol} ${borrower.debt[0].cToken}
         - Funding the liquidation with: ${debtFundingStrategies}
         - Exchanging liquidated tokens to: ${exchangeToTokenAddress}
         - Borrower: ${borrower.account}
         `);
};
