/**
 * Autogenerated file. Do not edit manually. */
export const AccrueInterest = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "cashPrior", type: "uint256" }, { indexed: false, internalType: "uint256", name: "interestAccumulated", type: "uint256" }, { indexed: false, internalType: "uint256", name: "borrowIndex", type: "uint256" }, { indexed: false, internalType: "uint256", name: "totalBorrows", type: "uint256" }], name: "AccrueInterest", type: "event" };
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Approval", type: "event" };
export const Failure = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "error", type: "uint256" }, { indexed: false, internalType: "uint256", name: "info", type: "uint256" }, { indexed: false, internalType: "uint256", name: "detail", type: "uint256" }], name: "Failure", type: "event" };
export const Flash = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "receiver", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Flash", type: "event" };
export const NewAdminFee = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldAdminFeeMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newAdminFeeMantissa", type: "uint256" }], name: "NewAdminFee", type: "event" };
export const NewFuseFee = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldFuseFeeMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newFuseFeeMantissa", type: "uint256" }], name: "NewFuseFee", type: "event" };
export const NewMarketInterestRateModel = { anonymous: false, inputs: [{ indexed: false, internalType: "contract InterestRateModel", name: "oldInterestRateModel", type: "address" }, { indexed: false, internalType: "contract InterestRateModel", name: "newInterestRateModel", type: "address" }], name: "NewMarketInterestRateModel", type: "event" };
export const NewReserveFactor = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldReserveFactorMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newReserveFactorMantissa", type: "uint256" }], name: "NewReserveFactor", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Transfer", type: "event" };
export const _getExtensionFunctions = { inputs: [], name: "_getExtensionFunctions", outputs: [{ internalType: "bytes4[]", name: "", type: "bytes4[]" }], stateMutability: "pure", type: "function" };
export const _setAdminFee = { inputs: [{ internalType: "uint256", name: "newAdminFeeMantissa", type: "uint256" }], name: "_setAdminFee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setInterestRateModel = { inputs: [{ internalType: "contract InterestRateModel", name: "newInterestRateModel", type: "address" }], name: "_setInterestRateModel", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setNameAndSymbol = { inputs: [{ internalType: "string", name: "_name", type: "string" }, { internalType: "string", name: "_symbol", type: "string" }], name: "_setNameAndSymbol", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setReserveFactor = { inputs: [{ internalType: "uint256", name: "newReserveFactorMantissa", type: "uint256" }], name: "_setReserveFactor", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const accrualBlockNumber = { inputs: [], name: "accrualBlockNumber", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const accrueInterest = { inputs: [], name: "accrueInterest", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const adminFeeMantissa = { inputs: [], name: "adminFeeMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const allowance = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }], name: "allowance", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "approve", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const asCTokenExtensionInterface = { inputs: [], name: "asCTokenExtensionInterface", outputs: [{ internalType: "contract CTokenFirstExtension", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const balanceOf = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "balanceOf", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const balanceOfUnderlying = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "balanceOfUnderlying", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowBalanceCurrent = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "borrowBalanceCurrent", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowIndex = { inputs: [], name: "borrowIndex", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowRatePerBlock = { inputs: [], name: "borrowRatePerBlock", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowRatePerBlockAfterBorrow = { inputs: [{ internalType: "uint256", name: "borrowAmount", type: "uint256" }], name: "borrowRatePerBlockAfterBorrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const comptroller = { inputs: [], name: "comptroller", outputs: [{ internalType: "contract IComptroller", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const exchangeRateCurrent = { inputs: [], name: "exchangeRateCurrent", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const feeSeizeShareMantissa = { inputs: [], name: "feeSeizeShareMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const flash = { inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "flash", outputs: [], stateMutability: "nonpayable", type: "function" };
export const fuseAdmin = { inputs: [], name: "fuseAdmin", outputs: [{ internalType: "address payable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseFeeMantissa = { inputs: [], name: "fuseFeeMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getAccountSnapshot = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getAccountSnapshot", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getTotalUnderlyingSupplied = { inputs: [], name: "getTotalUnderlyingSupplied", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const implementation = { inputs: [], name: "implementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const interestRateModel = { inputs: [], name: "interestRateModel", outputs: [{ internalType: "contract InterestRateModel", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const isCEther = { inputs: [], name: "isCEther", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const multicall = { inputs: [{ internalType: "bytes[]", name: "data", type: "bytes[]" }], name: "multicall", outputs: [{ internalType: "bytes[]", name: "results", type: "bytes[]" }], stateMutability: "payable", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const protocolSeizeShareMantissa = { inputs: [], name: "protocolSeizeShareMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const reserveFactorMantissa = { inputs: [], name: "reserveFactorMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const supplyRatePerBlock = { inputs: [], name: "supplyRatePerBlock", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const supplyRatePerBlockAfterDeposit = { inputs: [{ internalType: "uint256", name: "mintAmount", type: "uint256" }], name: "supplyRatePerBlockAfterDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const supplyRatePerBlockAfterWithdraw = { inputs: [{ internalType: "uint256", name: "withdrawAmount", type: "uint256" }], name: "supplyRatePerBlockAfterWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const totalAdminFees = { inputs: [], name: "totalAdminFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalBorrows = { inputs: [], name: "totalBorrows", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalBorrowsCurrent = { inputs: [], name: "totalBorrowsCurrent", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalFuseFees = { inputs: [], name: "totalFuseFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalReserves = { inputs: [], name: "totalReserves", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transfer = { inputs: [{ internalType: "address", name: "dst", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transfer", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferFrom = { inputs: [{ internalType: "address", name: "src", type: "address" }, { internalType: "address", name: "dst", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transferFrom", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const underlying = { inputs: [], name: "underlying", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [AccrueInterest, Approval, Failure, Flash, NewAdminFee, NewFuseFee, NewMarketInterestRateModel, NewReserveFactor, Transfer, _getExtensionFunctions, _setAdminFee, _setInterestRateModel, _setNameAndSymbol, _setReserveFactor, accrualBlockNumber, accrueInterest, adminFeeMantissa, allowance, approve, asCTokenExtensionInterface, balanceOf, balanceOfUnderlying, borrowBalanceCurrent, borrowIndex, borrowRatePerBlock, borrowRatePerBlockAfterBorrow, comptroller, decimals, exchangeRateCurrent, feeSeizeShareMantissa, flash, fuseAdmin, fuseFeeMantissa, getAccountSnapshot, getTotalUnderlyingSupplied, implementation, interestRateModel, isCEther, multicall, name, protocolSeizeShareMantissa, reserveFactorMantissa, supplyRatePerBlock, supplyRatePerBlockAfterDeposit, supplyRatePerBlockAfterWithdraw, symbol, totalAdminFees, totalBorrows, totalBorrowsCurrent, totalFuseFees, totalReserves, totalSupply, transfer, transferFrom, underlying];
