/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const BTC_TOKEN = { inputs: [], name: "BTC_TOKEN", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const BeamSwapCall = { inputs: [{ internalType: "address", name: "sender", type: "address" }, { internalType: "uint256", name: "amount0", type: "uint256" }, { internalType: "uint256", name: "amount1", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "BeamSwapCall", outputs: [], stateMutability: "nonpayable", type: "function" };
export const STABLE_TOKEN = { inputs: [], name: "STABLE_TOKEN", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const UNISWAP_V2_ROUTER_02 = { inputs: [], name: "UNISWAP_V2_ROUTER_02", outputs: [{ internalType: "contract IUniswapV2Router02", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const UNISWAP_V2_ROUTER_02_ADDRESS = { inputs: [], name: "UNISWAP_V2_ROUTER_02_ADDRESS", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const W_NATIVE = { inputs: [], name: "W_NATIVE", outputs: [{ internalType: "contract IW_NATIVE", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const W_NATIVE_ADDRESS = { inputs: [], name: "W_NATIVE_ADDRESS", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const _becomeImplementation = { inputs: [{ internalType: "bytes", name: "data", type: "bytes" }], name: "_becomeImplementation", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _whitelistRedemptionStrategies = { inputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "bool[]", name: "whitelisted", type: "bool[]" }], name: "_whitelistRedemptionStrategies", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _whitelistRedemptionStrategy = { inputs: [{ internalType: "contract IRedemptionStrategy", name: "strategy", type: "address" }, { internalType: "bool", name: "whitelisted", type: "bool" }], name: "_whitelistRedemptionStrategy", outputs: [], stateMutability: "nonpayable", type: "function" };
export const convertCustomFunds = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "uint256", name: "inputAmount", type: "uint256" }, { internalType: "contract IFundsConversionStrategy", name: "strategy", type: "address" }, { internalType: "bytes", name: "strategyData", type: "bytes" }], name: "convertCustomFunds", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const flashSwapFee = { inputs: [], name: "flashSwapFee", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "address", name: "_wtoken", type: "address" }, { internalType: "address", name: "_uniswapV2router", type: "address" }, { internalType: "address", name: "_stableToken", type: "address" }, { internalType: "address", name: "_btcToken", type: "address" }, { internalType: "bytes", name: "_uniswapPairInitHashCode", type: "bytes" }, { internalType: "uint8", name: "_flashSwapFee", type: "uint8" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const moraswapCall = { inputs: [{ internalType: "address", name: "sender", type: "address" }, { internalType: "uint256", name: "amount0", type: "uint256" }, { internalType: "uint256", name: "amount1", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "moraswapCall", outputs: [], stateMutability: "nonpayable", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pancakeCall = { inputs: [{ internalType: "address", name: "sender", type: "address" }, { internalType: "uint256", name: "amount0", type: "uint256" }, { internalType: "uint256", name: "amount1", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "pancakeCall", outputs: [], stateMutability: "nonpayable", type: "function" };
export const redeemCustomCollateral = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "underlyingCollateral", type: "address" }, { internalType: "uint256", name: "underlyingCollateralSeized", type: "uint256" }, { internalType: "contract IRedemptionStrategy", name: "strategy", type: "address" }, { internalType: "bytes", name: "strategyData", type: "bytes" }], name: "redeemCustomCollateral", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const redemptionStrategiesWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "redemptionStrategiesWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const safeLiquidate_address_address_address_uint256_address_address_addressArray_bytesArray = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }, { internalType: "contract ICErc20", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "address", name: "", type: "address" }, { internalType: "contract IUniswapV2Router02", name: "", type: "address" }, { internalType: "contract IRedemptionStrategy[]", name: "", type: "address[]" }, { internalType: "bytes[]", name: "", type: "bytes[]" }], name: "safeLiquidate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "payable", type: "function" };
export const safeLiquidate_address_uint256_address_address_uint256_address_address_addressArray_bytesArray = { inputs: [{ internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }, { internalType: "contract ICErc20", name: "cErc20", type: "address" }, { internalType: "contract ICErc20", name: "cTokenCollateral", type: "address" }, { internalType: "uint256", name: "minOutputAmount", type: "uint256" }, { internalType: "address", name: "exchangeSeizedTo", type: "address" }, { internalType: "contract IUniswapV2Router02", name: "uniswapV2Router", type: "address" }, { internalType: "contract IRedemptionStrategy[]", name: "redemptionStrategies", type: "address[]" }, { internalType: "bytes[]", name: "strategyData", type: "bytes[]" }], name: "safeLiquidate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const safeLiquidateToTokensWithFlashLoan = { inputs: [{ components: [{ internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }, { internalType: "contract ICErc20", name: "cErc20", type: "address" }, { internalType: "contract ICErc20", name: "cTokenCollateral", type: "address" }, { internalType: "contract IUniswapV2Pair", name: "flashSwapPair", type: "address" }, { internalType: "uint256", name: "minProfitAmount", type: "uint256" }, { internalType: "address", name: "exchangeProfitTo", type: "address" }, { internalType: "contract IUniswapV2Router02", name: "uniswapV2RouterForBorrow", type: "address" }, { internalType: "contract IUniswapV2Router02", name: "uniswapV2RouterForCollateral", type: "address" }, { internalType: "contract IRedemptionStrategy[]", name: "redemptionStrategies", type: "address[]" }, { internalType: "bytes[]", name: "strategyData", type: "bytes[]" }, { internalType: "uint256", name: "ethToCoinbase", type: "uint256" }, { internalType: "contract IFundsConversionStrategy[]", name: "debtFundingStrategies", type: "address[]" }, { internalType: "bytes[]", name: "debtFundingStrategiesData", type: "bytes[]" }], internalType: "struct FuseSafeLiquidator.LiquidateToTokensWithFlashSwapVars", name: "vars", type: "tuple" }], name: "safeLiquidateToTokensWithFlashLoan", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const stellaswapV2Call = { inputs: [{ internalType: "address", name: "sender", type: "address" }, { internalType: "uint256", name: "amount0", type: "uint256" }, { internalType: "uint256", name: "amount1", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "stellaswapV2Call", outputs: [], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const uniswapV2Call = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "uniswapV2Call", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [Initialized, OwnershipTransferred, BTC_TOKEN, BeamSwapCall, STABLE_TOKEN, UNISWAP_V2_ROUTER_02, UNISWAP_V2_ROUTER_02_ADDRESS, W_NATIVE, W_NATIVE_ADDRESS, _becomeImplementation, _whitelistRedemptionStrategies, _whitelistRedemptionStrategy, convertCustomFunds, flashSwapFee, initialize, moraswapCall, owner, pancakeCall, redeemCustomCollateral, redemptionStrategiesWhitelist, renounceOwnership, safeLiquidate_address_address_address_uint256_address_address_addressArray_bytesArray, safeLiquidate_address_uint256_address_address_uint256_address_address_addressArray_bytesArray, safeLiquidateToTokensWithFlashLoan, stellaswapV2Call, transferOwnership, uniswapV2Call];
