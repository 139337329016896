var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { utils } from "ethers";
import { getChainLiquidationConfig } from "./config";
import liquidateUnhealthyBorrows from "./liquidateUnhealthyBorrows";
import { gatherLiquidations, getAllFusePoolUsers } from "./index";
export function withSafeLiquidator(Base) {
    return class SafeLiquidator extends Base {
        constructor() {
            super(...arguments);
            this.chainLiquidationConfig = getChainLiquidationConfig(this);
        }
        getPotentialLiquidations(excludedComptrollers = [], maxHealthFactor = utils.parseEther("1"), configOverrides) {
            return __awaiter(this, void 0, void 0, function* () {
                // Get potential liquidations from public pools
                const [fusePoolWithUsers, erroredPools] = yield getAllFusePoolUsers(this, maxHealthFactor, excludedComptrollers);
                if (configOverrides)
                    this.chainLiquidationConfig = Object.assign(Object.assign({}, this.chainLiquidationConfig), configOverrides);
                const [liquidatablePools, erroredLiquidations] = yield gatherLiquidations(this, fusePoolWithUsers, this.chainLiquidationConfig);
                // get unique comptrollers
                const errored = [...erroredPools, ...erroredLiquidations].filter((value, idx, array) => array.findIndex((v2) => v2.comptroller === value.comptroller) === idx);
                return [liquidatablePools, errored];
            });
        }
        liquidatePositions(liquidatablePool) {
            return __awaiter(this, void 0, void 0, function* () {
                const [erroredLiquidations, succeededLiquidations] = yield liquidateUnhealthyBorrows(this, liquidatablePool);
                return [erroredLiquidations, succeededLiquidations];
            });
        }
    };
}
