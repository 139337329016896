import { chainIdToConfig } from "@midas-capital/chains";
import { withErc4626StrategyScorer } from "./strategy";
export { STRATEGY_HELP } from "./enums";
export class SecurityBase {
    constructor(chainId, provider) {
        this.chainConfig = chainIdToConfig[chainId];
        this.provider = provider;
    }
}
const SecurityBaseWithModules = withErc4626StrategyScorer(SecurityBase);
export default class Security extends SecurityBaseWithModules {
}
