/**
 * Autogenerated file. Do not edit manually. */
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Approval", type: "event" };
export const Deposit = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Deposit", type: "event" };
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const Paused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Transfer", type: "event" };
export const Unpaused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" };
export const UpdatedFeeSettings = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldPerformanceFee", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newPerformanceFee", type: "uint256" }, { indexed: false, internalType: "address", name: "oldFeeRecipient", type: "address" }, { indexed: false, internalType: "address", name: "newFeeRecipient", type: "address" }], name: "UpdatedFeeSettings", type: "event" };
export const Withdraw = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "receiver", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Withdraw", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const allowance = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }], name: "allowance", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "approve", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const asset = { inputs: [], name: "asset", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const balanceOf = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "balanceOf", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const convertToAssets = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "convertToAssets", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "view", type: "function" };
export const convertToShares = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "convertToShares", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "view", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const decreaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "subtractedValue", type: "uint256" }], name: "decreaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const deposit = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "deposit", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const emergencyWithdrawAndPause = { inputs: [], name: "emergencyWithdrawAndPause", outputs: [], stateMutability: "nonpayable", type: "function" };
export const feeRecipient = { inputs: [], name: "feeRecipient", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const increaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "addedValue", type: "uint256" }], name: "increaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const maxDeposit = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxMint = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxRedeem = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "maxRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxWithdraw = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "maxWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const mint = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "mint", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const paused = { inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const performanceFee = { inputs: [], name: "performanceFee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewDeposit = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewMint = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewRedeem = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewWithdraw = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const redeem = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "redeem", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const shutdown = { inputs: [{ internalType: "address", name: "market", type: "address" }], name: "shutdown", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const takePerformanceFee = { inputs: [], name: "takePerformanceFee", outputs: [], stateMutability: "nonpayable", type: "function" };
export const totalAssets = { inputs: [], name: "totalAssets", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transfer = { inputs: [{ internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transfer", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferFrom = { inputs: [{ internalType: "address", name: "from", type: "address" }, { internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transferFrom", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const unpause = { inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" };
export const updateFeeSettings = { inputs: [{ internalType: "uint256", name: "newPerformanceFee", type: "uint256" }, { internalType: "address", name: "newFeeRecipient", type: "address" }], name: "updateFeeSettings", outputs: [], stateMutability: "nonpayable", type: "function" };
export const vaultShareHWM = { inputs: [], name: "vaultShareHWM", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const withdraw = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "withdraw", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const withdrawAccruedFees = { inputs: [], name: "withdrawAccruedFees", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [Approval, Deposit, Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, Paused, Transfer, Unpaused, UpdatedFeeSettings, Withdraw, _acceptOwner, _setPendingOwner, allowance, approve, asset, balanceOf, convertToAssets, convertToShares, decimals, decreaseAllowance, deposit, emergencyWithdrawAndPause, feeRecipient, increaseAllowance, maxDeposit, maxMint, maxRedeem, maxWithdraw, mint, name, owner, paused, pendingOwner, performanceFee, previewDeposit, previewMint, previewRedeem, previewWithdraw, redeem, renounceOwnership, shutdown, symbol, takePerformanceFee, totalAssets, totalSupply, transfer, transferFrom, transferOwnership, unpause, updateFeeSettings, vaultShareHWM, withdraw, withdrawAccruedFees];
