// src/enums.ts
var SupportedChains = /* @__PURE__ */ ((SupportedChains2) => {
  SupportedChains2[SupportedChains2["ethereum"] = 1] = "ethereum";
  SupportedChains2[SupportedChains2["bsc"] = 56] = "bsc";
  SupportedChains2[SupportedChains2["chapel"] = 97] = "chapel";
  SupportedChains2[SupportedChains2["ganache"] = 1337] = "ganache";
  SupportedChains2[SupportedChains2["evmos"] = 9001] = "evmos";
  SupportedChains2[SupportedChains2["moonbeam"] = 1284] = "moonbeam";
  SupportedChains2[SupportedChains2["neon_devnet"] = 245022926] = "neon_devnet";
  SupportedChains2[SupportedChains2["polygon"] = 137] = "polygon";
  SupportedChains2[SupportedChains2["arbitrum"] = 42161] = "arbitrum";
  SupportedChains2[SupportedChains2["fantom"] = 250] = "fantom";
  SupportedChains2[SupportedChains2["lineagoerli"] = 59140] = "lineagoerli";
  return SupportedChains2;
})(SupportedChains || {});
var SupportedChainsArray = Object.entries(SupportedChains).map(([, value]) => value).filter((value) => typeof value === "number");
var RedemptionStrategyContract = /* @__PURE__ */ ((RedemptionStrategyContract2) => {
  RedemptionStrategyContract2["CurveLpTokenLiquidatorNoRegistry"] = "CurveLpTokenLiquidatorNoRegistry";
  RedemptionStrategyContract2["XBombLiquidatorFunder"] = "XBombLiquidatorFunder";
  RedemptionStrategyContract2["JarvisLiquidatorFunder"] = "JarvisLiquidatorFunder";
  RedemptionStrategyContract2["UniswapLpTokenLiquidator"] = "UniswapLpTokenLiquidator";
  RedemptionStrategyContract2["UniswapV2LiquidatorFunder"] = "UniswapV2LiquidatorFunder";
  RedemptionStrategyContract2["UniswapV3Liquidator"] = "UniswapV3Liquidator";
  RedemptionStrategyContract2["GelatoGUniLiquidator"] = "GelatoGUniLiquidator";
  RedemptionStrategyContract2["GammaLpTokenLiquidator"] = "GammaLpTokenLiquidator";
  RedemptionStrategyContract2["GammaLpTokenWrapper"] = "GammaLpTokenWrapper";
  RedemptionStrategyContract2["CurveSwapLiquidator"] = "CurveSwapLiquidator";
  RedemptionStrategyContract2["CurveSwapLiquidatorFunder"] = "CurveSwapLiquidatorFunder";
  RedemptionStrategyContract2["CurveLpTokenWrapper"] = "CurveLpTokenWrapper";
  RedemptionStrategyContract2["SaddleLpTokenLiquidator"] = "SaddleLpTokenLiquidator";
  RedemptionStrategyContract2["BalancerLpTokenLiquidator"] = "BalancerLpTokenLiquidator";
  RedemptionStrategyContract2["BalancerSwapLiquidator"] = "BalancerSwapLiquidator";
  RedemptionStrategyContract2["ERC4626Liquidator"] = "ERC4626Liquidator";
  RedemptionStrategyContract2["AlgebraSwapLiquidator"] = "AlgebraSwapLiquidator";
  RedemptionStrategyContract2["SolidlyLpTokenLiquidator"] = "SolidlyLpTokenLiquidator";
  RedemptionStrategyContract2["SolidlyLpTokenWrapper"] = "SolidlyLpTokenWrapper";
  RedemptionStrategyContract2["SolidlySwapLiquidator"] = "SolidlySwapLiquidator";
  RedemptionStrategyContract2["AaveTokenLiquidator"] = "AaveTokenLiquidator";
  return RedemptionStrategyContract2;
})(RedemptionStrategyContract || {});
var FundingStrategyContract = /* @__PURE__ */ ((FundingStrategyContract2) => {
  FundingStrategyContract2["JarvisLiquidatorFunder"] = "JarvisLiquidatorFunder";
  FundingStrategyContract2["XBombLiquidatorFunder"] = "XBombLiquidatorFunder";
  FundingStrategyContract2["UniswapV3LiquidatorFunder"] = "UniswapV3LiquidatorFunder";
  FundingStrategyContract2["CurveSwapLiquidatorFunder"] = "CurveSwapLiquidatorFunder";
  return FundingStrategyContract2;
})(FundingStrategyContract || {});
var DelegateContractName = /* @__PURE__ */ ((DelegateContractName2) => {
  DelegateContractName2["CErc20Delegate"] = "CErc20Delegate";
  DelegateContractName2["CErc20PluginDelegate"] = "CErc20PluginDelegate";
  DelegateContractName2["CErc20PluginRewardsDelegate"] = "CErc20PluginRewardsDelegate";
  return DelegateContractName2;
})(DelegateContractName || {});
var OracleTypes = /* @__PURE__ */ ((OracleTypes2) => {
  OracleTypes2["ChainlinkPriceOracleV2"] = "ChainlinkPriceOracleV2";
  OracleTypes2["CurveLpTokenPriceOracleNoRegistry"] = "CurveLpTokenPriceOracleNoRegistry";
  OracleTypes2["CurveV2PriceOracle"] = "CurveV2PriceOracle";
  OracleTypes2["CurveV2LpTokenPriceOracleNoRegistry"] = "CurveV2LpTokenPriceOracleNoRegistry";
  OracleTypes2["DiaPriceOracle"] = "DiaPriceOracle";
  OracleTypes2["FixedNativePriceOracle"] = "FixedNativePriceOracle";
  OracleTypes2["FluxPriceOracle"] = "FluxPriceOracle";
  OracleTypes2["UmbrellaPriceOracle"] = "UmbrellaPriceOracle";
  OracleTypes2["MasterPriceOracle"] = "MasterPriceOracle";
  OracleTypes2["SimplePriceOracle"] = "SimplePriceOracle";
  OracleTypes2["UniswapLpTokenPriceOracle"] = "UniswapLpTokenPriceOracle";
  OracleTypes2["UniswapTwapPriceOracleV2"] = "UniswapTwapPriceOracleV2";
  OracleTypes2["UniswapV3PriceOracle"] = "UniswapV3PriceOracle";
  OracleTypes2["AlgebraPriceOracle"] = "AlgebraPriceOracle";
  OracleTypes2["AnkrCertificateTokenPriceOracle"] = "AnkrCertificateTokenPriceOracle";
  OracleTypes2["GammaPoolPriceOracle"] = "GammaPoolPriceOracle";
  OracleTypes2["GelatoGUniPriceOracle"] = "GelatoGUniPriceOracle";
  OracleTypes2["DiaStDotPriceOracle"] = "DiaStDotPriceOracle";
  OracleTypes2["BalancerLpTokenPriceOracle"] = "BalancerLpTokenPriceOracle";
  OracleTypes2["BalancerLpStablePoolPriceOracle"] = "BalancerLpStablePoolPriceOracle";
  OracleTypes2["BalancerRateProviderOracle"] = "BalancerRateProviderOracle";
  OracleTypes2["BalancerLpLinearPoolPriceOracle"] = "BalancerLpLinearPoolPriceOracle";
  OracleTypes2["BalancerLpTokenPriceOracleNTokens"] = "BalancerLpTokenPriceOracleNTokens";
  OracleTypes2["StkBNBPriceOracle"] = "StkBNBPriceOracle";
  OracleTypes2["WombatLpTokenPriceOracle"] = "WombatLpTokenPriceOracle";
  OracleTypes2["AdrastiaPriceOracle"] = "AdrastiaPriceOracle";
  OracleTypes2["NativeUSDPriceOracle"] = "NativeUSDPriceOracle";
  OracleTypes2["SaddleLpPriceOracle"] = "SaddleLpPriceOracle";
  OracleTypes2["SolidlyLpTokenPriceOracle"] = "SolidlyLpTokenPriceOracle";
  OracleTypes2["WSTEthPriceOracle"] = "WSTEthPriceOracle";
  OracleTypes2["ERC4626Oracle"] = "ERC4626Oracle";
  return OracleTypes2;
})(OracleTypes || {});
var IrmTypes = /* @__PURE__ */ ((IrmTypes2) => {
  IrmTypes2["JumpRateModel"] = "JumpRateModel";
  IrmTypes2["WhitePaperInterestRateModel"] = "WhitePaperInterestRateModel";
  IrmTypes2["AnkrFTMInterestRateModel"] = "AnkrFTMInterestRateModel";
  IrmTypes2["AnkrBNBInterestRateModel"] = "AnkrBNBInterestRateModel";
  IrmTypes2["AdjustableAnkrBNBIrm"] = "AdjustableAnkrBNBIrm";
  IrmTypes2["AdjustableJumpRateModel_PSTAKE_WBNB"] = "AdjustableJumpRateModel_PSTAKE_WBNB";
  IrmTypes2["AdjustableJumpRateModel_MIXBYTES_XCDOT"] = "AdjustableJumpRateModel_MIXBYTES_XCDOT";
  IrmTypes2["AdjustableJumpRateModel_TRANSFERO_BRZ"] = "AdjustableJumpRateModel_TRANSFERO_BRZ";
  IrmTypes2["AdjustableJumpRateModel_TRANSFERO_BTCB_ETH_MAI_WBNB"] = "AdjustableJumpRateModel_TRANSFERO_BTCB_ETH_MAI_WBNB";
  IrmTypes2["AdjustableJumpRateModel_STADER_WBNB"] = "AdjustableJumpRateModel_STADER_WBNB";
  IrmTypes2["AdjustableJumpRateModel_MIXBYTES_USDC"] = "AdjustableJumpRateModel_MIXBYTES_USDC";
  IrmTypes2["AdjustableJumpRateModel_JARVIS_jBRL"] = "AdjustableJumpRateModel_JARVIS_jBRL";
  IrmTypes2["AdjustableJumpRateModel_JARVIS_jEUR"] = "AdjustableJumpRateModel_JARVIS_jEUR";
  return IrmTypes2;
})(IrmTypes || {});
var LiquidationStrategy = /* @__PURE__ */ ((LiquidationStrategy2) => {
  LiquidationStrategy2["DEFAULT"] = "DEFAULT";
  LiquidationStrategy2["UNISWAP"] = "UNISWAP";
  return LiquidationStrategy2;
})(LiquidationStrategy || {});
var ComptrollerErrorCodes = /* @__PURE__ */ ((ComptrollerErrorCodes2) => {
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["NO_ERROR"] = 0] = "NO_ERROR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["UNAUTHORIZED"] = 1] = "UNAUTHORIZED";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["COMPTROLLER_MISMATCH"] = 2] = "COMPTROLLER_MISMATCH";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["INSUFFICIENT_SHORTFALL"] = 3] = "INSUFFICIENT_SHORTFALL";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["INSUFFICIENT_LIQUIDITY"] = 4] = "INSUFFICIENT_LIQUIDITY";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["INVALID_CLOSE_FACTOR"] = 5] = "INVALID_CLOSE_FACTOR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["INVALID_COLLATERAL_FACTOR"] = 6] = "INVALID_COLLATERAL_FACTOR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["INVALID_LIQUIDATION_INCENTIVE"] = 7] = "INVALID_LIQUIDATION_INCENTIVE";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["MARKET_NOT_ENTERED"] = 8] = "MARKET_NOT_ENTERED";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["MARKET_NOT_LISTED"] = 9] = "MARKET_NOT_LISTED";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["MARKET_ALREADY_LISTED"] = 10] = "MARKET_ALREADY_LISTED";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["MATH_ERROR"] = 11] = "MATH_ERROR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["NONZERO_BORROW_BALANCE"] = 12] = "NONZERO_BORROW_BALANCE";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["PRICE_ERROR"] = 13] = "PRICE_ERROR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["REJECTION"] = 14] = "REJECTION";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["SNAPSHOT_ERROR"] = 15] = "SNAPSHOT_ERROR";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["TOO_MANY_ASSETS"] = 16] = "TOO_MANY_ASSETS";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["TOO_MUCH_REPAY"] = 17] = "TOO_MUCH_REPAY";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["SUPPLIER_NOT_WHITELISTED"] = 18] = "SUPPLIER_NOT_WHITELISTED";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["BORROW_BELOW_MIN"] = 19] = "BORROW_BELOW_MIN";
  ComptrollerErrorCodes2[ComptrollerErrorCodes2["SUPPLY_ABOVE_MAX"] = 20] = "SUPPLY_ABOVE_MAX";
  return ComptrollerErrorCodes2;
})(ComptrollerErrorCodes || {});
var CTokenErrorCodes = /* @__PURE__ */ ((CTokenErrorCodes2) => {
  CTokenErrorCodes2[CTokenErrorCodes2["NO_ERROR"] = 0] = "NO_ERROR";
  CTokenErrorCodes2[CTokenErrorCodes2["UNAUTHORIZED"] = 1] = "UNAUTHORIZED";
  CTokenErrorCodes2[CTokenErrorCodes2["BAD_INPUT"] = 2] = "BAD_INPUT";
  CTokenErrorCodes2[CTokenErrorCodes2["COMPTROLLER_REJECTION"] = 3] = "COMPTROLLER_REJECTION";
  CTokenErrorCodes2[CTokenErrorCodes2["COMPTROLLER_CALCULATION_ERROR"] = 4] = "COMPTROLLER_CALCULATION_ERROR";
  CTokenErrorCodes2[CTokenErrorCodes2["INTEREST_RATE_MODEL_ERROR"] = 5] = "INTEREST_RATE_MODEL_ERROR";
  CTokenErrorCodes2[CTokenErrorCodes2["INVALID_ACCOUNT_PAIR"] = 6] = "INVALID_ACCOUNT_PAIR";
  CTokenErrorCodes2[CTokenErrorCodes2["INVALID_CLOSE_AMOUNT_REQUESTED"] = 7] = "INVALID_CLOSE_AMOUNT_REQUESTED";
  CTokenErrorCodes2[CTokenErrorCodes2["INVALID_COLLATERAL_FACTOR"] = 8] = "INVALID_COLLATERAL_FACTOR";
  CTokenErrorCodes2[CTokenErrorCodes2["MATH_ERROR"] = 9] = "MATH_ERROR";
  CTokenErrorCodes2[CTokenErrorCodes2["MARKET_NOT_FRESH"] = 10] = "MARKET_NOT_FRESH";
  CTokenErrorCodes2[CTokenErrorCodes2["MARKET_NOT_LISTED"] = 11] = "MARKET_NOT_LISTED";
  CTokenErrorCodes2[CTokenErrorCodes2["TOKEN_INSUFFICIENT_ALLOWANCE"] = 12] = "TOKEN_INSUFFICIENT_ALLOWANCE";
  CTokenErrorCodes2[CTokenErrorCodes2["TOKEN_INSUFFICIENT_BALANCE"] = 13] = "TOKEN_INSUFFICIENT_BALANCE";
  CTokenErrorCodes2[CTokenErrorCodes2["TOKEN_INSUFFICIENT_CASH"] = 14] = "TOKEN_INSUFFICIENT_CASH";
  CTokenErrorCodes2[CTokenErrorCodes2["TOKEN_TRANSFER_IN_FAILED"] = 15] = "TOKEN_TRANSFER_IN_FAILED";
  CTokenErrorCodes2[CTokenErrorCodes2["TOKEN_TRANSFER_OUT_FAILED"] = 16] = "TOKEN_TRANSFER_OUT_FAILED";
  CTokenErrorCodes2[CTokenErrorCodes2["UTILIZATION_ABOVE_MAX"] = 17] = "UTILIZATION_ABOVE_MAX";
  return CTokenErrorCodes2;
})(CTokenErrorCodes || {});
var FundOperationMode = /* @__PURE__ */ ((FundOperationMode2) => {
  FundOperationMode2[FundOperationMode2["SUPPLY"] = 0] = "SUPPLY";
  FundOperationMode2[FundOperationMode2["WITHDRAW"] = 1] = "WITHDRAW";
  FundOperationMode2[FundOperationMode2["BORROW"] = 2] = "BORROW";
  FundOperationMode2[FundOperationMode2["REPAY"] = 3] = "REPAY";
  return FundOperationMode2;
})(FundOperationMode || {});
var assetOriginalSymbols = /* @__PURE__ */ ((assetOriginalSymbols2) => {
  assetOriginalSymbols2["sAMM-jBRL/BRZ"] = "jBRL/BRZ Thena LP";
  assetOriginalSymbols2["sAMM-HAY/BUSD"] = "HAY/BUSD Thena LP";
  assetOriginalSymbols2["vAMM-ANKR/ankrBNB"] = "ankrBNB/ANKR Thena LP";
  assetOriginalSymbols2["vAMM-ANKR/HAY"] = "HAY/ANKR Thena LP";
  assetOriginalSymbols2["vAMM-HAY/ankrBNB"] = "HAY/ankrBNB Thena LP";
  assetOriginalSymbols2["sAMM-stkBNB/WBNB"] = "stkBNB/WBNB Thena LP";
  assetOriginalSymbols2["WMATIC_MATICX_STABLE_BLP"] = "WMATIC-MATICX Balancer Stable LP";
  assetOriginalSymbols2["sAMM-USDC/USDR"] = "USDC/USDR Pearl Stable LP";
  assetOriginalSymbols2["vAMM-wUSDR/USDR"] = "wUSDR/USDR Pearl Variable LP";
  assetOriginalSymbols2["vAMM-stMATIC/USDR"] = "stMATIC/USDR Pearl Variable LP";
  return assetOriginalSymbols2;
})(assetOriginalSymbols || {});
var assetSymbols = /* @__PURE__ */ ((assetSymbols2) => {
  assetSymbols2["WETH"] = "WETH";
  assetSymbols2["DAI"] = "DAI";
  assetSymbols2["ETH"] = "ETH";
  assetSymbols2["AUTO"] = "AUTO";
  assetSymbols2["BIFI"] = "BIFI";
  assetSymbols2["USDC"] = "USDC";
  assetSymbols2["USDT"] = "USDT";
  assetSymbols2["UST"] = "UST";
  assetSymbols2["TUSD"] = "TUSD";
  assetSymbols2["FRAX"] = "FRAX";
  assetSymbols2["FTM"] = "FTM";
  assetSymbols2["WBTC"] = "WBTC";
  assetSymbols2["BAL"] = "BAL";
  assetSymbols2["BTC"] = "BTC";
  assetSymbols2["LINK"] = "LINK";
  assetSymbols2["AAVE"] = "AAVE";
  assetSymbols2["ALCX"] = "ALCX";
  assetSymbols2["AVAX"] = "AVAX";
  assetSymbols2["AXS"] = "AXS";
  assetSymbols2["CRV"] = "CRV";
  assetSymbols2["CVX"] = "CVX";
  assetSymbols2["FXS"] = "FXS";
  assetSymbols2["GHST"] = "GHST";
  assetSymbols2["GRT"] = "GRT";
  assetSymbols2["MAI"] = "MAI";
  assetSymbols2["MIM"] = "MIM";
  assetSymbols2["MKR"] = "MKR";
  assetSymbols2["RAI"] = "RAI";
  assetSymbols2["SNX"] = "SNX";
  assetSymbols2["SOL"] = "SOL";
  assetSymbols2["SUSHI"] = "SUSHI";
  assetSymbols2["YFI"] = "YFI";
  assetSymbols2["WBNB"] = "WBNB";
  assetSymbols2["BNB"] = "BNB";
  assetSymbols2["BUSD"] = "BUSD";
  assetSymbols2["BTCB"] = "BTCB";
  assetSymbols2["BETH"] = "BETH";
  assetSymbols2["CAKE"] = "CAKE";
  assetSymbols2["ALPACA"] = "ALPACA";
  assetSymbols2["3EPS"] = "3EPS";
  assetSymbols2["mai3EPS"] = "mai3EPS";
  assetSymbols2["val3EPS"] = "val3EPS";
  assetSymbols2["valdai3EPS"] = "valdai3EPS";
  assetSymbols2["2brl"] = "2brl";
  assetSymbols2["3brl"] = "3brl";
  assetSymbols2["jBRL"] = "jBRL";
  assetSymbols2["BRZ"] = "BRZ";
  assetSymbols2["BRZw"] = "BRZw";
  assetSymbols2["BOMB"] = "BOMB";
  assetSymbols2["xBOMB"] = "xBOMB";
  assetSymbols2["ankrBNB"] = "ankrBNB";
  assetSymbols2["stkBNB"] = "stkBNB";
  assetSymbols2["SAFEMOON"] = "SAFEMOON";
  assetSymbols2["HAY"] = "HAY";
  assetSymbols2["WBNB-DAI"] = "WBNB-DAI";
  assetSymbols2["WBNB-BUSD"] = "WBNB-BUSD";
  assetSymbols2["WBNB-USDT"] = "WBNB-USDT";
  assetSymbols2["WBNB-USDC"] = "WBNB-USDC";
  assetSymbols2["WBNB-ETH"] = "WBNB-ETH";
  assetSymbols2["BUSD-USDT"] = "BUSD-USDT";
  assetSymbols2["BUSD-BTCB"] = "BUSD-BTCB";
  assetSymbols2["USDC-BUSD"] = "USDC-BUSD";
  assetSymbols2["USDC-ETH"] = "USDC-ETH";
  assetSymbols2["BTCB-BOMB"] = "BTCB-BOMB";
  assetSymbols2["BTCB-ETH"] = "BTCB-ETH";
  assetSymbols2["CAKE-WBNB"] = "CAKE-WBNB";
  assetSymbols2["ANKR-ankrBNB"] = "ANKR-ankrBNB";
  assetSymbols2["stkBNB-WBNB"] = "stkBNB-WBNB";
  assetSymbols2["BNBx"] = "BNBx";
  assetSymbols2["epsBNBx-BNB"] = "epsBNBx-BNB";
  assetSymbols2["asBNBx-WBNB"] = "ApeSwap BNBx-WBNB LP";
  assetSymbols2["asANKR-ankrBNB"] = "ApeSwap ANKR-ankrBNB LP";
  assetSymbols2["DDD"] = "DDD";
  assetSymbols2["EPX"] = "EPX";
  assetSymbols2["pSTAKE"] = "pSTAKE";
  assetSymbols2["ANKR"] = "ANKR";
  assetSymbols2["SD"] = "SD";
  assetSymbols2["THE"] = "THE";
  assetSymbols2["RDNT"] = "RDNT";
  assetSymbols2["sAMM-jBRL/BRZ"] = "sAMM-jBRL-BRZ";
  assetSymbols2["sAMM-HAY/BUSD"] = "sAMM-HAY-BUSD";
  assetSymbols2["sAMM-stkBNB/WBNB"] = "sAMM-stkBNB-WBNB";
  assetSymbols2["vAMM-ANKR/ankrBNB"] = "vAMM-ANKR-ankrBNB";
  assetSymbols2["vAMM-ANKR/HAY"] = "vAMM-ANKR-HAY";
  assetSymbols2["vAMM-HAY/ankrBNB"] = "vAMM-HAY-ankrBNB";
  assetSymbols2["aWBNB_STKBNB"] = "aWBNB-STKBNB";
  assetSymbols2["aWBNB_BTCB"] = "aWBNB-BTCB";
  assetSymbols2["aWBNB_ETH"] = "aWBNB-ETH";
  assetSymbols2["aANKRBNB_ANKR_W"] = "aANKRBNB-ANKR-W";
  assetSymbols2["aANKRBNB_ANKR_N"] = "aANKRBNB-ANKR-N";
  assetSymbols2["aANKRBNB_RDNT_W"] = "aANKRBNB-RDNT-W";
  assetSymbols2["aANKRBNB_RDNT_N"] = "aANKRBNB-RDNT-N";
  assetSymbols2["aANKRBNB_THE_W"] = "aANKRBNB-THE-W";
  assetSymbols2["aANKRBNB_THE_N"] = "aANKRBNB-THE-N";
  assetSymbols2["WOMBATLP-WBNB"] = "WOMBATLP-WBNB";
  assetSymbols2["kinesisUSDC"] = "kinesisUSDC";
  assetSymbols2["kinesisUSDT"] = "kinesisUSDT";
  assetSymbols2["saddleOptFraxUSD"] = "saddleOptFraxUSD";
  assetSymbols2["saddleOptUSD"] = "saddleOptUSD";
  assetSymbols2["WEVMOS"] = "WEVMOS";
  assetSymbols2["ATOM"] = "ATOM";
  assetSymbols2["OSMO"] = "OSMO";
  assetSymbols2["JUNO"] = "JUNO";
  assetSymbols2["gUSDC"] = "gUSDC";
  assetSymbols2["axlUSDC"] = "axlUSDC";
  assetSymbols2["ceUSDC"] = "ceUSDC";
  assetSymbols2["gUSDT"] = "gUSDT";
  assetSymbols2["axlUSDT"] = "axlUSDT";
  assetSymbols2["ceUSDT"] = "ceUSDT";
  assetSymbols2["gDAI"] = "gDAI";
  assetSymbols2["ceWETH"] = "ceWETH";
  assetSymbols2["axlWETH"] = "axlWETH";
  assetSymbols2["gWBTC"] = "gWBTC";
  assetSymbols2["gWETH"] = "gWETH";
  assetSymbols2["axlWBTC"] = "axlWBTC";
  assetSymbols2["DIFF"] = "DIFF";
  assetSymbols2["GRAV"] = "GRAV";
  assetSymbols2["WEVMOS-JUNO"] = "WEVMOS-JUNO";
  assetSymbols2["WEVMOS-gUSDC"] = "WEVMOS-gUSDC";
  assetSymbols2["WEVMOS-ceUSDC"] = "WEVMOS-ceUSDC";
  assetSymbols2["WEVMOS-gWETH"] = "WEVMOS-gWETH";
  assetSymbols2["ceUSDC-ceUSDT"] = "ceUSDC-ceUSDT";
  assetSymbols2["GLMR"] = "GLMR";
  assetSymbols2["WGLMR"] = "WGLMR";
  assetSymbols2["GLINT"] = "GLINT";
  assetSymbols2["USDC.wh-GLMR"] = "USDC.wh-GLMR";
  assetSymbols2["DOT.xc-GLMR"] = "DOT.xc-GLMR";
  assetSymbols2["WETH.wh-GLMR"] = "WETH.wh-GLMR";
  assetSymbols2["WBTC.wh-GLMR"] = "WBTC.wh-GLMR";
  assetSymbols2["STELLA-GLMR"] = "STELLA-GLMR";
  assetSymbols2["CELR-GLMR"] = "CELR-GLMR";
  assetSymbols2["ATOM-GLMR"] = "ATOM-GLMR";
  assetSymbols2["STELLA"] = "STELLA";
  assetSymbols2["CELR"] = "CELR";
  assetSymbols2["LDO"] = "LDO";
  assetSymbols2["LDO-GLMR"] = "LDO-GLMR";
  assetSymbols2["wstDOT-DOT.xc"] = "wstDOT-DOT.xc";
  assetSymbols2["base4pool"] = "base4pool";
  assetSymbols2["GLMR-USDC"] = "GLMR-USDC";
  assetSymbols2["GLMR-GLINT"] = "GLMR-GLINT";
  assetSymbols2["WGLMR-xcDOT"] = "WGLMR-xcDOT";
  assetSymbols2["DOT"] = "DOT";
  assetSymbols2["xcDOT"] = "xcDOT";
  assetSymbols2["stDOT"] = "stDOT";
  assetSymbols2["wstDOT"] = "wstDOT";
  assetSymbols2["xcKSM"] = "xcKSM";
  assetSymbols2["stKSM"] = "stKSM";
  assetSymbols2["multiWBTC"] = "multiWBTC";
  assetSymbols2["WBTC_wh"] = "WBTC.wh";
  assetSymbols2["WETH_wh"] = "WETH.wh";
  assetSymbols2["multiUSDC"] = "multiUSDC";
  assetSymbols2["USDC_wh"] = "USDC.wh";
  assetSymbols2["multiUSDT"] = "multiUSDT";
  assetSymbols2["multiDAI"] = "multiDAI";
  assetSymbols2["USDT_xc"] = "USDT.xc";
  assetSymbols2["BUSD_wh"] = "BUSD.wh";
  assetSymbols2["3pool"] = "3pool";
  assetSymbols2["xcDOT-stDOT"] = "xcDOT-stDOT";
  assetSymbols2["TOUCH"] = "TOUCH";
  assetSymbols2["TRIBE"] = "TRIBE";
  assetSymbols2["WNEON"] = "WNEON";
  assetSymbols2["MORA"] = "MORA";
  assetSymbols2["WMATIC"] = "WMATIC";
  assetSymbols2["oBNB"] = "oBNB";
  assetSymbols2["WMATIC-USDC"] = "WMATIC-USDC";
  assetSymbols2["WMATIC-ETH"] = "WMATIC-ETH";
  assetSymbols2["WMATIC-USDT"] = "WMATIC-USDT";
  assetSymbols2["WMATIC-MATICx"] = "WMATIC-MATICx";
  assetSymbols2["WETH-WBTC"] = "WETH-WBTC";
  assetSymbols2["AGEUR-JEUR"] = "agEUR-jEUR";
  assetSymbols2["JEUR-PAR"] = "jEUR-PAR";
  assetSymbols2["JEUR-EURT"] = "jEUR-EURT";
  assetSymbols2["JJPY-JPYC"] = "jJPY-JPYC";
  assetSymbols2["JCAD-CADC"] = "jCAD-CADC";
  assetSymbols2["JSGD-XSGD"] = "jSGD-XSGD";
  assetSymbols2["JNZD-NZDS"] = "jNZD-NZDS";
  assetSymbols2["EURE-JEUR"] = "EURE-jEUR";
  assetSymbols2["JCHF-BUSD"] = "jCHF-BUSD";
  assetSymbols2["MAI-USDC"] = "MAI-USDC";
  assetSymbols2["am3CRV"] = "am3CRV";
  assetSymbols2["amUSDC"] = "amUSDC";
  assetSymbols2["AGEUR"] = "agEUR";
  assetSymbols2["EURT"] = "EURT";
  assetSymbols2["EURE"] = "EURE";
  assetSymbols2["CADC"] = "CADC";
  assetSymbols2["JSGD"] = "jSGD";
  assetSymbols2["JJPY"] = "jJPY";
  assetSymbols2["JAUD"] = "jAUD";
  assetSymbols2["JCAD"] = "jCAD";
  assetSymbols2["JNZD"] = "jNZD";
  assetSymbols2["JCHF"] = "jCHF";
  assetSymbols2["JCNY"] = "jCNY";
  assetSymbols2["JEUR"] = "jEUR";
  assetSymbols2["JKRW"] = "jKRW";
  assetSymbols2["JMXN"] = "jMXN";
  assetSymbols2["JGBP"] = "jGBP";
  assetSymbols2["JPLN"] = "jPLN";
  assetSymbols2["JPHP"] = "jPHP";
  assetSymbols2["JPYC"] = "JPYC";
  assetSymbols2["JSEK"] = "jSEK";
  assetSymbols2["PAR"] = "PAR";
  assetSymbols2["NZDS"] = "NZDS";
  assetSymbols2["XSGD"] = "XSGD";
  assetSymbols2["MIMO"] = "MIMO";
  assetSymbols2["JRT"] = "JRT";
  assetSymbols2["aMATICb"] = "aMATICb";
  assetSymbols2["aMATICc"] = "aMATICc";
  assetSymbols2["MATICx"] = "MATICx";
  assetSymbols2["stMATIC"] = "stMATIC";
  assetSymbols2["csMATIC"] = "csMATIC";
  assetSymbols2["IXT"] = "IXT";
  assetSymbols2["GNS"] = "GNS";
  assetSymbols2["DAI-GNS"] = "DAI-GNS";
  assetSymbols2["IXT-USDT"] = "IXT-USDT";
  assetSymbols2["USDR"] = "USDR";
  assetSymbols2["WUSDR"] = "WUSDR";
  assetSymbols2["USDR3CRV"] = "USDR3CRV";
  assetSymbols2["TNGBL"] = "TNGBL";
  assetSymbols2["MIMO_PAR_80_20"] = "MIMO80-PAR20 BLP";
  assetSymbols2["MIMO_PAR_75_25"] = "MIMO75-PAR25 BLP";
  assetSymbols2["BRZ_JBRL_STABLE_BLP"] = "BRZ_JBRL STABLE BLP";
  assetSymbols2["JEUR_PAR_STABLE_BLP"] = "JEUR_PAR STABLE BLP";
  assetSymbols2["WMATIC_STMATIC_STABLE_BLP"] = "WMATIC_STMATIC STABLE BLP";
  assetSymbols2["WMATIC_CSMATIC_STABLE_BLP"] = "WMATIC_CSMATIC STABLE BLP";
  assetSymbols2["WMATIC_MATICX_STABLE_BLP"] = "WMATIC_MATICX STABLE BLP";
  assetSymbols2["TETU_BOOSTED_STABLE_BLP"] = "TETU_BOOSTED STABLE BLP";
  assetSymbols2["TETU_LINEAR_USDT"] = "TETU_LINEAR USDT";
  assetSymbols2["TETU_LINEAR_USDC"] = "TETU_LINEAR USDC";
  assetSymbols2["TETU_LINEAR_DAI"] = "TETU_LINEAR DAI";
  assetSymbols2["AAVE_LINEAR_WMATIC"] = "AAVE_LINEAR WMATIC";
  assetSymbols2["MaticX_bbaWMATIC"] = "MaticX-bba-WMATIC";
  assetSymbols2["StMatic_bbaWMATIC"] = "StMatic-bba-WMATIC";
  assetSymbols2["arrakis_USDC_WETH_005"] = "Arrakis Vault V1 USDC-WETH (0.05)";
  assetSymbols2["arrakis_WBTC_WETH_005"] = "Arrakis Vault V1 WBTC-WETH (0.05)";
  assetSymbols2["arrakis_USDC_PAR_005"] = "G-UNI USDC-PAR Vault (0.05)";
  assetSymbols2["arrakis_WMATIC_USDC_005"] = "Arrakis Vault V1 WMATIC-USDC (0.05)";
  assetSymbols2["arrakis_USDC_agEUR_001"] = "Arrakis Vault V1 USDC-agEUR (0.01)";
  assetSymbols2["arrakis_WMATIC_WETH_005"] = "Arrakis Vault V1 WMATIC-WETH (0.05)";
  assetSymbols2["arrakis_WMATIC_AAVE_03"] = "Arrakis Vault V1 WMATIC-AAVE (0.3)";
  assetSymbols2["arrakis_USDC_MAI_005"] = "Arrakis Vault V1 USDC-miMATIC (0.05)";
  assetSymbols2["arrakis_USDC_USDT_001"] = "Arrakis Vault V1 USDC-USDT (0.01)";
  assetSymbols2["arrakis_USDC_USDT_005"] = "Arrakis Vault V1 USDC-USDT (0.05)";
  assetSymbols2["arrakis_USDC_DAI_005"] = "G-UNI USDC-DAI Vault (0.05)";
  assetSymbols2["arrakis_WETH_DAI_03"] = "G-UNI WETH-DAI Vault (0.3)";
  assetSymbols2["sAMM-USDC/USDR"] = "sAMM-USDC-USDR";
  assetSymbols2["vAMM-wUSDR/USDR"] = "sAMM-wUSDR-USDR";
  assetSymbols2["vAMM-stMATIC/USDR"] = "vAMM-stMATIC-USDR";
  assetSymbols2["2pool"] = "2pool";
  assetSymbols2["GOHM"] = "GOHM";
  assetSymbols2["OHM"] = "OHM";
  assetSymbols2["WETH-GOHM"] = "WETH-GOHM";
  assetSymbols2["DPX"] = "DPX";
  assetSymbols2["MAGIC"] = "MAGIC";
  assetSymbols2["GMX"] = "GMX";
  assetSymbols2["saddleFraxBP"] = "saddleFraxBP";
  assetSymbols2["saddleFraxUsdsBP"] = "saddleFraxUsdsBP";
  assetSymbols2["saddleFraxUsdtBP"] = "saddleFraxUsdtBP";
  assetSymbols2["USDs"] = "USDs";
  assetSymbols2["WFTM"] = "WFTM";
  assetSymbols2["multiBTC"] = "multiBTC";
  assetSymbols2["multiETH"] = "multiETH";
  assetSymbols2["aFTMc"] = "aFTMc";
  assetSymbols2["PAR_USDC_CURVE"] = "PAR_USDC CURVE";
  assetSymbols2["fUSDT"] = "fUSDT";
  assetSymbols2["triCrypto"] = "triCrypto";
  assetSymbols2["eUSD"] = "eUSD";
  assetSymbols2["realYieldUSD"] = "realYieldUSD";
  assetSymbols2["realYieldETH"] = "realYieldETH";
  assetSymbols2["ethBtcTrend"] = "ethBtcTrend";
  assetSymbols2["ethBtcMomentum"] = "ethBtcMomentum";
  assetSymbols2["wstETH"] = "wstETH";
  assetSymbols2["stETH"] = "stETH";
  assetSymbols2["swETH"] = "swETH";
  assetSymbols2["rETH"] = "rETH";
  assetSymbols2["cbETH"] = "cbETH";
  assetSymbols2["ankrETH"] = "ankrETH";
  assetSymbols2["frxETH"] = "frxETH";
  assetSymbols2["SWETH_BBA_WETH_BPT"] = "swETH-bba-WETH-BPT";
  assetSymbols2["WSTETH_WETH_STABLE_BPT"] = "wstETH-WETH-Stable-BPT";
  assetSymbols2["WSTETH_RETH_FRXETH_STABLE_BPT"] = "wstETH-rETH-frxETH-Stable-BPT";
  assetSymbols2["WBETH_WSTETH_STABLE_BPT"] = "WBETH-wstETH-Stable-BPT";
  assetSymbols2["WSTETH_CBETH_STABLE_BPT"] = "wstETH-cbETH-Stable-BPT";
  assetSymbols2["OHM50_DAI50_BPT"] = "OHM50-DAI50 BPT";
  assetSymbols2["OHM50_WETH50_BPT"] = "OHM50-WETH50 BPT";
  assetSymbols2["AAVE_BOOSTED_STABLE_BPT"] = "AAVE-bba-stable-BPT";
  assetSymbols2["AAVE_LINEAR_DAI"] = "AAVE-LINEAR-DAI";
  assetSymbols2["AAVE_LINEAR_USDC"] = "AAVE-LINEAR-USDC";
  assetSymbols2["AAVE_LINEAR_USDT"] = "AAVE-LINEAR-USDT";
  assetSymbols2["AAVE_LINEAR_WETH"] = "AAVE-LINEAR-WETH";
  assetSymbols2["TDAI"] = "TDAI";
  return assetSymbols2;
})(assetSymbols || {});

// src/plugin.ts
var Strategy = /* @__PURE__ */ ((Strategy2) => {
  Strategy2["Beefy"] = "BeefyERC4626";
  Strategy2["Arrakis"] = "ArrakisERC4626";
  Strategy2["Mimo"] = "MimoERC4626";
  Strategy2["DotDot"] = "DotDotLpERC4626";
  Strategy2["Stella"] = "StellaLpERC4626";
  Strategy2["Bomb"] = "BombERC4626";
  Strategy2["CurveGauge"] = "CurveGaugeERC4626";
  Strategy2["MiniChefV2"] = "MiniChefV2";
  Strategy2["HelioHAY"] = "HelioHAY";
  Strategy2["ThenaERC4626"] = "ThenaERC4626";
  Strategy2["Dyson4626"] = "Dyson4626";
  return Strategy2;
})(Strategy || {});
var StrategyComplexity = /* @__PURE__ */ ((StrategyComplexity2) => {
  StrategyComplexity2["LOW"] = "LOW";
  StrategyComplexity2["MEDIUM"] = "MEDIUM";
  StrategyComplexity2["HIGH"] = "HIGH";
  return StrategyComplexity2;
})(StrategyComplexity || {});
var StrategyTimeInMarket = /* @__PURE__ */ ((StrategyTimeInMarket2) => {
  StrategyTimeInMarket2["NEW"] = "NEW";
  StrategyTimeInMarket2["EXPERIMENTAL"] = "EXPERIMENTAL";
  StrategyTimeInMarket2["BATTLE_TESTED"] = "BATTLE_TESTED";
  return StrategyTimeInMarket2;
})(StrategyTimeInMarket || {});
var AssetRiskIL = /* @__PURE__ */ ((AssetRiskIL2) => {
  AssetRiskIL2["NONE"] = "NONE";
  AssetRiskIL2["LOW"] = "LOW";
  AssetRiskIL2["HIGH"] = "HIGH";
  return AssetRiskIL2;
})(AssetRiskIL || {});
var AssetRiskLiquidity = /* @__PURE__ */ ((AssetRiskLiquidity2) => {
  AssetRiskLiquidity2["LOW"] = "LOW";
  AssetRiskLiquidity2["HIGH"] = "HIGH";
  return AssetRiskLiquidity2;
})(AssetRiskLiquidity || {});
var AssetRiskMktCap = /* @__PURE__ */ ((AssetRiskMktCap2) => {
  AssetRiskMktCap2["LARGE"] = "LARGE";
  AssetRiskMktCap2["MEDIUM"] = "MEDIUM";
  AssetRiskMktCap2["SMALL"] = "SMALL";
  AssetRiskMktCap2["MICRO"] = "MICRO";
  return AssetRiskMktCap2;
})(AssetRiskMktCap || {});
var AssetRiskSupply = /* @__PURE__ */ ((AssetRiskSupply2) => {
  AssetRiskSupply2["CENTRALIZED"] = "CENTRALIZED";
  AssetRiskSupply2["DECENTRALIZED"] = "DECENTRALIZED";
  return AssetRiskSupply2;
})(AssetRiskSupply || {});
var PlatformRiskReputation = /* @__PURE__ */ ((PlatformRiskReputation2) => {
  PlatformRiskReputation2["ESTABLISHED"] = "ESTABLISHED";
  PlatformRiskReputation2["NEW"] = "NEW";
  return PlatformRiskReputation2;
})(PlatformRiskReputation || {});
var PlatformRiskAudit = /* @__PURE__ */ ((PlatformRiskAudit2) => {
  PlatformRiskAudit2["AUDIT"] = "AUDIT";
  PlatformRiskAudit2["NO_AUDIT"] = "NO_AUDIT";
  return PlatformRiskAudit2;
})(PlatformRiskAudit || {});
var PlatformRiskContractsVerified = /* @__PURE__ */ ((PlatformRiskContractsVerified2) => {
  PlatformRiskContractsVerified2["CONTRACTS_VERIFIED"] = "CONTRACTS_VERIFIED";
  PlatformRiskContractsVerified2["CONTRACTS_UNVERIFIED"] = "CONTRACTS_UNVERIFIED";
  return PlatformRiskContractsVerified2;
})(PlatformRiskContractsVerified || {});
var PlatformRiskAdminWithTimelock = /* @__PURE__ */ ((PlatformRiskAdminWithTimelock2) => {
  PlatformRiskAdminWithTimelock2["ADMIN_WITH_TIMELOCK"] = "ADMIN_WITH_TIMELOCK";
  PlatformRiskAdminWithTimelock2["ADMIN_WITHOUT_TIMELOCK"] = "ADMIN_WITHOUT_TIMELOCK";
  return PlatformRiskAdminWithTimelock2;
})(PlatformRiskAdminWithTimelock || {});

// src/rewards.ts
function isPluginReward(reward) {
  return reward.plugin !== void 0;
}
function isAssetReward(reward) {
  return reward.asset !== void 0;
}
function isFlywheelReward(reward) {
  return reward.flywheel !== void 0 && reward.token === void 0 && reward.plugin === void 0;
}
function isPluginWithFlywheelReward(reward) {
  return reward.flywheel !== void 0 && reward.token !== void 0 && reward.plugin !== void 0;
}

// src/utils.ts
var underlying = function(assets, symbol) {
  return assetFilter(assets, symbol).underlying;
};
var assetArrayToMap = (assets) => assets.reduce((acc, curr) => {
  acc[curr.underlying] = curr;
  return acc;
}, {});
var assetFilter = function(assets, symbol) {
  const asset = assets.find((a) => a.symbol === symbol);
  if (!asset)
    throw new Error(`no such SupportedAsset with symbol ${symbol} in assets ${JSON.stringify(assets)}`);
  return asset;
};
export {
  AssetRiskIL,
  AssetRiskLiquidity,
  AssetRiskMktCap,
  AssetRiskSupply,
  CTokenErrorCodes,
  ComptrollerErrorCodes,
  DelegateContractName,
  FundOperationMode,
  FundingStrategyContract,
  IrmTypes,
  LiquidationStrategy,
  OracleTypes,
  PlatformRiskAdminWithTimelock,
  PlatformRiskAudit,
  PlatformRiskContractsVerified,
  PlatformRiskReputation,
  RedemptionStrategyContract,
  Strategy,
  StrategyComplexity,
  StrategyTimeInMarket,
  SupportedChains,
  SupportedChainsArray,
  assetArrayToMap,
  assetFilter,
  assetOriginalSymbols,
  assetSymbols,
  isAssetReward,
  isFlywheelReward,
  isPluginReward,
  isPluginWithFlywheelReward,
  underlying
};
