/**
 * Autogenerated file. Do not edit manually. */
export const AssetInvalid = { inputs: [], name: "AssetInvalid", type: "error" };
export const InvalidConfig = { inputs: [], name: "InvalidConfig", type: "error" };
export const InvalidFeeRecipient = { inputs: [], name: "InvalidFeeRecipient", type: "error" };
export const InvalidVaultFees = { inputs: [], name: "InvalidVaultFees", type: "error" };
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Approval", type: "event" };
export const Deposit = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Deposit", type: "event" };
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewAdaptersProposed = { anonymous: false, inputs: [{ components: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], indexed: false, internalType: "struct AdapterConfig[10]", name: "newAdapter", type: "tuple[10]" }, { indexed: false, internalType: "uint8", name: "adaptersCount", type: "uint8" }, { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" }], name: "NewAdaptersProposed", type: "event" };
export const OwnershipTransferStarted = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferStarted", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const Paused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Transfer", type: "event" };
export const Unpaused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" };
export const Withdraw = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "receiver", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Withdraw", type: "event" };
export const DECIMAL_OFFSET = { inputs: [], name: "DECIMAL_OFFSET", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const _getExtensionFunctions = { inputs: [], name: "_getExtensionFunctions", outputs: [{ internalType: "bytes4[]", name: "", type: "bytes4[]" }], stateMutability: "pure", type: "function" };
export const acceptOwnership = { inputs: [], name: "acceptOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const adapters = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "adapters", outputs: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], stateMutability: "view", type: "function" };
export const adaptersCount = { inputs: [], name: "adaptersCount", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const addRewardToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "token_", type: "address" }], name: "addRewardToken", outputs: [], stateMutability: "nonpayable", type: "function" };
export const allowance = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }], name: "allowance", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "approve", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const asset = { inputs: [], name: "asset", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const assetsCheckpoint = { inputs: [], name: "assetsCheckpoint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const balanceOf = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "balanceOf", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const claimRewards = { inputs: [], name: "claimRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const claimRewardsForUser = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "claimRewardsForUser", outputs: [], stateMutability: "nonpayable", type: "function" };
export const convertToAssets = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "convertToAssets", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "view", type: "function" };
export const convertToShares = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "convertToShares", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "view", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const decreaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "subtractedValue", type: "uint256" }], name: "decreaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const deposit = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "deposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const depositLimit = { inputs: [], name: "depositLimit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const emergencyExit = { inputs: [], name: "emergencyExit", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const feeRecipient = { inputs: [], name: "feeRecipient", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fees = { inputs: [], name: "fees", outputs: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], stateMutability: "view", type: "function" };
export const feesUpdatedAt = { inputs: [], name: "feesUpdatedAt", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const flywheelForRewardToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], name: "flywheelForRewardToken", outputs: [{ internalType: "contract MidasFlywheel", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const flywheelLogic = { inputs: [], name: "flywheelLogic", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getAllFlywheels = { inputs: [], name: "getAllFlywheels", outputs: [{ internalType: "contract MidasFlywheel[]", name: "allFlywheels", type: "address[]" }], stateMutability: "view", type: "function" };
export const highWaterMark = { inputs: [], name: "highWaterMark", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const increaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "addedValue", type: "uint256" }], name: "increaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const initialize = { inputs: [{ internalType: "bytes", name: "data", type: "bytes" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const maxDeposit = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxMint = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxRedeem = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "maxRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxWithdraw = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "maxWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const mint = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "mint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const nonces = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "nonces", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const paused = { inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const previewDeposit = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewMint = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewRedeem = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewWithdraw = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposeAdapters = { inputs: [{ components: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], internalType: "struct AdapterConfig[10]", name: "newAdapters", type: "tuple[10]" }, { internalType: "uint8", name: "newAdaptersCount", type: "uint8" }], name: "proposeAdapters", outputs: [], stateMutability: "nonpayable", type: "function" };
export const proposedAdapterTime = { inputs: [], name: "proposedAdapterTime", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposedAdapters = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "proposedAdapters", outputs: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], stateMutability: "view", type: "function" };
export const proposedAdaptersCount = { inputs: [], name: "proposedAdaptersCount", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const proposedFeeTime = { inputs: [], name: "proposedFeeTime", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposedFees = { inputs: [], name: "proposedFees", outputs: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], stateMutability: "view", type: "function" };
export const quitPeriod = { inputs: [], name: "quitPeriod", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const redeem = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "redeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const registry = { inputs: [], name: "registry", outputs: [{ internalType: "contract OptimizedVaultsRegistry", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const rewardTokens = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "rewardTokens", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const totalAssets = { inputs: [], name: "totalAssets", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transfer = { inputs: [{ internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transfer", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferFrom = { inputs: [{ internalType: "address", name: "from", type: "address" }, { internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transferFrom", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const withdraw = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "withdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const withdrawalThreshold = { inputs: [], name: "withdrawalThreshold", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export default [AssetInvalid, InvalidConfig, InvalidFeeRecipient, InvalidVaultFees, Approval, Deposit, Initialized, NewAdaptersProposed, OwnershipTransferStarted, OwnershipTransferred, Paused, Transfer, Unpaused, Withdraw, DECIMAL_OFFSET, _getExtensionFunctions, acceptOwnership, adapters, adaptersCount, addRewardToken, allowance, approve, asset, assetsCheckpoint, balanceOf, claimRewards, claimRewardsForUser, convertToAssets, convertToShares, decimals, decreaseAllowance, deposit, depositLimit, emergencyExit, feeRecipient, fees, feesUpdatedAt, flywheelForRewardToken, flywheelLogic, getAllFlywheels, highWaterMark, increaseAllowance, initialize, maxDeposit, maxMint, maxRedeem, maxWithdraw, mint, name, nonces, owner, paused, pendingOwner, previewDeposit, previewMint, previewRedeem, previewWithdraw, proposeAdapters, proposedAdapterTime, proposedAdapters, proposedAdaptersCount, proposedFeeTime, proposedFees, quitPeriod, redeem, registry, renounceOwnership, rewardTokens, symbol, totalAssets, totalSupply, transfer, transferFrom, transferOwnership, withdraw, withdrawalThreshold];
