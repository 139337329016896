var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber, utils } from "ethers";
import CTokenFirstExtensionABI from "../../../abis/CTokenFirstExtension";
import AdjustableAnkrBNBIrmArtifact from "../../../artifacts/AdjustableAnkrBNBIrm.json";
import { getContract } from "../utils";
import JumpRateModel from "./JumpRateModel";
class AdjustableAnkrBNBIrm extends JumpRateModel {
    init(interestRateModelAddress, assetAddress, provider) {
        return __awaiter(this, void 0, void 0, function* () {
            const interestRateModelContract = getContract(interestRateModelAddress, AdjustableAnkrBNBIrmArtifact.abi, provider);
            this.baseRatePerBlock = BigNumber.from(yield interestRateModelContract.callStatic.getBaseRatePerBlock());
            this.multiplierPerBlock = BigNumber.from(yield interestRateModelContract.callStatic.getMultiplierPerBlock());
            this.jumpMultiplierPerBlock = BigNumber.from(yield interestRateModelContract.callStatic.jumpMultiplierPerBlock());
            this.kink = BigNumber.from(yield interestRateModelContract.callStatic.kink());
            const cTokenContract = getContract(assetAddress, CTokenFirstExtensionABI, provider);
            this.reserveFactorMantissa = BigNumber.from(yield cTokenContract.callStatic.reserveFactorMantissa());
            this.reserveFactorMantissa = this.reserveFactorMantissa.add(BigNumber.from(yield cTokenContract.callStatic.adminFeeMantissa()));
            this.reserveFactorMantissa = this.reserveFactorMantissa.add(BigNumber.from(yield cTokenContract.callStatic.fuseFeeMantissa()));
            this.initialized = true;
        });
    }
    _init(interestRateModelAddress, reserveFactorMantissa, adminFeeMantissa, fuseFeeMantissa, provider) {
        return __awaiter(this, void 0, void 0, function* () {
            const interestRateModelContract = getContract(interestRateModelAddress, AdjustableAnkrBNBIrmArtifact.abi, provider);
            this.baseRatePerBlock = BigNumber.from(yield interestRateModelContract.callStatic.getBaseRatePerBlock());
            this.multiplierPerBlock = BigNumber.from(yield interestRateModelContract.callStatic.getMultiplierPerBlock());
            this.jumpMultiplierPerBlock = BigNumber.from(yield interestRateModelContract.callStatic.jumpMultiplierPerBlock());
            this.kink = BigNumber.from(yield interestRateModelContract.callStatic.kink());
            this.reserveFactorMantissa = BigNumber.from(reserveFactorMantissa);
            this.reserveFactorMantissa = this.reserveFactorMantissa.add(BigNumber.from(adminFeeMantissa));
            this.reserveFactorMantissa = this.reserveFactorMantissa.add(BigNumber.from(fuseFeeMantissa));
            this.initialized = true;
        });
    }
}
AdjustableAnkrBNBIrm.RUNTIME_BYTECODE_HASH = utils.keccak256(AdjustableAnkrBNBIrmArtifact.deployedBytecode.object);
export default AdjustableAnkrBNBIrm;
