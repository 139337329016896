var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Wallet } from "ethers";
import { fetchGasLimitForTransaction } from "./utils";
export default function sendTransactionToSafeLiquidator(sdk, method, params, value) {
    return __awaiter(this, void 0, void 0, function* () {
        // Build data
        const data = sdk.contracts.FuseSafeLiquidator.interface.encodeFunctionData(method, params);
        const txCount = yield sdk.provider.getTransactionCount(process.env.ETHEREUM_ADMIN_ACCOUNT);
        const signer = new Wallet(process.env.ETHEREUM_ADMIN_PRIVATE_KEY, sdk.provider);
        // Build transaction
        const tx = {
            from: process.env.ETHEREUM_ADMIN_ACCOUNT,
            to: sdk.contracts.FuseSafeLiquidator.address,
            value: value,
            data: data,
            nonce: txCount,
        };
        // Estimate gas for transaction
        const gasLimit = yield fetchGasLimitForTransaction(sdk, method, tx);
        const txRequest = Object.assign(Object.assign({}, tx), { gasLimit: gasLimit });
        sdk.logger.info("Signing and sending", method, "transaction:", tx);
        let sentTx;
        // Sign transaction
        // Send transaction
        try {
            sentTx = yield signer.sendTransaction(txRequest);
            const receipt = yield sentTx.wait();
            if (receipt.status === 0) {
                throw `Error sending ${method} transaction: Transaction reverted with status 0`;
            }
            sdk.logger.info("Successfully sent", method, "transaction hash:", sentTx.hash);
            return sentTx;
        }
        catch (error) {
            throw `Error sending ${method}, transaction: ${error}`;
        }
    });
}
