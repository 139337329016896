var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LiquidationStrategy } from "@midas-capital/types";
import { BigNumber } from "ethers";
import { logLiquidation } from "./utils";
export default function encodeLiquidateTx(sdk, liquidationStrategy, borrower, exchangeToTokenAddress, strategiesAndDatas, liquidationAmount, flashSwapPair, debtFundingStrategies, debtFundingStrategiesData) {
    return __awaiter(this, void 0, void 0, function* () {
        logLiquidation(sdk, borrower, exchangeToTokenAddress, liquidationAmount, borrower.debt[0].underlyingSymbol, liquidationStrategy, debtFundingStrategies);
        switch (liquidationStrategy) {
            case LiquidationStrategy.DEFAULT:
                return {
                    method: "safeLiquidate(address,uint256,address,address,uint256,address,address,address[],bytes[])",
                    args: [
                        borrower.account,
                        liquidationAmount,
                        borrower.debt[0].cToken,
                        borrower.collateral[0].cToken,
                        0,
                        exchangeToTokenAddress,
                        sdk.chainSpecificAddresses.UNISWAP_V2_ROUTER,
                        strategiesAndDatas.strategies,
                        strategiesAndDatas.datas,
                    ],
                    value: BigNumber.from(0),
                };
            case LiquidationStrategy.UNISWAP:
                return {
                    method: "safeLiquidateToTokensWithFlashLoan",
                    args: [
                        {
                            borrower: borrower.account,
                            repayAmount: liquidationAmount,
                            cErc20: borrower.debt[0].cToken,
                            cTokenCollateral: borrower.collateral[0].cToken,
                            minProfitAmount: 0,
                            flashSwapPair,
                            exchangeProfitTo: exchangeToTokenAddress,
                            uniswapV2RouterForBorrow: sdk.chainSpecificAddresses.UNISWAP_V2_ROUTER,
                            uniswapV2RouterForCollateral: sdk.chainSpecificAddresses.UNISWAP_V2_ROUTER,
                            redemptionStrategies: strategiesAndDatas.strategies,
                            strategyData: strategiesAndDatas.datas,
                            ethToCoinbase: 0,
                            debtFundingStrategies,
                            debtFundingStrategiesData,
                        },
                    ],
                    value: BigNumber.from(0),
                };
        }
    });
}
