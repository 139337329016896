/**
 * Autogenerated file. Do not edit manually. */
export const IncorrectDistribution = { inputs: [], name: "IncorrectDistribution", type: "error" };
export const IncorrectListLength = { inputs: [], name: "IncorrectListLength", type: "error" };
export const InsufficientWithdrawalAmount = { inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }], name: "InsufficientWithdrawalAmount", type: "error" };
export const InvalidAllocations = { inputs: [], name: "InvalidAllocations", type: "error" };
export const InvalidFeeRecipient = { inputs: [], name: "InvalidFeeRecipient", type: "error" };
export const InvalidQuitPeriod = { inputs: [], name: "InvalidQuitPeriod", type: "error" };
export const InvalidReceiver = { inputs: [], name: "InvalidReceiver", type: "error" };
export const InvalidSigner = { inputs: [{ internalType: "address", name: "signer", type: "address" }], name: "InvalidSigner", type: "error" };
export const InvalidVaultFees = { inputs: [], name: "InvalidVaultFees", type: "error" };
export const MaxError = { inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }], name: "MaxError", type: "error" };
export const NotPassedQuitPeriod = { inputs: [], name: "NotPassedQuitPeriod", type: "error" };
export const PermitDeadlineExpired = { inputs: [{ internalType: "uint256", name: "deadline", type: "uint256" }], name: "PermitDeadlineExpired", type: "error" };
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Approval", type: "event" };
export const ChangedAdapters = { anonymous: false, inputs: [{ components: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], indexed: false, internalType: "struct AdapterConfig[10]", name: "oldAdapter", type: "tuple[10]" }, { indexed: false, internalType: "uint8", name: "oldAdaptersCount", type: "uint8" }, { components: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], indexed: false, internalType: "struct AdapterConfig[10]", name: "newAdapter", type: "tuple[10]" }, { indexed: false, internalType: "uint8", name: "newAdaptersCount", type: "uint8" }], name: "ChangedAdapters", type: "event" };
export const ChangedFees = { anonymous: false, inputs: [{ components: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], indexed: false, internalType: "struct VaultFees", name: "oldFees", type: "tuple" }, { components: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], indexed: false, internalType: "struct VaultFees", name: "newFees", type: "tuple" }], name: "ChangedFees", type: "event" };
export const Deposit = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Deposit", type: "event" };
export const DepositLimitSet = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "depositLimit", type: "uint256" }], name: "DepositLimitSet", type: "event" };
export const EmergencyExitActivated = { anonymous: false, inputs: [], name: "EmergencyExitActivated", type: "event" };
export const FeeRecipientUpdated = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldFeeRecipient", type: "address" }, { indexed: false, internalType: "address", name: "newFeeRecipient", type: "address" }], name: "FeeRecipientUpdated", type: "event" };
export const Harvested = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "totalAssets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "aprBefore", type: "uint256" }, { indexed: false, internalType: "uint256", name: "aprAfter", type: "uint256" }], name: "Harvested", type: "event" };
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewFeesProposed = { anonymous: false, inputs: [{ components: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], indexed: false, internalType: "struct VaultFees", name: "newFees", type: "tuple" }, { indexed: false, internalType: "uint256", name: "timestamp", type: "uint256" }], name: "NewFeesProposed", type: "event" };
export const OwnershipTransferStarted = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferStarted", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const Paused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Paused", type: "event" };
export const QuitPeriodSet = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "quitPeriod", type: "uint256" }], name: "QuitPeriodSet", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "value", type: "uint256" }], name: "Transfer", type: "event" };
export const Unpaused = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }], name: "Unpaused", type: "event" };
export const Withdraw = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "sender", type: "address" }, { indexed: true, internalType: "address", name: "receiver", type: "address" }, { indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: false, internalType: "uint256", name: "assets", type: "uint256" }, { indexed: false, internalType: "uint256", name: "shares", type: "uint256" }], name: "Withdraw", type: "event" };
export const DECIMAL_OFFSET = { inputs: [], name: "DECIMAL_OFFSET", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const DOMAIN_SEPARATOR = { inputs: [], name: "DOMAIN_SEPARATOR", outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }], stateMutability: "view", type: "function" };
export const _getExtensionFunctions = { inputs: [], name: "_getExtensionFunctions", outputs: [{ internalType: "bytes4[]", name: "", type: "bytes4[]" }], stateMutability: "pure", type: "function" };
export const acceptOwnership = { inputs: [], name: "acceptOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const accruedManagementFee = { inputs: [], name: "accruedManagementFee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const accruedPerformanceFee = { inputs: [], name: "accruedPerformanceFee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const adapters = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "adapters", outputs: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], stateMutability: "view", type: "function" };
export const adaptersCount = { inputs: [], name: "adaptersCount", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const allowance = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }], name: "allowance", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "approve", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const asset = { inputs: [], name: "asset", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const assetsCheckpoint = { inputs: [], name: "assetsCheckpoint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const balanceOf = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "balanceOf", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const changeAdapters = { inputs: [], name: "changeAdapters", outputs: [], stateMutability: "nonpayable", type: "function" };
export const changeFees = { inputs: [], name: "changeFees", outputs: [], stateMutability: "nonpayable", type: "function" };
export const convertToAssets = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "convertToAssets", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "view", type: "function" };
export const convertToShares = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "convertToShares", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "view", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const decreaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "subtractedValue", type: "uint256" }], name: "decreaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const deposit_uint256_address = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "deposit", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const deposit_uint256 = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "deposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const depositLimit = { inputs: [], name: "depositLimit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const emergencyExit = { inputs: [], name: "emergencyExit", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const estimatedAPR_ = { inputs: [], name: "estimatedAPR", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const estimatedAPR_uint64Array = { inputs: [{ internalType: "uint64[]", name: "allocations", type: "uint64[]" }], name: "estimatedAPR", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "int256[]", name: "", type: "int256[]" }], stateMutability: "view", type: "function" };
export const estimatedTotalAssets = { inputs: [], name: "estimatedTotalAssets", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const feeRecipient = { inputs: [], name: "feeRecipient", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fees = { inputs: [], name: "fees", outputs: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], stateMutability: "view", type: "function" };
export const feesUpdatedAt = { inputs: [], name: "feesUpdatedAt", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const flywheelForRewardToken = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], name: "flywheelForRewardToken", outputs: [{ internalType: "contract MidasFlywheel", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const flywheelLogic = { inputs: [], name: "flywheelLogic", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const harvest = { inputs: [{ internalType: "uint64[]", name: "adapterAllocationsHint", type: "uint64[]" }], name: "harvest", outputs: [], stateMutability: "nonpayable", type: "function" };
export const highWaterMark = { inputs: [], name: "highWaterMark", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const increaseAllowance = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "addedValue", type: "uint256" }], name: "increaseAllowance", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const lentTotalAssets = { inputs: [], name: "lentTotalAssets", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxDeposit = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxMint = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "maxMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxRedeem = { inputs: [{ internalType: "address", name: "caller", type: "address" }], name: "maxRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const maxWithdraw = { inputs: [{ internalType: "address", name: "caller", type: "address" }], name: "maxWithdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const mint_uint256_address = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }], name: "mint", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const mint_uint256 = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "mint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const nonces = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "nonces", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pause = { inputs: [], name: "pause", outputs: [], stateMutability: "nonpayable", type: "function" };
export const paused = { inputs: [], name: "paused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const permit = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "value", type: "uint256" }, { internalType: "uint256", name: "deadline", type: "uint256" }, { internalType: "uint8", name: "v", type: "uint8" }, { internalType: "bytes32", name: "r", type: "bytes32" }, { internalType: "bytes32", name: "s", type: "bytes32" }], name: "permit", outputs: [], stateMutability: "nonpayable", type: "function" };
export const previewDeposit = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewDeposit", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewMint = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewMint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewRedeem = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "previewRedeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const previewWithdraw = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "previewWithdraw", outputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposeFees = { inputs: [{ components: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], internalType: "struct VaultFees", name: "newFees", type: "tuple" }], name: "proposeFees", outputs: [], stateMutability: "nonpayable", type: "function" };
export const proposedAdapterTime = { inputs: [], name: "proposedAdapterTime", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposedAdapters = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "proposedAdapters", outputs: [{ internalType: "contract CompoundMarketERC4626", name: "adapter", type: "address" }, { internalType: "uint64", name: "allocation", type: "uint64" }], stateMutability: "view", type: "function" };
export const proposedAdaptersCount = { inputs: [], name: "proposedAdaptersCount", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const proposedFeeTime = { inputs: [], name: "proposedFeeTime", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const proposedFees = { inputs: [], name: "proposedFees", outputs: [{ internalType: "uint64", name: "deposit", type: "uint64" }, { internalType: "uint64", name: "withdrawal", type: "uint64" }, { internalType: "uint64", name: "management", type: "uint64" }, { internalType: "uint64", name: "performance", type: "uint64" }], stateMutability: "view", type: "function" };
export const pullAccruedVaultRewards = { inputs: [], name: "pullAccruedVaultRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const quitPeriod = { inputs: [], name: "quitPeriod", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const redeem_uint256_address_address = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "redeem", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const redeem_uint256 = { inputs: [{ internalType: "uint256", name: "shares", type: "uint256" }], name: "redeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const registry = { inputs: [], name: "registry", outputs: [{ internalType: "contract OptimizedVaultsRegistry", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const rewardTokens = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "rewardTokens", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const setDepositLimit = { inputs: [{ internalType: "uint256", name: "_depositLimit", type: "uint256" }], name: "setDepositLimit", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setEmergencyExit = { inputs: [], name: "setEmergencyExit", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setFeeRecipient = { inputs: [{ internalType: "address", name: "_feeRecipient", type: "address" }], name: "setFeeRecipient", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setQuitPeriod = { inputs: [{ internalType: "uint256", name: "_quitPeriod", type: "uint256" }], name: "setQuitPeriod", outputs: [], stateMutability: "nonpayable", type: "function" };
export const supplyAPY = { inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }], name: "supplyAPY", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const takeManagementAndPerformanceFees = { inputs: [], name: "takeManagementAndPerformanceFees", outputs: [], stateMutability: "nonpayable", type: "function" };
export const totalAssets = { inputs: [], name: "totalAssets", outputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transfer = { inputs: [{ internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transfer", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferFrom = { inputs: [{ internalType: "address", name: "from", type: "address" }, { internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transferFrom", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const unpause = { inputs: [], name: "unpause", outputs: [], stateMutability: "nonpayable", type: "function" };
export const withdraw_uint256 = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }], name: "withdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const withdraw_uint256_address_address = { inputs: [{ internalType: "uint256", name: "assets", type: "uint256" }, { internalType: "address", name: "receiver", type: "address" }, { internalType: "address", name: "owner", type: "address" }], name: "withdraw", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const withdrawalThreshold = { inputs: [], name: "withdrawalThreshold", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export default [IncorrectDistribution, IncorrectListLength, InsufficientWithdrawalAmount, InvalidAllocations, InvalidFeeRecipient, InvalidQuitPeriod, InvalidReceiver, InvalidSigner, InvalidVaultFees, MaxError, NotPassedQuitPeriod, PermitDeadlineExpired, Approval, ChangedAdapters, ChangedFees, Deposit, DepositLimitSet, EmergencyExitActivated, FeeRecipientUpdated, Harvested, Initialized, NewFeesProposed, OwnershipTransferStarted, OwnershipTransferred, Paused, QuitPeriodSet, Transfer, Unpaused, Withdraw, DECIMAL_OFFSET, DOMAIN_SEPARATOR, _getExtensionFunctions, acceptOwnership, accruedManagementFee, accruedPerformanceFee, adapters, adaptersCount, allowance, approve, asset, assetsCheckpoint, balanceOf, changeAdapters, changeFees, convertToAssets, convertToShares, decimals, decreaseAllowance, deposit_uint256_address, deposit_uint256, depositLimit, emergencyExit, estimatedAPR_, estimatedAPR_uint64Array, estimatedTotalAssets, feeRecipient, fees, feesUpdatedAt, flywheelForRewardToken, flywheelLogic, harvest, highWaterMark, increaseAllowance, lentTotalAssets, maxDeposit, maxMint, maxRedeem, maxWithdraw, mint_uint256_address, mint_uint256, name, nonces, owner, pause, paused, pendingOwner, permit, previewDeposit, previewMint, previewRedeem, previewWithdraw, proposeFees, proposedAdapterTime, proposedAdapters, proposedAdaptersCount, proposedFeeTime, proposedFees, pullAccruedVaultRewards, quitPeriod, redeem_uint256_address_address, redeem_uint256, registry, renounceOwnership, rewardTokens, setDepositLimit, setEmergencyExit, setFeeRecipient, setQuitPeriod, supplyAPY, symbol, takeManagementAndPerformanceFees, totalAssets, totalSupply, transfer, transferFrom, transferOwnership, unpause, withdraw_uint256, withdraw_uint256_address_address, withdrawalThreshold];
