/**
 * Autogenerated file. Do not edit manually. */
export const ExtensionNotFound = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }], name: "ExtensionNotFound", type: "error" };
export const FunctionAlreadyAdded = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }, { internalType: "address", name: "_currentImpl", type: "address" }], name: "FunctionAlreadyAdded", type: "error" };
export const FunctionNotFound = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }], name: "FunctionNotFound", type: "error" };
export const AccrueInterest = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "cashPrior", type: "uint256" }, { indexed: false, internalType: "uint256", name: "interestAccumulated", type: "uint256" }, { indexed: false, internalType: "uint256", name: "borrowIndex", type: "uint256" }, { indexed: false, internalType: "uint256", name: "totalBorrows", type: "uint256" }], name: "AccrueInterest", type: "event" };
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Approval", type: "event" };
export const Borrow = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "borrower", type: "address" }, { indexed: false, internalType: "uint256", name: "borrowAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "accountBorrows", type: "uint256" }, { indexed: false, internalType: "uint256", name: "totalBorrows", type: "uint256" }], name: "Borrow", type: "event" };
export const Failure = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "error", type: "uint256" }, { indexed: false, internalType: "uint256", name: "info", type: "uint256" }, { indexed: false, internalType: "uint256", name: "detail", type: "uint256" }], name: "Failure", type: "event" };
export const LiquidateBorrow = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "liquidator", type: "address" }, { indexed: false, internalType: "address", name: "borrower", type: "address" }, { indexed: false, internalType: "uint256", name: "repayAmount", type: "uint256" }, { indexed: false, internalType: "address", name: "cTokenCollateral", type: "address" }, { indexed: false, internalType: "uint256", name: "seizeTokens", type: "uint256" }], name: "LiquidateBorrow", type: "event" };
export const Mint = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "minter", type: "address" }, { indexed: false, internalType: "uint256", name: "mintAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "mintTokens", type: "uint256" }], name: "Mint", type: "event" };
export const NewAdminFee = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldAdminFeeMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newAdminFeeMantissa", type: "uint256" }], name: "NewAdminFee", type: "event" };
export const NewFuseFee = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldFuseFeeMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newFuseFeeMantissa", type: "uint256" }], name: "NewFuseFee", type: "event" };
export const NewImplementation = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldImplementation", type: "address" }, { indexed: false, internalType: "address", name: "newImplementation", type: "address" }], name: "NewImplementation", type: "event" };
export const NewMarketInterestRateModel = { anonymous: false, inputs: [{ indexed: false, internalType: "contract InterestRateModel", name: "oldInterestRateModel", type: "address" }, { indexed: false, internalType: "contract InterestRateModel", name: "newInterestRateModel", type: "address" }], name: "NewMarketInterestRateModel", type: "event" };
export const NewPluginImplementation = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldImpl", type: "address" }, { indexed: false, internalType: "address", name: "newImpl", type: "address" }], name: "NewPluginImplementation", type: "event" };
export const NewReserveFactor = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldReserveFactorMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newReserveFactorMantissa", type: "uint256" }], name: "NewReserveFactor", type: "event" };
export const Redeem = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "redeemer", type: "address" }, { indexed: false, internalType: "uint256", name: "redeemAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "redeemTokens", type: "uint256" }], name: "Redeem", type: "event" };
export const RepayBorrow = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "payer", type: "address" }, { indexed: false, internalType: "address", name: "borrower", type: "address" }, { indexed: false, internalType: "uint256", name: "repayAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "accountBorrows", type: "uint256" }, { indexed: false, internalType: "uint256", name: "totalBorrows", type: "uint256" }], name: "RepayBorrow", type: "event" };
export const ReservesAdded = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "benefactor", type: "address" }, { indexed: false, internalType: "uint256", name: "addAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newTotalReserves", type: "uint256" }], name: "ReservesAdded", type: "event" };
export const ReservesReduced = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "admin", type: "address" }, { indexed: false, internalType: "uint256", name: "reduceAmount", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newTotalReserves", type: "uint256" }], name: "ReservesReduced", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Transfer", type: "event" };
export const _becomeImplementation = { inputs: [{ internalType: "bytes", name: "data", type: "bytes" }], name: "_becomeImplementation", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _listExtensions = { inputs: [], name: "_listExtensions", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const _prepare = { inputs: [], name: "_prepare", outputs: [], stateMutability: "payable", type: "function" };
export const _registerExtension = { inputs: [{ internalType: "contract DiamondExtension", name: "extensionToAdd", type: "address" }, { internalType: "contract DiamondExtension", name: "extensionToReplace", type: "address" }], name: "_registerExtension", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setImplementationSafe = { inputs: [{ internalType: "address", name: "implementation_", type: "address" }, { internalType: "bool", name: "allowResign", type: "bool" }, { internalType: "bytes", name: "becomeImplementationData", type: "bytes" }], name: "_setImplementationSafe", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _updatePlugin = { inputs: [{ internalType: "address", name: "_plugin", type: "address" }], name: "_updatePlugin", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _withdrawAdminFees = { inputs: [{ internalType: "uint256", name: "withdrawAmount", type: "uint256" }], name: "_withdrawAdminFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _withdrawFuseFees = { inputs: [{ internalType: "uint256", name: "withdrawAmount", type: "uint256" }], name: "_withdrawFuseFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const accrualBlockNumber = { inputs: [], name: "accrualBlockNumber", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const adminFeeMantissa = { inputs: [], name: "adminFeeMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "_token", type: "address" }, { internalType: "address", name: "_spender", type: "address" }], name: "approve", outputs: [], stateMutability: "nonpayable", type: "function" };
export const borrow = { inputs: [{ internalType: "uint256", name: "borrowAmount", type: "uint256" }], name: "borrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const borrowIndex = { inputs: [], name: "borrowIndex", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const claim = { inputs: [], name: "claim", outputs: [], stateMutability: "nonpayable", type: "function" };
export const comptroller = { inputs: [], name: "comptroller", outputs: [{ internalType: "contract IComptroller", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const contractType = { inputs: [], name: "contractType", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "pure", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const feeSeizeShareMantissa = { inputs: [], name: "feeSeizeShareMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const fuseAdmin = { inputs: [], name: "fuseAdmin", outputs: [{ internalType: "address payable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseFeeMantissa = { inputs: [], name: "fuseFeeMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getCash = { inputs: [], name: "getCash", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const implementation = { inputs: [], name: "implementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const initialize_address_address_address_address_string_string_uint256_uint256 = { inputs: [{ internalType: "address", name: "underlying_", type: "address" }, { internalType: "contract IComptroller", name: "comptroller_", type: "address" }, { internalType: "address payable", name: "fuseAdmin_", type: "address" }, { internalType: "contract InterestRateModel", name: "interestRateModel_", type: "address" }, { internalType: "string", name: "name_", type: "string" }, { internalType: "string", name: "symbol_", type: "string" }, { internalType: "uint256", name: "reserveFactorMantissa_", type: "uint256" }, { internalType: "uint256", name: "adminFeeMantissa_", type: "uint256" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const initialize_address_address_address_uint256_string_string_uint8_uint256_uint256 = { inputs: [{ internalType: "contract IComptroller", name: "comptroller_", type: "address" }, { internalType: "address payable", name: "fuseAdmin_", type: "address" }, { internalType: "contract InterestRateModel", name: "interestRateModel_", type: "address" }, { internalType: "uint256", name: "initialExchangeRateMantissa_", type: "uint256" }, { internalType: "string", name: "name_", type: "string" }, { internalType: "string", name: "symbol_", type: "string" }, { internalType: "uint8", name: "decimals_", type: "uint8" }, { internalType: "uint256", name: "reserveFactorMantissa_", type: "uint256" }, { internalType: "uint256", name: "adminFeeMantissa_", type: "uint256" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const interestRateModel = { inputs: [], name: "interestRateModel", outputs: [{ internalType: "contract InterestRateModel", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const isCEther = { inputs: [], name: "isCEther", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const liquidateBorrow = { inputs: [{ internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }, { internalType: "address", name: "cTokenCollateral", type: "address" }], name: "liquidateBorrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const mint = { inputs: [{ internalType: "uint256", name: "mintAmount", type: "uint256" }], name: "mint", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const plugin = { inputs: [], name: "plugin", outputs: [{ internalType: "contract IERC4626", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const protocolSeizeShareMantissa = { inputs: [], name: "protocolSeizeShareMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const redeem = { inputs: [{ internalType: "uint256", name: "redeemTokens", type: "uint256" }], name: "redeem", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const redeemUnderlying = { inputs: [{ internalType: "uint256", name: "redeemAmount", type: "uint256" }], name: "redeemUnderlying", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const repayBorrow = { inputs: [{ internalType: "uint256", name: "repayAmount", type: "uint256" }], name: "repayBorrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const repayBorrowBehalf = { inputs: [{ internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }], name: "repayBorrowBehalf", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const reserveFactorMantissa = { inputs: [], name: "reserveFactorMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const seize = { inputs: [{ internalType: "address", name: "liquidator", type: "address" }, { internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "seizeTokens", type: "uint256" }], name: "seize", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const selfTransferIn = { inputs: [{ internalType: "address", name: "from", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "selfTransferIn", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const selfTransferOut = { inputs: [{ internalType: "address", name: "to", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "selfTransferOut", outputs: [], stateMutability: "nonpayable", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const totalAdminFees = { inputs: [], name: "totalAdminFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalBorrows = { inputs: [], name: "totalBorrows", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalFuseFees = { inputs: [], name: "totalFuseFees", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalReserves = { inputs: [], name: "totalReserves", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const underlying = { inputs: [], name: "underlying", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [ExtensionNotFound, FunctionAlreadyAdded, FunctionNotFound, AccrueInterest, Approval, Borrow, Failure, LiquidateBorrow, Mint, NewAdminFee, NewFuseFee, NewImplementation, NewMarketInterestRateModel, NewPluginImplementation, NewReserveFactor, Redeem, RepayBorrow, ReservesAdded, ReservesReduced, Transfer, _becomeImplementation, _listExtensions, _prepare, _registerExtension, _setImplementationSafe, _updatePlugin, _withdrawAdminFees, _withdrawFuseFees, accrualBlockNumber, adminFeeMantissa, approve, borrow, borrowIndex, claim, comptroller, contractType, decimals, feeSeizeShareMantissa, fuseAdmin, fuseFeeMantissa, getCash, implementation, initialize_address_address_address_address_string_string_uint256_uint256, initialize_address_address_address_uint256_string_string_uint8_uint256_uint256, interestRateModel, isCEther, liquidateBorrow, mint, name, plugin, protocolSeizeShareMantissa, redeem, redeemUnderlying, repayBorrow, repayBorrowBehalf, reserveFactorMantissa, seize, selfTransferIn, selfTransferOut, symbol, totalAdminFees, totalBorrows, totalFuseFees, totalReserves, totalSupply, underlying];
