/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewAdmin = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldAdmin", type: "address" }, { indexed: false, internalType: "address", name: "newAdmin", type: "address" }], name: "NewAdmin", type: "event" };
export const NewDefaultOracle = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOracle", type: "address" }, { indexed: false, internalType: "address", name: "newOracle", type: "address" }], name: "NewDefaultOracle", type: "event" };
export const NewOracle = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "underlying", type: "address" }, { indexed: false, internalType: "address", name: "oldOracle", type: "address" }, { indexed: false, internalType: "address", name: "newOracle", type: "address" }], name: "NewOracle", type: "event" };
export const add = { inputs: [{ internalType: "address[]", name: "underlyings", type: "address[]" }, { internalType: "contract BasePriceOracle[]", name: "_oracles", type: "address[]" }], name: "add", outputs: [], stateMutability: "nonpayable", type: "function" };
export const admin = { inputs: [], name: "admin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const canAdminOverwrite = { inputs: [], name: "canAdminOverwrite", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const changeAdmin = { inputs: [{ internalType: "address", name: "newAdmin", type: "address" }], name: "changeAdmin", outputs: [], stateMutability: "nonpayable", type: "function" };
export const defaultOracle = { inputs: [], name: "defaultOracle", outputs: [{ internalType: "contract BasePriceOracle", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getUnderlyingPrice = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "getUnderlyingPrice", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "address[]", name: "underlyings", type: "address[]" }, { internalType: "contract BasePriceOracle[]", name: "_oracles", type: "address[]" }, { internalType: "contract BasePriceOracle", name: "_defaultOracle", type: "address" }, { internalType: "address", name: "_admin", type: "address" }, { internalType: "bool", name: "_canAdminOverwrite", type: "bool" }, { internalType: "address", name: "_wtoken", type: "address" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const oracles = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "oracles", outputs: [{ internalType: "contract BasePriceOracle", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const price = { inputs: [{ internalType: "address", name: "underlying", type: "address" }], name: "price", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const setDefaultOracle = { inputs: [{ internalType: "contract BasePriceOracle", name: "newOracle", type: "address" }], name: "setDefaultOracle", outputs: [], stateMutability: "nonpayable", type: "function" };
export const wtoken = { inputs: [], name: "wtoken", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [Initialized, NewAdmin, NewDefaultOracle, NewOracle, add, admin, canAdminOverwrite, changeAdmin, defaultOracle, getUnderlyingPrice, initialize, oracles, price, setDefaultOracle, wtoken];
