/**
 * Autogenerated file. Do not edit manually. */
export const AccrueRewards = { anonymous: false, inputs: [{ indexed: true, internalType: "contract ERC20", name: "strategy", type: "address" }, { indexed: true, internalType: "address", name: "user", type: "address" }, { indexed: false, internalType: "uint256", name: "rewardsDelta", type: "uint256" }, { indexed: false, internalType: "uint256", name: "rewardsIndex", type: "uint256" }], name: "AccrueRewards", type: "event" };
export const AddStrategy = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "newStrategy", type: "address" }], name: "AddStrategy", type: "event" };
export const ClaimRewards = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "user", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "ClaimRewards", type: "event" };
export const FlywheelBoosterUpdate = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "newBooster", type: "address" }], name: "FlywheelBoosterUpdate", type: "event" };
export const FlywheelRewardsUpdate = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "newFlywheelRewards", type: "address" }], name: "FlywheelRewardsUpdate", type: "event" };
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const UpdatedFeeSettings = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldPerformanceFee", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newPerformanceFee", type: "uint256" }, { indexed: false, internalType: "address", name: "oldFeeRecipient", type: "address" }, { indexed: false, internalType: "address", name: "newFeeRecipient", type: "address" }], name: "UpdatedFeeSettings", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const accrue_address_address = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }, { internalType: "address", name: "user", type: "address" }], name: "accrue", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const accrue_address_address_address = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }, { internalType: "address", name: "user", type: "address" }, { internalType: "address", name: "secondUser", type: "address" }], name: "accrue", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const addMarketForRewards = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }], name: "addMarketForRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const addStrategyForRewards = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }], name: "addStrategyForRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const allStrategies = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "allStrategies", outputs: [{ internalType: "contract ERC20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const claimRewards = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "claimRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const compAccrued = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "compAccrued", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const feeRecipient = { inputs: [], name: "feeRecipient", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const flywheelBooster = { inputs: [], name: "flywheelBooster", outputs: [{ internalType: "contract IFlywheelBooster", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const flywheelPreBorrowerAction = { inputs: [{ internalType: "address", name: "market", type: "address" }, { internalType: "address", name: "borrower", type: "address" }], name: "flywheelPreBorrowerAction", outputs: [], stateMutability: "nonpayable", type: "function" };
export const flywheelPreSupplierAction = { inputs: [{ internalType: "address", name: "market", type: "address" }, { internalType: "address", name: "supplier", type: "address" }], name: "flywheelPreSupplierAction", outputs: [], stateMutability: "nonpayable", type: "function" };
export const flywheelPreTransferAction = { inputs: [{ internalType: "address", name: "market", type: "address" }, { internalType: "address", name: "src", type: "address" }, { internalType: "address", name: "dst", type: "address" }], name: "flywheelPreTransferAction", outputs: [], stateMutability: "nonpayable", type: "function" };
export const flywheelRewards = { inputs: [], name: "flywheelRewards", outputs: [{ internalType: "contract IFlywheelRewards", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getAllStrategies = { inputs: [], name: "getAllStrategies", outputs: [{ internalType: "contract ERC20[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "contract ERC20", name: "_rewardToken", type: "address" }, { internalType: "contract IFlywheelRewards", name: "_flywheelRewards", type: "address" }, { internalType: "contract IFlywheelBooster", name: "_flywheelBooster", type: "address" }, { internalType: "address", name: "_owner", type: "address" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const isFlywheel = { inputs: [], name: "isFlywheel", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isRewardsDistributor = { inputs: [], name: "isRewardsDistributor", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const marketState = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }], name: "marketState", outputs: [{ internalType: "uint224", name: "", type: "uint224" }, { internalType: "uint32", name: "", type: "uint32" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const performanceFee = { inputs: [], name: "performanceFee", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const rewardToken = { inputs: [], name: "rewardToken", outputs: [{ internalType: "contract ERC20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const rewardsAccrued = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "rewardsAccrued", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const setBooster = { inputs: [{ internalType: "contract IFlywheelBooster", name: "newBooster", type: "address" }], name: "setBooster", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setFlywheelRewards = { inputs: [{ internalType: "contract IFlywheelRewards", name: "newFlywheelRewards", type: "address" }], name: "setFlywheelRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const strategyState = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }], name: "strategyState", outputs: [{ internalType: "uint224", name: "index", type: "uint224" }, { internalType: "uint32", name: "lastUpdatedTimestamp", type: "uint32" }], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const updateFeeSettings = { inputs: [{ internalType: "uint256", name: "_performanceFee", type: "uint256" }, { internalType: "address", name: "_feeRecipient", type: "address" }], name: "updateFeeSettings", outputs: [], stateMutability: "nonpayable", type: "function" };
export const userIndex = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }, { internalType: "address", name: "user", type: "address" }], name: "userIndex", outputs: [{ internalType: "uint224", name: "", type: "uint224" }], stateMutability: "nonpayable", type: "function" };
export default [AccrueRewards, AddStrategy, ClaimRewards, FlywheelBoosterUpdate, FlywheelRewardsUpdate, Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, UpdatedFeeSettings, _acceptOwner, _setPendingOwner, accrue_address_address, accrue_address_address_address, addMarketForRewards, addStrategyForRewards, allStrategies, claimRewards, compAccrued, feeRecipient, flywheelBooster, flywheelPreBorrowerAction, flywheelPreSupplierAction, flywheelPreTransferAction, flywheelRewards, getAllStrategies, initialize, isFlywheel, isRewardsDistributor, marketState, owner, pendingOwner, performanceFee, renounceOwnership, rewardToken, rewardsAccrued, setBooster, setFlywheelRewards, strategyState, transferOwnership, updateFeeSettings, userIndex];
