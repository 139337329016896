/**
 * Autogenerated file. Do not edit manually. */
export const _setLiquidatorsRegistry = { inputs: [{ internalType: "contract ILiquidatorsRegistry", name: "_liquidatorsRegistry", type: "address" }], name: "_setLiquidatorsRegistry", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPairWhitelisted = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }, { internalType: "bool", name: "_whitelisted", type: "bool" }], name: "_setPairWhitelisted", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setSlippages = { inputs: [{ internalType: "contract IERC20Upgradeable[]", name: "inputTokens", type: "address[]" }, { internalType: "contract IERC20Upgradeable[]", name: "outputTokens", type: "address[]" }, { internalType: "uint256[]", name: "slippages", type: "uint256[]" }], name: "_setSlippages", outputs: [], stateMutability: "nonpayable", type: "function" };
export const blocksPerYear = { inputs: [], name: "blocksPerYear", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const closeAndRemoveUserPosition = { inputs: [{ internalType: "contract LeveredPosition", name: "position", type: "address" }], name: "closeAndRemoveUserPosition", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const createAndFundPosition = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "_fundingAsset", type: "address" }, { internalType: "uint256", name: "_fundingAmount", type: "uint256" }], name: "createAndFundPosition", outputs: [{ internalType: "contract LeveredPosition", name: "", type: "address" }], stateMutability: "nonpayable", type: "function" };
export const createAndFundPositionAtRatio = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "_fundingAsset", type: "address" }, { internalType: "uint256", name: "_fundingAmount", type: "uint256" }, { internalType: "uint256", name: "_leverageRatio", type: "uint256" }], name: "createAndFundPositionAtRatio", outputs: [{ internalType: "contract LeveredPosition", name: "", type: "address" }], stateMutability: "nonpayable", type: "function" };
export const createPosition = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }], name: "createPosition", outputs: [{ internalType: "contract LeveredPosition", name: "", type: "address" }], stateMutability: "nonpayable", type: "function" };
export const fuseFeeDistributor = { inputs: [], name: "fuseFeeDistributor", outputs: [{ internalType: "contract IFuseFeeDistributor", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getAccountsWithOpenPositions = { inputs: [], name: "getAccountsWithOpenPositions", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getBorrowableMarketsByCollateral = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }], name: "getBorrowableMarketsByCollateral", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getMinBorrowNative = { inputs: [], name: "getMinBorrowNative", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getPositionsByAccount = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getPositionsByAccount", outputs: [{ internalType: "address[]", name: "", type: "address[]" }, { internalType: "bool[]", name: "", type: "bool[]" }], stateMutability: "view", type: "function" };
export const getPositionsExtension = { inputs: [{ internalType: "bytes4", name: "msgSig", type: "bytes4" }], name: "getPositionsExtension", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getRedemptionStrategies = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "getRedemptionStrategies", outputs: [{ internalType: "contract IRedemptionStrategy[]", name: "strategies", type: "address[]" }, { internalType: "bytes[]", name: "strategiesData", type: "bytes[]" }], stateMutability: "view", type: "function" };
export const getSlippage = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "inputToken", type: "address" }, { internalType: "contract IERC20Upgradeable", name: "outputToken", type: "address" }], name: "getSlippage", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getWhitelistedCollateralMarkets = { inputs: [], name: "getWhitelistedCollateralMarkets", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const liquidatorsRegistry = { inputs: [], name: "liquidatorsRegistry", outputs: [{ internalType: "contract ILiquidatorsRegistry", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const removeClosedPosition = { inputs: [{ internalType: "address", name: "closedPosition", type: "address" }], name: "removeClosedPosition", outputs: [{ internalType: "bool", name: "removed", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export default [_setLiquidatorsRegistry, _setPairWhitelisted, _setSlippages, blocksPerYear, closeAndRemoveUserPosition, createAndFundPosition, createAndFundPositionAtRatio, createPosition, fuseFeeDistributor, getAccountsWithOpenPositions, getBorrowableMarketsByCollateral, getMinBorrowNative, getPositionsByAccount, getPositionsExtension, getRedemptionStrategies, getSlippage, getWhitelistedCollateralMarkets, liquidatorsRegistry, owner, removeClosedPosition];
