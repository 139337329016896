/**
 * Autogenerated file. Do not edit manually. */
export const Approval = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "owner", type: "address" }, { indexed: true, internalType: "address", name: "spender", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Approval", type: "event" };
export const Transfer = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "from", type: "address" }, { indexed: true, internalType: "address", name: "to", type: "address" }, { indexed: false, internalType: "uint256", name: "amount", type: "uint256" }], name: "Transfer", type: "event" };
export const allowance = { inputs: [{ internalType: "address", name: "owner", type: "address" }, { internalType: "address", name: "spender", type: "address" }], name: "allowance", outputs: [{ internalType: "uint256", name: "remaining", type: "uint256" }], stateMutability: "view", type: "function" };
export const approve = { inputs: [{ internalType: "address", name: "spender", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "approve", outputs: [{ internalType: "bool", name: "success", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const balanceOf = { inputs: [{ internalType: "address", name: "owner", type: "address" }], name: "balanceOf", outputs: [{ internalType: "uint256", name: "balance", type: "uint256" }], stateMutability: "view", type: "function" };
export const decimals = { inputs: [], name: "decimals", outputs: [{ internalType: "uint8", name: "", type: "uint8" }], stateMutability: "view", type: "function" };
export const name = { inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const symbol = { inputs: [], name: "symbol", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" };
export const totalSupply = { inputs: [], name: "totalSupply", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transfer = { inputs: [{ internalType: "address", name: "dst", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transfer", outputs: [{ internalType: "bool", name: "success", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export const transferFrom = { inputs: [{ internalType: "address", name: "src", type: "address" }, { internalType: "address", name: "dst", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "transferFrom", outputs: [{ internalType: "bool", name: "success", type: "bool" }], stateMutability: "nonpayable", type: "function" };
export default [Approval, Transfer, allowance, approve, balanceOf, decimals, name, symbol, totalSupply, transfer, transferFrom];
