import { BigNumber } from "ethers";
export const getChainLiquidationConfig = (sdk) => {
    return {
        SUPPORTED_OUTPUT_CURRENCIES: process.env.SUPPORTED_OUTPUT_CURRENCIES
            ? process.env.SUPPORTED_OUTPUT_CURRENCIES.split(",")
            : sdk.liquidationConfig.SUPPORTED_OUTPUT_CURRENCIES,
        SUPPORTED_INPUT_CURRENCIES: process.env.SUPPORTED_INPUT_CURRENCIES
            ? process.env.SUPPORTED_INPUT_CURRENCIES.split(",")
            : sdk.liquidationConfig.SUPPORTED_INPUT_CURRENCIES,
        LIQUIDATION_STRATEGY: process.env.LIQUIDATION_STRATEGY
            ? process.env.LIQUIDATION_STRATEGY
            : sdk.liquidationConfig.LIQUIDATION_STRATEGY,
        MINIMUM_PROFIT_NATIVE: process.env.MINIMUM_PROFIT_NATIVE
            ? BigNumber.from(process.env.MINIMUM_PROFIT_NATIVE)
            : sdk.liquidationConfig.MINIMUM_PROFIT_NATIVE,
        LIQUIDATION_INTERVAL_SECONDS: process.env.LIQUIDATION_INTERVAL_SECONDS
            ? parseInt(process.env.LIQUIDATION_INTERVAL_SECONDS)
            : sdk.liquidationConfig.LIQUIDATION_INTERVAL_SECONDS,
    };
};
