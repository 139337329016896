/**
 * Autogenerated file. Do not edit manually. */
export const ExtensionNotFound = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }], name: "ExtensionNotFound", type: "error" };
export const FunctionAlreadyAdded = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }, { internalType: "address", name: "_currentImpl", type: "address" }], name: "FunctionAlreadyAdded", type: "error" };
export const FunctionNotFound = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }], name: "FunctionNotFound", type: "error" };
export const AddedRewardsDistributor = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "rewardsDistributor", type: "address" }], name: "AddedRewardsDistributor", type: "event" };
export const AutoImplementationsToggled = { anonymous: false, inputs: [{ indexed: false, internalType: "bool", name: "enabled", type: "bool" }], name: "AutoImplementationsToggled", type: "event" };
export const Failure = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "error", type: "uint256" }, { indexed: false, internalType: "uint256", name: "info", type: "uint256" }, { indexed: false, internalType: "uint256", name: "detail", type: "uint256" }], name: "Failure", type: "event" };
export const MarketEntered = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "address", name: "account", type: "address" }], name: "MarketEntered", type: "event" };
export const MarketExited = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "address", name: "account", type: "address" }], name: "MarketExited", type: "event" };
export const MarketListed = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "MarketListed", type: "event" };
export const NewCloseFactor = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldCloseFactorMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newCloseFactorMantissa", type: "uint256" }], name: "NewCloseFactor", type: "event" };
export const NewCollateralFactor = { anonymous: false, inputs: [{ indexed: false, internalType: "contract ICErc20", name: "cToken", type: "address" }, { indexed: false, internalType: "uint256", name: "oldCollateralFactorMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newCollateralFactorMantissa", type: "uint256" }], name: "NewCollateralFactor", type: "event" };
export const NewLiquidationIncentive = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "oldLiquidationIncentiveMantissa", type: "uint256" }, { indexed: false, internalType: "uint256", name: "newLiquidationIncentiveMantissa", type: "uint256" }], name: "NewLiquidationIncentive", type: "event" };
export const NewPriceOracle = { anonymous: false, inputs: [{ indexed: false, internalType: "contract BasePriceOracle", name: "oldPriceOracle", type: "address" }, { indexed: false, internalType: "contract BasePriceOracle", name: "newPriceOracle", type: "address" }], name: "NewPriceOracle", type: "event" };
export const WhitelistEnforcementChanged = { anonymous: false, inputs: [{ indexed: false, internalType: "bool", name: "enforce", type: "bool" }], name: "WhitelistEnforcementChanged", type: "event" };
export const _addRewardsDistributor = { inputs: [{ internalType: "address", name: "distributor", type: "address" }], name: "_addRewardsDistributor", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _afterNonReentrant = { inputs: [], name: "_afterNonReentrant", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _become = { inputs: [{ internalType: "address", name: "_unitroller", type: "address" }], name: "_become", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _becomeImplementation = { inputs: [], name: "_becomeImplementation", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _beforeNonReentrant = { inputs: [], name: "_beforeNonReentrant", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _borrowGuardianPaused = { inputs: [], name: "_borrowGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const _deployMarket = { inputs: [{ internalType: "bool", name: "", type: "bool" }, { internalType: "bytes", name: "constructorData", type: "bytes" }, { internalType: "uint256", name: "collateralFactorMantissa", type: "uint256" }], name: "_deployMarket", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _listExtensions = { inputs: [], name: "_listExtensions", outputs: [{ internalType: "address[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const _mintGuardianPaused = { inputs: [], name: "_mintGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const _registerExtension = { inputs: [{ internalType: "contract DiamondExtension", name: "extensionToAdd", type: "address" }, { internalType: "contract DiamondExtension", name: "extensionToReplace", type: "address" }], name: "_registerExtension", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setCloseFactor = { inputs: [{ internalType: "uint256", name: "newCloseFactorMantissa", type: "uint256" }], name: "_setCloseFactor", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setCollateralFactor = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }, { internalType: "uint256", name: "newCollateralFactorMantissa", type: "uint256" }], name: "_setCollateralFactor", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setLiquidationIncentive = { inputs: [{ internalType: "uint256", name: "newLiquidationIncentiveMantissa", type: "uint256" }], name: "_setLiquidationIncentive", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setPriceOracle = { inputs: [{ internalType: "contract BasePriceOracle", name: "newOracle", type: "address" }], name: "_setPriceOracle", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setWhitelistEnforcement = { inputs: [{ internalType: "bool", name: "enforce", type: "bool" }], name: "_setWhitelistEnforcement", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _setWhitelistStatuses = { inputs: [{ internalType: "address[]", name: "suppliers", type: "address[]" }, { internalType: "bool[]", name: "statuses", type: "bool[]" }], name: "_setWhitelistStatuses", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const _toggleAutoImplementations = { inputs: [{ internalType: "bool", name: "enabled", type: "bool" }], name: "_toggleAutoImplementations", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const accountAssets = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "uint256", name: "", type: "uint256" }], name: "accountAssets", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const admin = { inputs: [], name: "admin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const adminHasRights = { inputs: [], name: "adminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const allBorrowers = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "allBorrowers", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const allMarkets = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "allMarkets", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const autoImplementation = { inputs: [], name: "autoImplementation", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const borrowAllowed = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "borrowAmount", type: "uint256" }], name: "borrowAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const borrowCapForCollateral = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "borrowCapForCollateral", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowCapGuardian = { inputs: [], name: "borrowCapGuardian", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const borrowCaps = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "borrowCaps", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowGuardianPaused = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "borrowGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const borrowWithinLimits = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "uint256", name: "accountBorrowsNew", type: "uint256" }], name: "borrowWithinLimits", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const borrowingAgainstCollateralBlacklist = { inputs: [{ internalType: "address", name: "", type: "address" }, { internalType: "address", name: "", type: "address" }], name: "borrowingAgainstCollateralBlacklist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const cTokensByUnderlying = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "cTokensByUnderlying", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const checkMembership = { inputs: [{ internalType: "address", name: "account", type: "address" }, { internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "checkMembership", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const closeFactorMantissa = { inputs: [], name: "closeFactorMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const comptrollerImplementation = { inputs: [], name: "comptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const enforceWhitelist = { inputs: [], name: "enforceWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const enterMarkets = { inputs: [{ internalType: "address[]", name: "cTokens", type: "address[]" }], name: "enterMarkets", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }], stateMutability: "nonpayable", type: "function" };
export const exitMarket = { inputs: [{ internalType: "address", name: "cTokenAddress", type: "address" }], name: "exitMarket", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const fuseAdmin = { inputs: [], name: "fuseAdmin", outputs: [{ internalType: "address payable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fuseAdminHasRights = { inputs: [], name: "fuseAdminHasRights", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const getAccountLiquidity = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getAccountLiquidity", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getAssetsIn = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getAssetsIn", outputs: [{ internalType: "contract ICErc20[]", name: "", type: "address[]" }], stateMutability: "view", type: "function" };
export const getHypotheticalAccountLiquidity = { inputs: [{ internalType: "address", name: "account", type: "address" }, { internalType: "address", name: "cTokenModify", type: "address" }, { internalType: "uint256", name: "redeemTokens", type: "uint256" }, { internalType: "uint256", name: "borrowAmount", type: "uint256" }], name: "getHypotheticalAccountLiquidity", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getMaxRedeemOrBorrow = { inputs: [{ internalType: "address", name: "account", type: "address" }, { internalType: "contract ICErc20", name: "cTokenModify", type: "address" }, { internalType: "bool", name: "isBorrow", type: "bool" }], name: "getMaxRedeemOrBorrow", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const isComptroller = { inputs: [], name: "isComptroller", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const isDeprecated = { inputs: [{ internalType: "contract ICErc20", name: "cToken", type: "address" }], name: "isDeprecated", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const liquidateBorrowAllowed = { inputs: [{ internalType: "address", name: "cTokenBorrowed", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "liquidator", type: "address" }, { internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }], name: "liquidateBorrowAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const liquidateCalculateSeizeTokens = { inputs: [{ internalType: "address", name: "cTokenBorrowed", type: "address" }, { internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "uint256", name: "actualRepayAmount", type: "uint256" }], name: "liquidateCalculateSeizeTokens", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const liquidationIncentiveMantissa = { inputs: [], name: "liquidationIncentiveMantissa", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const markets = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "markets", outputs: [{ internalType: "bool", name: "isListed", type: "bool" }, { internalType: "uint256", name: "collateralFactorMantissa", type: "uint256" }], stateMutability: "view", type: "function" };
export const mintAllowed = { inputs: [{ internalType: "address", name: "cTokenAddress", type: "address" }, { internalType: "address", name: "minter", type: "address" }, { internalType: "uint256", name: "mintAmount", type: "uint256" }], name: "mintAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const mintGuardianPaused = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "mintGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const nonAccruingRewardsDistributors = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "nonAccruingRewardsDistributors", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const oracle = { inputs: [], name: "oracle", outputs: [{ internalType: "contract BasePriceOracle", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pauseGuardian = { inputs: [], name: "pauseGuardian", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingAdmin = { inputs: [], name: "pendingAdmin", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingComptrollerImplementation = { inputs: [], name: "pendingComptrollerImplementation", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const redeemAllowed = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "redeemer", type: "address" }, { internalType: "uint256", name: "redeemTokens", type: "uint256" }], name: "redeemAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const redeemVerify = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "redeemer", type: "address" }, { internalType: "uint256", name: "redeemAmount", type: "uint256" }, { internalType: "uint256", name: "redeemTokens", type: "uint256" }], name: "redeemVerify", outputs: [], stateMutability: "nonpayable", type: "function" };
export const repayBorrowAllowed = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "payer", type: "address" }, { internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }], name: "repayBorrowAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const rewardsDistributors = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "rewardsDistributors", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const seizeAllowed = { inputs: [{ internalType: "address", name: "cTokenCollateral", type: "address" }, { internalType: "address", name: "cTokenBorrowed", type: "address" }, { internalType: "address", name: "liquidator", type: "address" }, { internalType: "address", name: "borrower", type: "address" }, { internalType: "uint256", name: "seizeTokens", type: "uint256" }], name: "seizeAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const seizeGuardianPaused = { inputs: [], name: "seizeGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const suppliers = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "suppliers", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const supplyCaps = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "supplyCaps", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const transferAllowed = { inputs: [{ internalType: "address", name: "cToken", type: "address" }, { internalType: "address", name: "src", type: "address" }, { internalType: "address", name: "dst", type: "address" }, { internalType: "uint256", name: "transferTokens", type: "uint256" }], name: "transferAllowed", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const transferGuardianPaused = { inputs: [], name: "transferGuardianPaused", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const whitelist = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "whitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const whitelistArray = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "whitelistArray", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export default [ExtensionNotFound, FunctionAlreadyAdded, FunctionNotFound, AddedRewardsDistributor, AutoImplementationsToggled, Failure, MarketEntered, MarketExited, MarketListed, NewCloseFactor, NewCollateralFactor, NewLiquidationIncentive, NewPriceOracle, WhitelistEnforcementChanged, _addRewardsDistributor, _afterNonReentrant, _become, _becomeImplementation, _beforeNonReentrant, _borrowGuardianPaused, _deployMarket, _listExtensions, _mintGuardianPaused, _registerExtension, _setCloseFactor, _setCollateralFactor, _setLiquidationIncentive, _setPriceOracle, _setWhitelistEnforcement, _setWhitelistStatuses, _toggleAutoImplementations, accountAssets, admin, adminHasRights, allBorrowers, allMarkets, autoImplementation, borrowAllowed, borrowCapForCollateral, borrowCapGuardian, borrowCaps, borrowGuardianPaused, borrowWithinLimits, borrowingAgainstCollateralBlacklist, cTokensByUnderlying, checkMembership, closeFactorMantissa, comptrollerImplementation, enforceWhitelist, enterMarkets, exitMarket, fuseAdmin, fuseAdminHasRights, getAccountLiquidity, getAssetsIn, getHypotheticalAccountLiquidity, getMaxRedeemOrBorrow, isComptroller, isDeprecated, liquidateBorrowAllowed, liquidateCalculateSeizeTokens, liquidationIncentiveMantissa, markets, mintAllowed, mintGuardianPaused, nonAccruingRewardsDistributors, oracle, pauseGuardian, pendingAdmin, pendingComptrollerImplementation, redeemAllowed, redeemVerify, repayBorrowAllowed, rewardsDistributors, seizeAllowed, seizeGuardianPaused, suppliers, supplyCaps, transferAllowed, transferGuardianPaused, whitelist, whitelistArray];
