/**
 * Autogenerated file. Do not edit manually. */
export const BorrowStableFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "BorrowStableFailed", type: "error" };
export const ConvertFundsFailed = { inputs: [], name: "ConvertFundsFailed", type: "error" };
export const ExitFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "ExitFailed", type: "error" };
export const ExtNotFound = { inputs: [{ internalType: "bytes4", name: "_functionSelector", type: "bytes4" }], name: "ExtNotFound", type: "error" };
export const NotPositionOwner = { inputs: [], name: "NotPositionOwner", type: "error" };
export const OnlyWhenClosed = { inputs: [], name: "OnlyWhenClosed", type: "error" };
export const RedeemCollateralFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "RedeemCollateralFailed", type: "error" };
export const RedeemFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "RedeemFailed", type: "error" };
export const RepayBorrowFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "RepayBorrowFailed", type: "error" };
export const RepayFlashLoanFailed = { inputs: [{ internalType: "address", name: "asset", type: "address" }, { internalType: "uint256", name: "currentBalance", type: "uint256" }, { internalType: "uint256", name: "repayAmount", type: "uint256" }], name: "RepayFlashLoanFailed", type: "error" };
export const SupplyCollateralFailed = { inputs: [{ internalType: "uint256", name: "errorCode", type: "uint256" }], name: "SupplyCollateralFailed", type: "error" };
export const adjustLeverageRatio = { inputs: [{ internalType: "uint256", name: "targetRatioMantissa", type: "uint256" }], name: "adjustLeverageRatio", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const claimRewards_ = { inputs: [], name: "claimRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const claimRewards_address = { inputs: [{ internalType: "address", name: "withdrawTo", type: "address" }], name: "claimRewards", outputs: [], stateMutability: "nonpayable", type: "function" };
export const closePosition_address = { inputs: [{ internalType: "address", name: "withdrawTo", type: "address" }], name: "closePosition", outputs: [{ internalType: "uint256", name: "withdrawAmount", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const closePosition_ = { inputs: [], name: "closePosition", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export const collateralAsset = { inputs: [], name: "collateralAsset", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const collateralMarket = { inputs: [], name: "collateralMarket", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const factory = { inputs: [], name: "factory", outputs: [{ internalType: "contract ILeveredPositionFactory", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const fundPosition = { inputs: [{ internalType: "contract IERC20Upgradeable", name: "fundingAsset", type: "address" }, { internalType: "uint256", name: "amount", type: "uint256" }], name: "fundPosition", outputs: [], stateMutability: "nonpayable", type: "function" };
export const getAccruedRewards = { inputs: [], name: "getAccruedRewards", outputs: [{ internalType: "contract ERC20[]", name: "rewardTokens", type: "address[]" }, { internalType: "uint256[]", name: "amounts", type: "uint256[]" }], stateMutability: "nonpayable", type: "function" };
export const getCurrentLeverageRatio = { inputs: [], name: "getCurrentLeverageRatio", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getEquityAmount = { inputs: [], name: "getEquityAmount", outputs: [{ internalType: "uint256", name: "equityAmount", type: "uint256" }], stateMutability: "view", type: "function" };
export const getMaxLeverageRatio = { inputs: [], name: "getMaxLeverageRatio", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getMinLeverageRatio = { inputs: [], name: "getMinLeverageRatio", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const isPositionClosed = { inputs: [], name: "isPositionClosed", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pool = { inputs: [], name: "pool", outputs: [{ internalType: "contract IComptroller", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const positionOwner = { inputs: [], name: "positionOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const receiveFlashLoan = { inputs: [{ internalType: "address", name: "assetAddress", type: "address" }, { internalType: "uint256", name: "borrowedAmount", type: "uint256" }, { internalType: "bytes", name: "data", type: "bytes" }], name: "receiveFlashLoan", outputs: [], stateMutability: "nonpayable", type: "function" };
export const stableAsset = { inputs: [], name: "stableAsset", outputs: [{ internalType: "contract IERC20Upgradeable", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const stableMarket = { inputs: [], name: "stableMarket", outputs: [{ internalType: "contract ICErc20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const withdrawStableLeftovers = { inputs: [{ internalType: "address", name: "withdrawTo", type: "address" }], name: "withdrawStableLeftovers", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "nonpayable", type: "function" };
export default [BorrowStableFailed, ConvertFundsFailed, ExitFailed, ExtNotFound, NotPositionOwner, OnlyWhenClosed, RedeemCollateralFailed, RedeemFailed, RepayBorrowFailed, RepayFlashLoanFailed, SupplyCollateralFailed, adjustLeverageRatio, claimRewards_, claimRewards_address, closePosition_address, closePosition_, collateralAsset, collateralMarket, factory, fundPosition, getAccruedRewards, getCurrentLeverageRatio, getEquityAmount, getMaxLeverageRatio, getMinLeverageRatio, isPositionClosed, pool, positionOwner, receiveFlashLoan, stableAsset, stableMarket, withdrawStableLeftovers];
