/**
 * Autogenerated file. Do not edit manually. */
export const NewInterestParams = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "baseRatePerBlock", type: "uint256" }, { indexed: false, internalType: "uint256", name: "multiplierPerBlock", type: "uint256" }, { indexed: false, internalType: "uint256", name: "jumpMultiplierPerBlock", type: "uint256" }, { indexed: false, internalType: "uint256", name: "kink", type: "uint256" }], name: "NewInterestParams", type: "event" };
export const baseRatePerBlock = { inputs: [], name: "baseRatePerBlock", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const blocksPerYear = { inputs: [], name: "blocksPerYear", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getBorrowRate = { inputs: [{ internalType: "uint256", name: "cash", type: "uint256" }, { internalType: "uint256", name: "borrows", type: "uint256" }, { internalType: "uint256", name: "reserves", type: "uint256" }], name: "getBorrowRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getSupplyRate = { inputs: [{ internalType: "uint256", name: "cash", type: "uint256" }, { internalType: "uint256", name: "borrows", type: "uint256" }, { internalType: "uint256", name: "reserves", type: "uint256" }, { internalType: "uint256", name: "reserveFactorMantissa", type: "uint256" }], name: "getSupplyRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const isInterestRateModel = { inputs: [], name: "isInterestRateModel", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const jumpMultiplierPerBlock = { inputs: [], name: "jumpMultiplierPerBlock", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const kink = { inputs: [], name: "kink", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const multiplierPerBlock = { inputs: [], name: "multiplierPerBlock", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const utilizationRate = { inputs: [{ internalType: "uint256", name: "cash", type: "uint256" }, { internalType: "uint256", name: "borrows", type: "uint256" }, { internalType: "uint256", name: "reserves", type: "uint256" }], name: "utilizationRate", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "pure", type: "function" };
export default [NewInterestParams, baseRatePerBlock, blocksPerYear, getBorrowRate, getSupplyRate, isInterestRateModel, jumpMultiplierPerBlock, kink, multiplierPerBlock, utilizationRate];
