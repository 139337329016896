import { Contract } from "ethers";
import CErc20DelegateABI from "../../abis/CErc20Delegate";
import CErc20PluginRewardsDelegateABI from "../../abis/CErc20PluginRewardsDelegate";
import CompoundMarketERC4626ABI from "../../abis/CompoundMarketERC4626";
import ComptrollerABI from "../../abis/Comptroller";
import ComptrollerFirstExtensionABI from "../../abis/ComptrollerFirstExtension";
import CTokenFirstExtensionABI from "../../abis/CTokenFirstExtension";
import FlywheelStaticRewardsABI from "../../abis/FlywheelStaticRewards";
import FusePoolLensSecondaryABI from "../../abis/FusePoolLensSecondary";
import ILeveredPositionFactoryABI from "../../abis/ILeveredPositionFactory";
import ILiquidatorsRegistryABI from "../../abis/ILiquidatorsRegistry";
import JumpRateModelABI from "../../abis/JumpRateModel";
import LeveredPositionABI from "../../abis/LeveredPosition";
import LeveredPositionsLensABI from "../../abis/LeveredPositionsLens";
import MasterPriceOracleABI from "../../abis/MasterPriceOracle";
import MidasFlywheelABI from "../../abis/MidasFlywheel";
import MidasFlywheelLensRouterABI from "../../abis/MidasFlywheelLensRouter";
import OptimizedAPRVaultFirstExtensionABI from "../../abis/OptimizedAPRVaultFirstExtension";
import OptimizedAPRVaultSecondExtensionABI from "../../abis/OptimizedAPRVaultSecondExtension";
import OptimizedVaultsRegistryABI from "../../abis/OptimizedVaultsRegistry";
import UnitrollerABI from "../../abis/Unitroller";
export function withCreateContracts(Base) {
    return class CreateContracts extends Base {
        constructor() {
            super(...arguments);
            this.createUnitroller = this.createContractInstance(UnitrollerABI);
            this.createMidasFlywheel = this.createContractInstance(MidasFlywheelABI);
            this.createFlywheelStaticRewards = this.createContractInstance(FlywheelStaticRewardsABI);
            this.createJumpRateModel = this.createContractInstance(JumpRateModelABI);
        }
        createContractInstance(abi) {
            return (address, signerOrProvider = this.signer) => new Contract(address, abi, signerOrProvider);
        }
        createComptroller(comptrollerAddress, signerOrProvider = this.provider) {
            if (this.chainDeployment.ComptrollerFirstExtension) {
                return new Contract(comptrollerAddress, [...ComptrollerABI, ...ComptrollerFirstExtensionABI], signerOrProvider);
            }
            return new Contract(comptrollerAddress, ComptrollerABI, signerOrProvider);
        }
        createCTokenWithExtensions(address, signerOrProvider = this.provider) {
            if (this.chainDeployment.CTokenFirstExtension) {
                return new Contract(address, [...CErc20DelegateABI, ...CTokenFirstExtensionABI], signerOrProvider);
            }
            return new Contract(address, CErc20DelegateABI, signerOrProvider);
        }
        createCErc20PluginRewardsDelegate(cTokenAddress, signerOrProvider = this.provider) {
            return new Contract(cTokenAddress, CErc20PluginRewardsDelegateABI, signerOrProvider);
        }
        createMasterPriceOracle(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.MasterPriceOracle.address, MasterPriceOracleABI, signerOrProvider);
        }
        createCompoundMarketERC4626(address, signerOrProvider = this.provider) {
            return new Contract(address, CompoundMarketERC4626ABI, signerOrProvider);
        }
        createOptimizedAPRVault(address, signerOrProvider = this.provider) {
            return new Contract(address, [...OptimizedAPRVaultFirstExtensionABI, ...OptimizedAPRVaultSecondExtensionABI], signerOrProvider);
        }
        createOptimizedVaultsRegistry(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.OptimizedVaultsRegistry.address, OptimizedVaultsRegistryABI, signerOrProvider);
        }
        createMidasFlywheelLensRouter(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.MidasFlywheelLensRouter.address, MidasFlywheelLensRouterABI, signerOrProvider);
        }
        createLeveredPositionFactory(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.LeveredPositionFactory.address, ILeveredPositionFactoryABI, signerOrProvider);
        }
        createLeveredPosition(address, signerOrProvider = this.provider) {
            return new Contract(address, LeveredPositionABI, signerOrProvider);
        }
        createLeveredPositionLens(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.LeveredPositionsLens.address, LeveredPositionsLensABI, signerOrProvider);
        }
        createFusePoolLensSecondary(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.FusePoolLensSecondary.address, FusePoolLensSecondaryABI, signerOrProvider);
        }
        createILiquidatorsRegistry(signerOrProvider = this.provider) {
            return new Contract(this.chainDeployment.LiquidatorsRegistry.address, ILiquidatorsRegistryABI, signerOrProvider);
        }
    };
}
