/**
 * Autogenerated file. Do not edit manually. */
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const factory = { inputs: [], name: "factory", outputs: [{ internalType: "contract ILeveredPositionFactory", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getBorrowRateAtRatio = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }, { internalType: "uint256", name: "_equityAmount", type: "uint256" }, { internalType: "uint256", name: "_targetLeverageRatio", type: "uint256" }], name: "getBorrowRateAtRatio", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getBorrowableMarketsAndRates = { inputs: [{ internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }], name: "getBorrowableMarketsAndRates", outputs: [{ internalType: "address[]", name: "markets", type: "address[]" }, { internalType: "address[]", name: "underlyings", type: "address[]" }, { internalType: "uint256[]", name: "underlyingsPrices", type: "uint256[]" }, { internalType: "string[]", name: "names", type: "string[]" }, { internalType: "string[]", name: "symbols", type: "string[]" }, { internalType: "uint256[]", name: "rates", type: "uint256[]" }, { internalType: "uint8[]", name: "decimals", type: "uint8[]" }], stateMutability: "view", type: "function" };
export const getCollateralMarkets = { inputs: [], name: "getCollateralMarkets", outputs: [{ internalType: "address[]", name: "markets", type: "address[]" }, { internalType: "contract IComptroller[]", name: "poolOfMarket", type: "address[]" }, { internalType: "address[]", name: "underlyings", type: "address[]" }, { internalType: "uint256[]", name: "underlyingPrices", type: "uint256[]" }, { internalType: "string[]", name: "names", type: "string[]" }, { internalType: "string[]", name: "symbols", type: "string[]" }, { internalType: "uint8[]", name: "decimals", type: "uint8[]" }, { internalType: "uint256[]", name: "totalUnderlyingSupplied", type: "uint256[]" }, { internalType: "uint256[]", name: "ratesPerBlock", type: "uint256[]" }], stateMutability: "view", type: "function" };
export const getLeverageRatioAfterFunding = { inputs: [{ internalType: "contract LeveredPosition", name: "pos", type: "address" }, { internalType: "uint256", name: "newFunding", type: "uint256" }], name: "getLeverageRatioAfterFunding", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const getNetAPY = { inputs: [{ internalType: "uint256", name: "_supplyAPY", type: "uint256" }, { internalType: "uint256", name: "_supplyAmount", type: "uint256" }, { internalType: "contract ICErc20", name: "_collateralMarket", type: "address" }, { internalType: "contract ICErc20", name: "_stableMarket", type: "address" }, { internalType: "uint256", name: "_targetLeverageRatio", type: "uint256" }], name: "getNetAPY", outputs: [{ internalType: "int256", name: "netAPY", type: "int256" }], stateMutability: "view", type: "function" };
export const getNetApyForPosition = { inputs: [{ internalType: "contract LeveredPosition", name: "pos", type: "address" }, { internalType: "uint256", name: "supplyAPY", type: "uint256" }], name: "getNetApyForPosition", outputs: [{ internalType: "int256", name: "", type: "int256" }], stateMutability: "view", type: "function" };
export const getNetApyForPositionAfterFunding = { inputs: [{ internalType: "contract LeveredPosition", name: "pos", type: "address" }, { internalType: "uint256", name: "supplyAPY", type: "uint256" }, { internalType: "uint256", name: "newFunding", type: "uint256" }], name: "getNetApyForPositionAfterFunding", outputs: [{ internalType: "int256", name: "", type: "int256" }], stateMutability: "view", type: "function" };
export const getPositionInfo = { inputs: [{ internalType: "contract LeveredPosition", name: "pos", type: "address" }, { internalType: "uint256", name: "supplyApy", type: "uint256" }], name: "getPositionInfo", outputs: [{ components: [{ internalType: "uint256", name: "collateralAssetPrice", type: "uint256" }, { internalType: "uint256", name: "borrowedAssetPrice", type: "uint256" }, { internalType: "uint256", name: "positionSupplyAmount", type: "uint256" }, { internalType: "uint256", name: "positionValue", type: "uint256" }, { internalType: "uint256", name: "debtAmount", type: "uint256" }, { internalType: "uint256", name: "debtValue", type: "uint256" }, { internalType: "uint256", name: "equityAmount", type: "uint256" }, { internalType: "uint256", name: "equityValue", type: "uint256" }, { internalType: "int256", name: "currentApy", type: "int256" }, { internalType: "uint256", name: "debtRatio", type: "uint256" }, { internalType: "uint256", name: "liquidationThreshold", type: "uint256" }, { internalType: "uint256", name: "safetyBuffer", type: "uint256" }], internalType: "struct LeveredPositionsLens.PositionInfo", name: "info", type: "tuple" }], stateMutability: "view", type: "function" };
export const getPositionsInfo = { inputs: [{ internalType: "contract LeveredPosition[]", name: "positions", type: "address[]" }, { internalType: "uint256[]", name: "supplyApys", type: "uint256[]" }], name: "getPositionsInfo", outputs: [{ components: [{ internalType: "uint256", name: "collateralAssetPrice", type: "uint256" }, { internalType: "uint256", name: "borrowedAssetPrice", type: "uint256" }, { internalType: "uint256", name: "positionSupplyAmount", type: "uint256" }, { internalType: "uint256", name: "positionValue", type: "uint256" }, { internalType: "uint256", name: "debtAmount", type: "uint256" }, { internalType: "uint256", name: "debtValue", type: "uint256" }, { internalType: "uint256", name: "equityAmount", type: "uint256" }, { internalType: "uint256", name: "equityValue", type: "uint256" }, { internalType: "int256", name: "currentApy", type: "int256" }, { internalType: "uint256", name: "debtRatio", type: "uint256" }, { internalType: "uint256", name: "liquidationThreshold", type: "uint256" }, { internalType: "uint256", name: "safetyBuffer", type: "uint256" }], internalType: "struct LeveredPositionsLens.PositionInfo[]", name: "infos", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "contract ILeveredPositionFactory", name: "_factory", type: "address" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [Initialized, factory, getBorrowRateAtRatio, getBorrowableMarketsAndRates, getCollateralMarkets, getLeverageRatioAfterFunding, getNetAPY, getNetApyForPosition, getNetApyForPositionAfterFunding, getPositionInfo, getPositionsInfo, initialize];
