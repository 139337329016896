var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { chainIdToConfig } from "@midas-capital/chains";
import { BigNumber } from "ethers";
export function withFusePoolLens(Base) {
    return class FusePoolLens extends Base {
        /**
         * @returns the TVL on current chain in native asset value
         */
        getTotalValueLocked(whitelistedAdmin = true) {
            return __awaiter(this, void 0, void 0, function* () {
                const { 2: fusePoolDataStructs } = yield this.contracts.FusePoolLens.callStatic.getPublicPoolsByVerificationWithData(whitelistedAdmin);
                return fusePoolDataStructs
                    .map((data) => data.totalSupply)
                    .reduce((prev, cur) => prev.add(cur), BigNumber.from(0));
            });
        }
        /**
         * @returns a set of the currently live assets on our platform on the current chain
         */
        getLiveAssets() {
            return __awaiter(this, void 0, void 0, function* () {
                const pools = yield this.contracts.FusePoolDirectory.callStatic.getAllPools();
                const allAssets = new Set();
                for (const pool of pools) {
                    const [, , ulTokens] = yield this.contracts.FusePoolLens.callStatic.getPoolSummary(pool.comptroller);
                    for (const token of ulTokens) {
                        const asset = chainIdToConfig[this.chainId].assets.find((x) => x.underlying === token);
                        if (!asset) {
                            throw new Error(`Asset not found for ${token}, this should never happen`);
                        }
                        else {
                            allAssets.add(asset);
                        }
                    }
                }
                return allAssets;
            });
        }
    };
}
