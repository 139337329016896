/**
 * Autogenerated file. Do not edit manually. */
export const AdminWhitelistUpdated = { anonymous: false, inputs: [{ indexed: false, internalType: "address[]", name: "admins", type: "address[]" }, { indexed: false, internalType: "bool", name: "status", type: "bool" }], name: "AdminWhitelistUpdated", type: "event" };
export const Initialized = { anonymous: false, inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }], name: "Initialized", type: "event" };
export const NewOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldOwner", type: "address" }, { indexed: false, internalType: "address", name: "newOwner", type: "address" }], name: "NewOwner", type: "event" };
export const NewPendingOwner = { anonymous: false, inputs: [{ indexed: false, internalType: "address", name: "oldPendingOwner", type: "address" }, { indexed: false, internalType: "address", name: "newPendingOwner", type: "address" }], name: "NewPendingOwner", type: "event" };
export const OwnershipTransferred = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "previousOwner", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnershipTransferred", type: "event" };
export const PoolRegistered = { anonymous: false, inputs: [{ indexed: false, internalType: "uint256", name: "index", type: "uint256" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], indexed: false, internalType: "struct FusePoolDirectory.FusePool", name: "pool", type: "tuple" }], name: "PoolRegistered", type: "event" };
export const _acceptOwner = { inputs: [], name: "_acceptOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _deprecatePool_address = { inputs: [{ internalType: "address", name: "comptroller", type: "address" }], name: "_deprecatePool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _deprecatePool_uint256 = { inputs: [{ internalType: "uint256", name: "index", type: "uint256" }], name: "_deprecatePool", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _editAdminWhitelist = { inputs: [{ internalType: "address[]", name: "admins", type: "address[]" }, { internalType: "bool", name: "status", type: "bool" }], name: "_editAdminWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _editDeployerWhitelist = { inputs: [{ internalType: "address[]", name: "deployers", type: "address[]" }, { internalType: "bool", name: "status", type: "bool" }], name: "_editDeployerWhitelist", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _resetGap = { inputs: [], name: "_resetGap", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setDeployerWhitelistEnforcement = { inputs: [{ internalType: "bool", name: "enforce", type: "bool" }], name: "_setDeployerWhitelistEnforcement", outputs: [], stateMutability: "nonpayable", type: "function" };
export const _setPendingOwner = { inputs: [{ internalType: "address", name: "newPendingOwner", type: "address" }], name: "_setPendingOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const adminWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "adminWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const deployPool = { inputs: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "implementation", type: "address" }, { internalType: "bytes", name: "constructorData", type: "bytes" }, { internalType: "bool", name: "enforceWhitelist", type: "bool" }, { internalType: "uint256", name: "closeFactor", type: "uint256" }, { internalType: "uint256", name: "liquidationIncentive", type: "uint256" }, { internalType: "address", name: "priceOracle", type: "address" }], name: "deployPool", outputs: [{ internalType: "uint256", name: "", type: "uint256" }, { internalType: "address", name: "", type: "address" }], stateMutability: "nonpayable", type: "function" };
export const deployerWhitelist = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "deployerWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const enforceDeployerWhitelist = { inputs: [], name: "enforceDeployerWhitelist", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const getActivePools = { inputs: [], name: "getActivePools", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getAllPools = { inputs: [], name: "getAllPools", outputs: [{ components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getPoolsByAccount = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getPoolsByAccount", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getPoolsOfUser = { inputs: [{ internalType: "address", name: "user", type: "address" }], name: "getPoolsOfUser", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getPublicPools = { inputs: [], name: "getPublicPools", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getPublicPoolsByVerification = { inputs: [{ internalType: "bool", name: "whitelistedAdmin", type: "bool" }], name: "getPublicPoolsByVerification", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const getVerifiedPoolsOfWhitelistedAccount = { inputs: [{ internalType: "address", name: "account", type: "address" }], name: "getVerifiedPoolsOfWhitelistedAccount", outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }, { components: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], internalType: "struct FusePoolDirectory.FusePool[]", name: "", type: "tuple[]" }], stateMutability: "view", type: "function" };
export const initialize = { inputs: [{ internalType: "bool", name: "_enforceDeployerWhitelist", type: "bool" }, { internalType: "address[]", name: "_deployerWhitelist", type: "address[]" }], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const pendingOwner = { inputs: [], name: "pendingOwner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const poolExists = { inputs: [{ internalType: "address", name: "", type: "address" }], name: "poolExists", outputs: [{ internalType: "bool", name: "", type: "bool" }], stateMutability: "view", type: "function" };
export const pools = { inputs: [{ internalType: "uint256", name: "", type: "uint256" }], name: "pools", outputs: [{ internalType: "string", name: "name", type: "string" }, { internalType: "address", name: "creator", type: "address" }, { internalType: "address", name: "comptroller", type: "address" }, { internalType: "uint256", name: "blockPosted", type: "uint256" }, { internalType: "uint256", name: "timestampPosted", type: "uint256" }], stateMutability: "view", type: "function" };
export const poolsCounter = { inputs: [], name: "poolsCounter", outputs: [{ internalType: "uint256", name: "", type: "uint256" }], stateMutability: "view", type: "function" };
export const renounceOwnership = { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setPoolName = { inputs: [{ internalType: "uint256", name: "index", type: "uint256" }, { internalType: "string", name: "name", type: "string" }], name: "setPoolName", outputs: [], stateMutability: "nonpayable", type: "function" };
export const transferOwnership = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "transferOwnership", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [AdminWhitelistUpdated, Initialized, NewOwner, NewPendingOwner, OwnershipTransferred, PoolRegistered, _acceptOwner, _deprecatePool_address, _deprecatePool_uint256, _editAdminWhitelist, _editDeployerWhitelist, _resetGap, _setDeployerWhitelistEnforcement, _setPendingOwner, adminWhitelist, deployPool, deployerWhitelist, enforceDeployerWhitelist, getActivePools, getAllPools, getPoolsByAccount, getPoolsOfUser, getPublicPools, getPublicPoolsByVerification, getVerifiedPoolsOfWhitelistedAccount, initialize, owner, pendingOwner, poolExists, pools, poolsCounter, renounceOwnership, setPoolName, transferOwnership];
