export const COMPTROLLER_ERROR_CODES = [
    "NO_ERROR",
    "UNAUTHORIZED",
    "COMPTROLLER_MISMATCH",
    "INSUFFICIENT_SHORTFALL",
    "INSUFFICIENT_LIQUIDITY",
    "INVALID_CLOSE_FACTOR",
    "INVALID_COLLATERAL_FACTOR",
    "INVALID_LIQUIDATION_INCENTIVE",
    "MARKET_NOT_ENTERED",
    "MARKET_NOT_LISTED",
    "MARKET_ALREADY_LISTED",
    "MATH_ERROR",
    "NONZERO_BORROW_BALANCE",
    "PRICE_ERROR",
    "REJECTION",
    "SNAPSHOT_ERROR",
    "TOO_MANY_ASSETS",
    "TOO_MUCH_REPAY",
    "SUPPLIER_NOT_WHITELISTED",
    "BORROW_BELOW_MIN",
    "SUPPLY_ABOVE_MAX",
    "NONZERO_TOTAL_SUPPLY",
];
export const CTOKEN_ERROR_CODES = [
    "NO_ERROR",
    "UNAUTHORIZED",
    "BAD_INPUT",
    "COMPTROLLER_REJECTION",
    "COMPTROLLER_CALCULATION_ERROR",
    "INTEREST_RATE_MODEL_ERROR",
    "INVALID_ACCOUNT_PAIR",
    "INVALID_CLOSE_AMOUNT_REQUESTED",
    "INVALID_COLLATERAL_FACTOR",
    "MATH_ERROR",
    "MARKET_NOT_FRESH",
    "MARKET_NOT_LISTED",
    "TOKEN_INSUFFICIENT_ALLOWANCE",
    "TOKEN_INSUFFICIENT_BALANCE",
    "TOKEN_INSUFFICIENT_CASH",
    "TOKEN_TRANSFER_IN_FAILED",
    "TOKEN_TRANSFER_OUT_FAILED",
    "UTILIZATION_ABOVE_MAX",
];
