var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LiquidationStrategy } from "@midas-capital/types";
import { getUniswapV2Router } from "./redemptionStrategy";
const estimateGas = (fuse, borrower, exchangeToTokenAddress, liquidationAmount, strategiesAndDatas, flashSwapPair, liquidationStrategy, debtFundingStrategies, debtFundingStrategiesData) => __awaiter(void 0, void 0, void 0, function* () {
    switch (liquidationStrategy) {
        case LiquidationStrategy.DEFAULT:
            return yield fuse.contracts.FuseSafeLiquidator.estimateGas["safeLiquidate(address,uint256,address,address,uint256,address,address,address[],bytes[])"](borrower.account, liquidationAmount, borrower.debt[0].cToken, borrower.collateral[0].cToken, 0, exchangeToTokenAddress, fuse.chainSpecificAddresses.UNISWAP_V2_ROUTER, strategiesAndDatas.strategies, strategiesAndDatas.datas, {
                gasLimit: 1e9,
                from: process.env.ETHEREUM_ADMIN_ACCOUNT,
            });
        case LiquidationStrategy.UNISWAP:
            return yield fuse.contracts.FuseSafeLiquidator.estimateGas.safeLiquidateToTokensWithFlashLoan({
                borrower: borrower.account,
                repayAmount: liquidationAmount,
                cErc20: borrower.debt[0].cToken,
                cTokenCollateral: borrower.collateral[0].cToken,
                minProfitAmount: 0,
                exchangeProfitTo: exchangeToTokenAddress,
                uniswapV2RouterForBorrow: fuse.chainSpecificAddresses.UNISWAP_V2_ROUTER,
                uniswapV2RouterForCollateral: getUniswapV2Router(fuse, borrower.collateral[0].cToken),
                redemptionStrategies: strategiesAndDatas.strategies,
                strategyData: strategiesAndDatas.datas,
                flashSwapPair,
                ethToCoinbase: 0,
                debtFundingStrategies,
                debtFundingStrategiesData,
            }, {
                gasLimit: 1e9,
                from: process.env.ETHEREUM_ADMIN_ACCOUNT,
            });
    }
});
export default estimateGas;
