/**
 * Autogenerated file. Do not edit manually. */
export const FlywheelError = { inputs: [], name: "FlywheelError", type: "error" };
export const AuthorityUpdated = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "user", type: "address" }, { indexed: true, internalType: "contract Authority", name: "newAuthority", type: "address" }], name: "AuthorityUpdated", type: "event" };
export const OwnerUpdated = { anonymous: false, inputs: [{ indexed: true, internalType: "address", name: "user", type: "address" }, { indexed: true, internalType: "address", name: "newOwner", type: "address" }], name: "OwnerUpdated", type: "event" };
export const RewardsInfoUpdate = { anonymous: false, inputs: [{ indexed: true, internalType: "contract ERC20", name: "strategy", type: "address" }, { indexed: false, internalType: "uint224", name: "rewardsPerSecond", type: "uint224" }, { indexed: false, internalType: "uint32", name: "rewardsEndTimestamp", type: "uint32" }], name: "RewardsInfoUpdate", type: "event" };
export const authority = { inputs: [], name: "authority", outputs: [{ internalType: "contract Authority", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const flywheel = { inputs: [], name: "flywheel", outputs: [{ internalType: "contract FlywheelCore", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const getAccruedRewards = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }, { internalType: "uint32", name: "lastUpdatedTimestamp", type: "uint32" }], name: "getAccruedRewards", outputs: [{ internalType: "uint256", name: "amount", type: "uint256" }], stateMutability: "view", type: "function" };
export const owner = { inputs: [], name: "owner", outputs: [{ internalType: "address", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const rewardToken = { inputs: [], name: "rewardToken", outputs: [{ internalType: "contract ERC20", name: "", type: "address" }], stateMutability: "view", type: "function" };
export const rewardsInfo = { inputs: [{ internalType: "contract ERC20", name: "", type: "address" }], name: "rewardsInfo", outputs: [{ internalType: "uint224", name: "rewardsPerSecond", type: "uint224" }, { internalType: "uint32", name: "rewardsEndTimestamp", type: "uint32" }], stateMutability: "view", type: "function" };
export const setAuthority = { inputs: [{ internalType: "contract Authority", name: "newAuthority", type: "address" }], name: "setAuthority", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setOwner = { inputs: [{ internalType: "address", name: "newOwner", type: "address" }], name: "setOwner", outputs: [], stateMutability: "nonpayable", type: "function" };
export const setRewardsInfo = { inputs: [{ internalType: "contract ERC20", name: "strategy", type: "address" }, { components: [{ internalType: "uint224", name: "rewardsPerSecond", type: "uint224" }, { internalType: "uint32", name: "rewardsEndTimestamp", type: "uint32" }], internalType: "struct FlywheelStaticRewards.RewardsInfo", name: "rewards", type: "tuple" }], name: "setRewardsInfo", outputs: [], stateMutability: "nonpayable", type: "function" };
export default [FlywheelError, AuthorityUpdated, OwnerUpdated, RewardsInfoUpdate, authority, flywheel, getAccruedRewards, owner, rewardToken, rewardsInfo, setAuthority, setOwner, setRewardsInfo];
